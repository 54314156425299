import React, { FC } from "react";
import classNames from "classnames";
import Tooltip from "rc-tooltip";
import { ReactComponent as HelpIcon } from "../../../assets/icons/Help Icon.svg";

import styles from "./Content.module.scss";

interface Props {
  title: any;
  text: any;
  icon: any;
  onClick: any;
  className?: any;
  children?: any;
  isBlurred?: boolean;
  tooltip?: boolean;
  tooltipPlacement?: string;
  tooltipText?: string;
  tooltipTrigger?: string;
  translateId?: string;
}

export const Dashboard: FC<Props> = ({
  title,
  text,
  icon,
  onClick,
  className,
  children,
  isBlurred,
  tooltip,
  tooltipPlacement,
  tooltipText,
  tooltipTrigger,
  translateId,
}) => (
  <div className={classNames(styles.dashboard, className)} onClick={onClick}>
    <div className={styles.top}>
      <div className={styles.icon}>{icon}</div>
    </div>
    <div className={styles.content}>
      <div
        data-translate={translateId}
        className={classNames(styles.title, { "with-tooltip": tooltip })}
      >
        {title}
        {tooltip && (
          <Tooltip
            mouseEnterDelay={0}
            mouseLeaveDelay={0}
            destroyTooltipOnHide={true}
            placement={tooltipPlacement || "top"}
            trigger={tooltipTrigger || "hover"}
            overlay={<div>{tooltipText || ""}</div>}
          >
            <HelpIcon
              style={{ zIndex: 9999, width: "18px", marginLeft: "8px" }}
            />
          </Tooltip>
        )}
      </div>
      <div className={styles.text}>{text}</div>
    </div>
  </div>
);
