import React from 'react';
import gsap from 'gsap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from '../../../reducers';
import * as actions from '../../../actions';
import './StartPage.scss';
import { Button, EButtonType } from '../../../components/Button';
import FormattedMessageCustom from '../../../components/FormattedMessageCustom';
import { defineMessages } from 'react-intl';
import classNames from 'classnames';
import PKlogo from '../../../assets/imgs/pkar_logo.png';
import BVGlogo from '../../../assets/icons/logo.png';
import { ReactComponent as PensionDynamics } from '../../../assets/imgs/pension_dynamics.svg';
import { ReactComponent as PoweredByICR } from '../../../assets/imgs/icr_powered.svg';
import { Transition, SwitchTransition } from 'react-transition-group'
import { ReactComponent as IconGlasses } from '../../../assets/imgs/glasses.svg';
import { ReactComponent as IconHead } from '../../../assets/imgs/brainhead.svg';
import {getParameterByName} from "../../../utils/toSearchParams";
import Spinner from "../../../components/Spinner";

const translatableStrings = defineMessages({
    welcome: {
        id: 'pageStart.welcome',
        defaultMessage: 'Willkommen beim Pensionskassenrechner'
    },
    explanation: {
        id: 'pageStart.explanation',
        defaultMessage: 'Haben Sie sich schon gefragt, wie Ihre Rente nach der Pensionierung aussieht, welche Kapitaleinzahlungen Sie machen können und wie sich diese auswirken? Berechnen Sie in wenigen Schritten diverse Ereignisse. <br /> Bitte beachten Sie, dass sich aus den Leistungsberechnungen kein Rechtsanspruch ableiten lässt. Die Leistungsangaben erfolgen ohne Gewähr.'
    },
    step1: {
        id: 'pageStart.step1',
        defaultMessage: 'Wir fragen nur nach den für die Berechnung <br/>nötigen persönlichen Informationen. <br/>Sie bleiben auf Wunsch völlig anonym.'
    },
    step2: {
        id: 'pageStart.step2',
        defaultMessage: 'Simulieren Sie ganz einfach verschiedene Szenarien <br/>und sehen Sie direkt die Auswirkung auf Ihre Rente.'
    },
    next: {
        id: 'button.next',
        defaultMessage: 'WEITER'
    },
    skip: {
        id: 'button.skip',
        defaultMessage: 'DIREKT LOSLEGEN'
    },
})

interface State {
    step: number;
    loading: boolean;
    // mobileStep: number;
}

interface Props {
    actions: any;
    clearDataAction: any;
    history: any;
    fetchSettings: any;
    intl: any;
    userDataWI: any;
    fetchInsurantData: any;
}

class Welcome extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            step: window.innerWidth < 897 ? 0 : 1,
            // mobileStep: 0
        }
    }

    private lastStep = 0;
    private timeline = null;
    private touchStart = null;
    private touchEnd = null;


    componentDidMount() {
        document.body.classList.add("welcome");
        document.addEventListener('keypress', this.handleEnterPress);

        const token = getParameterByName("token");
        const portalUrl = getParameterByName("portalUrl");
        if(token && portalUrl) {
            this.setState({loading: true});
        }

        // If you know a better way, let me know please.
        const tenantName = JSON.parse((window as any).localStorage.getItem("userDataWI"))?.tenantName;
        const userDataWI = {
            tenantName
        };
        (window as any).localStorage.removeItem("userDataWI");
        (window as any).localStorage.setItem("userDataWI", JSON.stringify(userDataWI));
        setTimeout(() => {
            this.props.clearDataAction();
        }, 150);

        // this.props.actions.fetchSettings();
        // console.log('param', getParameterByName('step'));
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if(!prevProps.userDataWI.settings && this.props.userDataWI.settings) {
            const token = getParameterByName("token");
            const portalUrl = getParameterByName("portalUrl");
            console.log("TOKEN", token);
            console.log("PORTAL URL", portalUrl);
            localStorage.removeItem("insurantPortalDataWI");
            localStorage.removeItem("insurantPortalUrlWI");

            if(token && portalUrl) {
                console.log("SETTINGS", this.props.userDataWI.settings);
                this.setState({loading: true});
                this.props.fetchSettings().then(() => {
                    setTimeout(() => {
                        this.props.fetchInsurantData(token, portalUrl)
                            .then(res =>  {
                                this.props.history.push({pathname: `/chart`})
                            })
                            .catch(err =>  {
                                console.log(err);
                                this.setState({loading: false});
                            });
                    }, 200);
                });
            }
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('welcome');
        document.removeEventListener('keypress', this.handleEnterPress);
    }


    start() {
        this.state.step === 2 ? this.setState({ step: 1 }) : this.setState({ step: 2 });
    }

    handleEnterPress = e => {
        if (e && e.key === 'Enter') {
            this.next();
        }
    }

    async next() {
        this.timeline = gsap.timeline({ paused: true });
        this.timeline && this.timeline.pause();

        if (window.innerWidth < 897 && this.state.step === 0) {
            this.setState({
                step: 1
            });
        } else if (this.state.step === 2) {
            this.timeline
                // .fromTo(document.body, {overflow: 'auto', ease: 'linear', duration: 0.3}, {overflow: 'hidden'})
                .fromTo(document.querySelector('#root .animationMask'), { autoAlpha: 0 }, {
                    autoAlpha: 1,
                    ease: 'sine.out',
                    duration: 0.5
                })
                .fromTo([document.querySelector('#root .card.min-wind'), document.querySelector('#root .logoIcon')], {
                    y: 0,
                    ease: 'sine.out',
                    autoAlpha: 1
                }, { y: '-100vh', ease: 'sine.out', duration: 0.5, autoAlpha: 0 })

            this.timeline.play();

            setTimeout(() => {
                console.log('objectstop')
                this.props.history.push({
                    pathname: `/geburtsdatum`
                });
            }, 1000)

        } else {
            this.setState({ step: 2 });
        }
    }

    skip() {
        this.timeline = gsap.timeline({ paused: true });
        this.timeline && this.timeline.pause();


        this.timeline.fromTo(document.querySelector('#root .animationMask'), { autoAlpha: 0 }, {
            autoAlpha: 1,
            ease: 'sine.out',
            duration: 0.5
        }).fromTo([document.querySelector('#root .card.min-wind'), document.querySelector('#root .logoIcon')], { y: 0, ease: 'sine.out', autoAlpha: 1 }, {
            y: '-100vh',
            ease: 'sine.out',
            duration: 0.5,
            autoAlpha: 0
        })

        this.timeline.play();

        setTimeout(() => {
            console.log('objectstop')
            this.props.history.push({
                pathname: `/geburtsdatum`
            })
        }, 1000)
    }

    playIn = (node, appears?) => {
        console.log('playIn', node, appears,
            'step', this.state.step,
            'lastStep', this.lastStep
        );
        this.timeline && this.timeline.kill();

        if (!appears) {
            this.timeline = gsap.timeline({ paused: true });
            this.timeline && this.timeline.pause();

            if (this.state.step === 2) {
                this.timeline.from(node.querySelectorAll('.animate #Picto path'), {
                    x: 80,
                    duration: 0.5,
                    autoAlpha: 0,
                    ease: 'circle'
                });
                this.timeline.from(node.querySelectorAll('.title-subtitle'), {
                    x: 100,
                    duration: 0.4,
                    autoAlpha: 0,
                    ease: 'circle',
                    delay: 0.1
                }, 0);
                this.lastStep = 2;
            } else if (this.state.step === 1) {
                if (this.lastStep === 0) {
                    console.log('if step1 laststep0')
                    this.lastStep = 1;

                    this.timeline.fromTo(document.querySelectorAll('.mobileAnimatableIn'), {
                        x: 300,
                        duration: 0.5,
                        autoAlpha: 0,
                        ease: 'circle'
                    }, { display: 'inline-block', autoAlpha: 1, x: 0 });
                } else {
                    console.log('else');
                    // this.lastStep = 0;
                    this.timeline.from(node.querySelectorAll('.animate #Picto path'), {
                        x: -80,
                        duration: 0.5,
                        autoAlpha: 0,
                        ease: 'circle'
                    });
                    this.timeline.from(node.querySelectorAll('.title-subtitle'), {
                        x: -100,
                        duration: 0.4,
                        autoAlpha: 0,
                        ease: 'circle',
                        delay: 0.1
                    }, 0);
                }
            } else {
                this.lastStep = 0;
                this.timeline.fromTo(document.querySelectorAll('.mobileAnimatable'), {
                    x: -300,
                    duration: 0.3,
                    ease: 'circle',
                }, { x: 0 }, 0);
            }
            this.timeline.play();
        }
    }

    playOut = (node, appears?) => {
        console.log(
            'playOut', node,
            'step', this.state.step,
            'lastStep', this.lastStep
        );

        this.timeline && this.timeline.kill();
        this.timeline = gsap.timeline({ paused: true });

        // going to step 2
        if (this.state.step === 2) {
            this.timeline.fromTo(node.querySelectorAll('.animate #Picto path'), {
                x: 0,
                autoAlpha: 1,
                ease: 'circle',
                duration: 0.5
            }, { x: -80, autoAlpha: 0 });
            this.timeline.fromTo(node.querySelectorAll('.title-subtitle'), {
                x: 0,
                autoAlpha: 1,
                ease: 'circle',
                duration: 0.4
            }, { x: -100, autoAlpha: 0 }, 0);
        } else if (this.state.step === 1) {
            if (this.lastStep === 0) {
                console.log('going to step1', document.querySelectorAll('.mobileAnimatable'));
                // going to step 1 on mobile
                this.timeline.to(document.querySelectorAll('.mobileAnimatable'), {
                    x: -300,
                    duration: 0.3,
                    // autoAlpha: 0,
                    ease: 'circle',
                    // display: 'none'
                }, 0);
            } else {
                // going to step 1 on both
                this.timeline.fromTo(node.querySelectorAll('.animate #Picto path'), {
                    x: 0,
                    duration: 0.5,
                    autoAlpha: 1,
                    ease: 'circle'
                }, { x: 80, autoAlpha: 0 });
                this.timeline.fromTo(node.querySelectorAll('.title-subtitle'), {
                    x: 0,
                    duration: 0.4,
                    autoAlpha: 1,
                    ease: 'circle'
                }, { x: 100, autoAlpha: 0 }, 0);
            }
        } else {
            this.timeline.fromTo(document.querySelectorAll('.mobileAnimatableIn'), {
                x: 0,
                duration: 0.5,
                autoAlpha: 1,
                ease: 'circle'
            }, { autoAlpha: 0, x: 300 });
        }

        this.timeline.play();
    }

    handleTouchStart = e => {
        this.touchStart = e.targetTouches[0].clientX;
    }

    handleTouchMove = e => {
        this.touchEnd = e.targetTouches[0].clientX;
    }

    handleTouchEnd = e => {
        if (!this.touchStart || !this.touchEnd) {
            return;
        }

        if (this.touchStart - this.touchEnd > 75) {
            this.next();
        }

        if (this.touchStart - this.touchEnd < -75) {
            if (this.state.step == 2) {
                this.start();
            } else {
                this.setState({ step: 0 });
            }
        }
    }

    getLogo = (withClasses) => {
        console.log('this.props.userDataWI.tenantName', this.props.userDataWI.tenantName);
        const tenantName = this.props.userDataWI.tenantName?.toUpperCase();
        const settingsLogoUrl = this.props.userDataWI.settings?.customDesign?.logoImage;
        const logo = settingsLogoUrl ? settingsLogoUrl : tenantName !== "PKAR" ? BVGlogo : PKlogo;
        if (this.props.userDataWI.tenantName === null) {
            return null;
        } else if (withClasses) {
            if(tenantName !== "PKAR") {
                return <img alt="logo" src={logo} className="d-none d-sm-inline title-logo mobileAnimatable" />
            } else {
                return <img alt="logo" src={logo} className="d-none d-sm-inline title-logo mobileAnimatable pkar" />
            }
        } else {
            if(tenantName !== "PKAR") {
                return <img alt="logo" src={logo} />
            } else {
                return <img alt="logo" src={logo} className="pkar" />
            }
        }
    }

    render() {
        return (
            <div className="container-fluid">
                {this.state.loading && <Spinner timeout={1}/>}
                <PensionDynamics className="pensionDynamics logoIcon" />
                <PoweredByICR className="icrPowered logoIcon" />
                <div className="row mobilePadding">
                    {/* <div className="śśśśśśśśśśśśśśśśś"></div> */}
                    <div className={classNames("card min-wind mx-auto text-center", `step${this.state.step}`, {})}
                        onTouchStart={this.handleTouchStart}
                        onTouchMove={this.handleTouchMove}
                        onTouchEnd={this.handleTouchEnd}>
                        {window.innerWidth > 876 ?
                            <>
                                <div className="topCard d-sm-none mobileAnimatable"
                                    style={{ display: 'block' }}>
                                    {this.getLogo(false)}
                                </div>
                                {this.getLogo(true)}
                                <FormattedMessageCustom id={translatableStrings.welcome.id}
                                    text={this.props.intl.formatMessage(translatableStrings.welcome)}>
                                    <h1 className="p-relative title mobileAnimatable">
                                    </h1>
                                </FormattedMessageCustom>
                                <div className="mobileAnimatable">
                                    <FormattedMessageCustom id={translatableStrings.explanation.id}
                                        text={this.props.intl.formatMessage(translatableStrings.explanation)}>
                                        <p className="p-relative"></p>
                                    </FormattedMessageCustom>
                                </div>
                            </> : null}
                        <SwitchTransition mode="out-in">
                            <Transition
                                key={`child${this.state.step}`}
                                timeout={{ enter: 0, exit: 500 }}
                                onEnter={(node, appears?) => this.playIn(node, appears)}
                                onExit={(node?) => this.playOut(node)}>
                                <>
                                    {this.state.step === 2 ?
                                        <div className="slide 2">
                                            <IconHead className="d-sm-inline brain animate mobileAnimatableIn"
                                                onClick={() => this.next()}
                                            // style={{ display: 'none' }}
                                            />
                                            {/* <img alt="logo" src={head} className="d-none d-sm-inline brain" /> */}
                                            <FormattedMessageCustom id={translatableStrings.step2.id}
                                                text={this.props.intl.formatMessage(translatableStrings.step2)}>
                                                <p className='title-subtitle mobileAnimatableIn'
                                                    onClick={() => this.next()}>
                                                </p>
                                            </FormattedMessageCustom>
                                        </div> : null}

                                    {this.state.step === 1 ? <div className="slide 1">
                                        <IconGlasses className="d-sm-inline brain animate mobileAnimatableIn"
                                            // style={{ display: 'inline-block' }}
                                            onClick={() => this.start()} />
                                        {/* <img alt="logo" src={glasses} className="d-none d-sm-inline brain" /> */}
                                        <FormattedMessageCustom id={translatableStrings.step1.id}
                                            text={this.props.intl.formatMessage(translatableStrings.step1)}>
                                            <p className='title-subtitle mobileAnimatableIn'
                                                // style={{ display: 'inherit' }}
                                                onClick={() => this.start()}>
                                            </p>
                                        </FormattedMessageCustom>
                                    </div> : null}

                                    {this.state.step === 0 ? <>
                                        <div className="topCard d-sm-none mobileAnimatable mx-auto"
                                            style={{ display: 'block' }}
                                        >
                                            {this.getLogo(false)}
                                        </div>
                                        {this.getLogo(true)}
                                        <FormattedMessageCustom id={translatableStrings.welcome.id}
                                            text={this.props.intl.formatMessage(translatableStrings.welcome)}>
                                            <h1 className="p-relative title mobileAnimatable"></h1>
                                        </FormattedMessageCustom>
                                        <div className="mobileAnimatable">
                                            <FormattedMessageCustom id={translatableStrings.explanation.id}
                                                text={this.props.intl.formatMessage(translatableStrings.explanation)}>
                                                <p className="p-relative"></p>
                                            </FormattedMessageCustom>
                                        </div>
                                    </> : null}
                                </>
                            </Transition>
                        </SwitchTransition>
                        <div className={classNames('rectangle mobileAnimatableIn', {})}
                            style={{ display: window.innerWidth < 897 ? 'none' : 'block' }}>

                            <svg style={{
                                width: '24px',
                                height: 'inherit',
                                margin: 'auto',
                                cursor: 'pointer',
                            }}
                                onClick={() => this.start()}>

                                <g id="Desktop" stroke="none" fill="none" fillRule="evenodd">
                                    <g id="Welcome-01" transform="translate(-694.000000, -716.000000)" stroke="#969899"
                                        strokeWidth="2">
                                        <g id="Overlay" transform="translate(410.000000, 114.000000)">
                                            <g id="Onboarding-Slide-1" transform="translate(111.000000, 327.000000)">
                                                <g id="Progress" transform="translate(173.000000, 275.000000)">
                                                    <rect id="Rectangle-Copy"
                                                        fill={this.state.step !== 2 ? "#1B8DB3" : "transparent"}
                                                        stroke={this.state.step === 1 ? "#1B8DB3" : "#969899"}
                                                        x="1"
                                                        y="1" width="8" height="8" rx="4" />
                                                    <rect id="Rectangle-Copy-2" x="15" y="1" width="8" height="8" rx="4"
                                                        fill={this.state.step === 1 ? "transparent" : "#1B8DB3"}
                                                        stroke={this.state.step === 2 ? "#1B8DB3" : "#969899"}
                                                    />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div className='buttons'>
                            <Button
                                className="bigButton mt-4 w-auto text-uppercase"
                                submit={true}
                                onClick={() => this.next()}
                            >
                                <svg className='next'>
                                    <g id="Welcome-01" transform="translate(-586.000000, -790.000000)"
                                        fillRule="evenodd">
                                        <g id="Overlay" transform="translate(410.000000, 114.000000)">
                                            <g id="Primary-Button" transform="translate(176.000000, 676.000000)">
                                                <path
                                                    d="M28,0 L239,0 C254.463973,-2.84068575e-15 267,12.536027 267,28 C267,43.463973 254.463973,56 239,56 L28,56 C12.536027,56 1.61046452e-14,43.463973 0,28 C-1.8937905e-15,12.536027 12.536027,2.84068575e-15 28,0 Z"
                                                    id="Rectangle" fill="#145685" />
                                                <text id="Weiter" fontFamily="Inter-Bold, Inter" fontSize="14"
                                                    fontWeight="bold" fill="#FFFFFF">
                                                    <FormattedMessageCustom id={translatableStrings.next.id}
                                                        text={this.props.intl.formatMessage(translatableStrings.next)}>
                                                        <tspan x="32" y="33"></tspan>
                                                    </FormattedMessageCustom>
                                                </text>
                                                <g className="arrowRightG">
                                                    <g id="arrow-right-(6)" transform="translate(224.000000, 24.000000)"
                                                        stroke="#FFFFFF" strokeLinecap="square" strokeWidth="2">
                                                        <line x1="0" y1="4" x2="10" y2="4" id="Path" />
                                                        <polyline id="Path" points="7 0 11 4 7 8" fill="none" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </Button>
                            <Button
                                className="d-inline-block mt-1 ml-auto d-none passwordVergessenButton"
                                onClick={() => this.skip()}
                                to={null}
                                buttonType={EButtonType.BasicLink}
                            >
                                <FormattedMessageCustom id={translatableStrings.skip.id}
                                    text={this.props.intl.formatMessage(translatableStrings.skip)}>
                                    <p></p>
                                </FormattedMessageCustom>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        clearDataAction: bindActionCreators(actions.clearDataAction, dispatch),
        fetchSettings: bindActionCreators(actions.fetchSettings, dispatch),
        fetchInsurantData: bindActionCreators(actions.fetchInsurantData, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
