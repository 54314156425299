import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../../../reducers";
import * as actions from "../../../actions";
import "../../Wunschberechnung/Anliegen/Wunschberechnung.scss";
import Modal from "react-modal";
import { Input } from "../../../components/Input/index";
import doc from "../../../assets/imgs/sparguthaben-2.png";
import { Button, EButtonType } from "../../../components/Button";
import SelectList from "../../../components/Sidebar/SelectList";
import close from "../../../assets/icons/Close_Icon.svg";
import gsap from "gsap";
import { animationConfig } from "../../../constants";
import { defineMessages } from "react-intl";
import FormattedMessageCustom from "../../../components/FormattedMessageCustom";
import { isNumericOrDot } from "../../../utils/variableEvaluation";

const translatableStrings = defineMessages({
  example: {
    id: "pageBeschaftigungsgrad.example",
    defaultMessage: "Beispiel",
  },
  exampleOfInsurance: {
    id: "pageBeschaftigungsgrad.exampleOfInsurance",
    defaultMessage: "Beispiel Versicherungsausweis",
  },
  whatIsYourCapital: {
    id: "pageLognklasse.whatIsYourSalaryCategory",
    defaultMessage: "In welcher Lohnklasse sind Sie <br />zurzeit Versichert?",
  },
  whereToFindCapital: {
    id: "pageSparguthaben.whereToFindCapital",
    defaultMessage: "WO FINDE ICH MEIN ALTERSGUTHABEN / AUSTRITTSLEISTUNG",
  },
  enterPayrollClass: {
    id: "pageLohnklasse.enterPayrollClass",
    defaultMessage: "Lohnklasse eingeben (1 bis 30)",
  },
});

interface State {
  value: any;
  modalIsOpen: boolean;
}

interface Props {
  actions: any;
  history: any;
  setUserDataLohnklasse: any;
  userDataWI: any;
  intl: any;
  hasPayrollClasses: boolean;
}

class Lohnklasse extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      modalIsOpen: false,
      value: this.props.userDataWI.payrollClass
        ? this.props.userDataWI.payrollClass
        : "",
    };
    this.next = this.next.bind(this);
    this.back = this.back.bind(this);
  }
  private timeline = null;

  componentDidMount() {
    // if (window.innerWidth > 896) {
    setTimeout(() => {
      (document.querySelector(".main input") as any) &&
        (document.querySelector(".main input") as any).focus();
    }, 1200);
    // }
  }

  async setUserDataLohnklasse(data) {
    await this.props.setUserDataLohnklasse({ value: data });
  }

  async next(e) {
    // let parts = e.toString().split(".");
    // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    // await this.setUserDataSparguthaben(parts.join("."));
    await this.setUserDataLohnklasse(e);

    this.timeline = gsap.timeline({ paused: true });
    this.timeline && this.timeline.pause();

    if (document.querySelectorAll(".crossFade").length) {
      this.timeline.fromTo(
        document.querySelectorAll(".crossFade"),
        { autoAlpha: 1 },
        {
          autoAlpha: 0,
        }
      );
    }

    if (document.querySelectorAll(".stagger").length) {
      this.timeline.fromTo(
        document.querySelectorAll(".stagger"),
        { autoAlpha: 1 },
        {
          y: -900,
          stagger: {
            ...animationConfig().stagger,
          },
          ease: animationConfig().easingStart,
          autoAlpha: 0,
        }
      );
    }

    this.timeline.play();

    setTimeout((e) => {
      this.props.history.push({
        pathname: `/beschaftigungsgrad`,
        // pathname: `/vpkonto`
      });
    }, 1200);
  }

  start = (e) => {
    this.props.history.push({
      pathname: e,
    });
  };

  back = (e) => {
    if (e.currentTarget.id) {
      this.props.history.push({
        pathname: "/" + e.currentTarget.id,
      });
    }
  };

  handleChange(name, value) {
    // const value = e.currentTarget.value
    // .replace(/[^.\d]+/g, "").replace(/^([^\.]*\.)|\./g, '$1');
    isNumericOrDot(value) &&
      this.setState({
        value: value,
      });
  }

  showHideNotif = () => {
    if (this.state.modalIsOpen && window.innerWidth < 897) {
      this.setState({
        modalIsOpen: false,
      });
    } else {
      this.setState({
        modalIsOpen: true,
      });
    }
  };

  async showHide(event) {
    event();
  }

  async showHideBack() {
    document.body.classList.remove("blurred");
  }

  enter(event) {
    if (event.keyCode === 13) {
      this.next(event.currentTarget.value);
    }
  }

  render() {
    return (
      <div className="wunschberechnung_container">
        <div
          id="back"
          className="mobile_back"
          onClick={() => this.showHideBack()}
        />
        <div className="sidebar">
          <div className="flex-grow-1"></div>
          <div className="selects">
            <div className="neustart">
              <SelectList
                intl={this.props.intl}
                back={this.back}
                start={this.start}
              />
            </div>
          </div>
          {/* <img alt="logo" src={HelpIcon} className='ques' /> */}
        </div>
        <div className="main sparg-main">
          <FormattedMessageCustom
            id={translatableStrings.whatIsYourCapital.id}
            text={this.props.intl.formatMessage(
              translatableStrings.whatIsYourCapital
            )}
          >
            <div className="ich-mochte mb-40 stagger"></div>
          </FormattedMessageCustom>
          {/* <img alt="logo" src={HelpIcon} className='ques_mobile' /> */}
          <div className="lohnsumme stagger">
            <div className="lohnsumme__main">
              <Input
                value={this.state.value}
                placeholder={this.props.intl.formatMessage(
                  translatableStrings.enterPayrollClass
                )}
                inputWrapperClass="w-100"
                onChange={(name, val) => this.handleChange(name, val)}
                onKeyDown={(event) => this.enter(event)}
                type="number"
                // validationMessageConfig={{}}
                inputWrapperStyle={{}}
              ></Input>
            </div>
            <div className="lohnsumme__button">
              <Button
                className="button"
                submit={true}
                buttonType={EButtonType.FullWidth}
                onClick={() => this.next(this.state.value)}
                // Changed by Glauk, necessary?
                isDisabled={!this.state.value}
              >
                <div>WEITER</div>
              </Button>
            </div>
          </div>
          <FormattedMessageCustom
            id={translatableStrings.whereToFindCapital.id}
            text={this.props.intl.formatMessage(
              translatableStrings.whereToFindCapital
            )}
          >
            <div className="inform stagger" onClick={this.showHideNotif}></div>
          </FormattedMessageCustom>
        </div>
        <div className="optional" />
      </div>
    );
  }
}

function mapStateToProps(state: RootState, ownProps: any) {
  return {
    ...ownProps,
    ...state.app,
  };
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
  return {
    setUserDataLohnklasse: (data) =>
      dispatch(actions.setUserDataLohnklasse(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Lohnklasse);
