import { getParameterByName } from '../utils/toSearchParams';
import * as constants from '../constants';
import { toastr } from 'react-redux-toastr';
import { defined } from '../utils/variableEvaluation';
const apiUrl = process.env.REACT_APP_REST_API_URL;
const insurantPortalUrl = process.env.REACT_APP_INSURANCEPORTAL_URL;

export interface ACTION {
    type: string;
    payload?: any;
}

export const setTenant = (payload: string): ACTION => ({
    type: constants.TENANT_SET,
    payload
});

export const setMonthlyOrYearly = (payload: string): ACTION => ({
    type: constants.SET_MONTHLY_OR_YEARLY,
    payload
});

// export const setLang = (payload: string): ACTION => ({
//     type: constants.LANG_SET,
//     payload
// });

export const loginAct = (payload: any): ACTION => ({
    type: constants.LOGIN,
    payload
});

export const loginSuccess = (payload: any): ACTION => ({
    type: constants.LOGIN_SUCCESS,
    payload
});

export const logOut = (): ACTION => ({
    type: constants.LOG_OUT,
});

export const setLoading = (payload: boolean): ACTION => ({
    type: constants.LOADING,
    payload
});

export function clearData(payload, dispatch) {
    dispatch(clearDataAction(payload));
    // this triggers settings refetch
    (window as any).location.reload(true);
}

export const clearDataAction = (payload: string): ACTION => ({
    type: constants.CLEAR_DATA,
    payload
});


export const setUserDataAnliegen = (payload: string): ACTION => ({
    type: constants.USERDATA_ANLIEGEN,
    payload
});
export const setUserDataDetaillierung = (payload: string): ACTION => ({
    type: constants.USERDATA_DETAILLIERUNG,
    payload
});
export const setUserDataDatenquelle = (payload: string): ACTION => ({
    type: constants.USERDATA_DATENQUELLE,
    payload
});
export const setUserDataGeburtsdatum = (payload: string): ACTION => ({
    type: constants.USERDATA_GEBURTSDATUM,
    payload
});
export const setUserDataGeschlecht = (payload: string): ACTION => ({
    type: constants.USERDATA_GESCHLECHT,
    payload
});
export const setUserDataWohnort = (payload: string): ACTION => ({
    type: constants.USERDATA_WOHNORT,
    payload
});
export const setUserDataAusgestelltAm = (payload: string): ACTION => ({
    type: constants.USERDATA_AUSGESTELLTAM,
    payload
});
export const setUserDataLohnsumme = (payload: string): ACTION => ({
    type: constants.USERDATA_LOHNSUMME,
    payload
});
export const setUserDataBeschaftigungsgrad = (payload: string): ACTION => ({
    type: constants.USERDATA_BESCHAFTIGUNGSGRAD,
    payload
});
export const setUserDataSparguthaben = (payload: string): ACTION => ({
    type: constants.USERDATA_SPARGUTHABEN,
    payload
});
export const setUserDataAHVRente = (payload: string): ACTION => ({
    type: constants.USERDATA_AHVRENTE,
    payload
});
export const setUserDataVPKonto = (payload: string): ACTION => ({
    type: constants.USERDATA_VPKONTO,
    payload
});
export const setUserDataVPKontoAmount = (payload: string): ACTION => ({
    type: constants.USERDATA_VPKONTO_AMOUNT,
    payload
});
export const setUserDataRentenanteil = (payload: string): ACTION => ({
    type: constants.USERDATA_RENTENANTEIL,
    payload
});
export const setUserDataArbeitspensum = (payload: string): ACTION => ({
    type: constants.USERDATA_ARBEITSPENSUM,
    payload
});
export const setUserDataBruttolohn = (payload: string): ACTION => ({
    type: constants.USERDATA_BRUTTOLOHN,
    payload
});
export const setUserDataZeitpunkt = (payload: string): ACTION => ({
    type: constants.USERDATA_ZEITPUNKT,
    payload
});
export const setUserDataLohnklasse = (payload: string): ACTION => ({
    type: constants.USERDATA_LOHNKLASSE,
    payload
});
export const setUserDataInsurancePlan = (payload: string): ACTION => ({
    type: constants.USERDATA_INSURANCE_PLAN,
    payload
});
export const userDataWIZeitpunktUpdate = (payload: string): ACTION => ({
    type: constants.USERDATA_ZEITPUNKT_UPDATE,
    payload
});
export const removeTeilPension = (payload: string): ACTION => ({
    type: constants.USERDATA_TEILPENSION_REMOVE,
    payload
});

export const setStepsOpened = (payload: string): ACTION => ({
    type: constants.SET_STEPS_OPENED,
    payload
});

export const clearControls = (payload: string): ACTION => ({
    type: constants.CLEAR_CONTROLS,
    payload
});

export const setCapitalPurchase = (payload: string): ACTION => ({
    type: constants.USERDATA_CAPITAL_PURCHASE,
    payload
});

export const setCapitalWithdrawal = (payload: string): ACTION => ({
    type: constants.USERDATA_CAPITAL_WITHDRAWAL,
    payload
});

export const setBridgingPension = (payload: any): ACTION => ({
    type: constants.USERDATA_BRIDGINGPENSION,
    payload
});

export const setDivorcePostings = (payload: Array<{ date: string, value: number }>): ACTION => ({
    type: constants.USERDATA_DIVORCEPOSTINGS,
    payload
});

export const removeCapitalPurchase = (payload: string): ACTION => ({
    type: constants.USERDATA_CAPITAL_PURCHASE_REMOVE,
    payload
});

export const forceRecalculate = (payload: string): ACTION => ({
    type: constants.FORCE_RECALCULATE,
    payload
});

export const setInsurantData = (payload: string): ACTION => ({
    type: constants.FETCHED_INSURANT_DATA,
    payload
});

export const setGlobalSettings = (payload: any): ACTION => ({
    type: constants.FETCHED_GLOBAL_SETTINGS,
    payload
});

export const setSincronizeMaxPurchases = (): ACTION => ({
    type: constants.SINCRONIZE_MAXPURCHASES,
});

// export const autoFillData = (payload: any): ACTION => ({
//     type: constants.AUTOFILL_DATA,
//     payload
// });

export const calculatePost = (payload) => wrap((dispatch) => {
    console.log('calculatePost2');
    dispatch(setLoading(true));
    const options = {
        // method: 'GET',
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            // "Authorization": `Bearer ${apiToken}`,
            // ...headers
        },
        body: payload ? JSON.stringify(payload) : null
    }
    return fetch(`${apiUrl}/calculate/`, options).then((res) => {
        return res.json().then((data) => {
            console.log('data calculatePost then', data);
            dispatch(setLoading(false));
            if (data.data) {
                dispatch({ "type": constants.FETCHED_CALCULATIONS, "payload": { ...data, posted: payload.data } });
                return data;
            } else if (data.errors[0]) {
                setTimeout(() => {
                    dispatch({ "type": constants.FETCHED_CALCULATIONS_FAILED, "payload": { ...data, posted: payload.data } });
                }, 10);
                throw (data.errors[0]);
            } else {
                throw (data)
            }
        })
    }).catch((err) => {
        console.log('error: ', err);
        dispatch(setLoading(false));
        throw (err);
    })
}, 'POST_ERROR');

export const simulatePost = (payload) => wrap((dispatch) => {
    console.log('calculatePost2');
    dispatch(setLoading(true));
    const options = {
        // method: 'GET',
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            // "Authorization": `Bearer ${apiToken}`,
            // ...headers
        },
        body: payload ? JSON.stringify(payload) : null
    }
    return fetch(`${apiUrl}/simulate/`, options).then((res) => {
        return res.json().then((data) => {
            console.log('data calculatePost then', data);
            dispatch(setLoading(false));
            if (data.data) {
                dispatch({ "type": constants.FETCHED_CALCULATIONS, "payload": { ...data, posted: payload.data } });
                return data;
            } else if (data.errors[0]) {
                setTimeout(() => {
                    dispatch({ "type": constants.FETCHED_CALCULATIONS_FAILED, "payload": { ...data, posted: payload.data } });
                }, 10);
                throw (data.errors[0]);
            } else {
                throw (data)
            }
        })
    }).catch((err) => {
        console.log('error: ', err);
        dispatch(setLoading(false));
        throw (err);
    })
}, 'POST_ERROR');

export const calculateMaxPurchase = (payload) => wrap((dispatch) => {
    // dispatch(setLoading(true));
    const options = {
        // method: 'GET',
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            // "Authorization": `Bearer ${apiToken}`,
            // ...headers
        },
        body: payload ? JSON.stringify(payload) : null
    };
    const date = payload.data.purchaseDate;
    return fetch(`${apiUrl}/calculatemaxpurchase/`, options).then((res) => {
        return res.json().then((data) => {
            console.log("data?: ", data)
            // dispatch(setLoading(false));
            if (data.data.value !== 0) {
                const payload = { "date": date, "max": data.data.value }
                dispatch({ "type": constants.FETCHED_MAX_PURCHASE, "payload": payload });
                return data;
            }
        })
    }).catch((err) => {
        // dispatch(setLoading(false));
        throw (err);
    })
}, 'POST_ERROR');

export const currentSituation = (payload) => wrap((dispatch) => {
    const options = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            // "Authorization": `Bearer ${apiToken}`,
            // ...headers
        },
        body: payload ? JSON.stringify(payload) : null
    };
    return fetch(`${apiUrl}/simulate/currentSituation/`, options).then((res) => {
        return res.json().then((data) => {
            const currentCreditBalance = data.data.currentCreditBalance ? data.data.currentCreditBalance : null
            dispatch({ "type": constants.USERDATA_DIVORCETOTALVALUE, "payload": currentCreditBalance });
            return currentCreditBalance;
        })
    }).catch((err) => {
        // dispatch(setLoading(false));
        throw (err);
    })
}, 'POST_ERROR');


export const fetchSettings = () => wrap((dispatch) => {
    // dispatch(setLoading(true));
    const options = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
        },
    }

    const tenantName = JSON.parse((window as any).localStorage.getItem("userDataWI"))?.tenantName;
    const tenantParam = getParameterByName('tenantName') || getParameterByName('tenantName');
    console.log('tenantName', tenantName, tenantParam);
    let tenant = defined(tenantParam) ? tenantParam : tenantName;
    if (tenant === undefined) {
        toastr.error(`Your url is misssing tenantName parameter`, { timeOut: 3000 });
    }
    return fetch(`${apiUrl}/globalsettings?tenantName=${tenant}`, options).then((res) => {
        return res.json().then((data) => {
            // dispatch(setLoading(false));
            dispatch({ "type": constants.FETCHED_SETTINGS, "payload": data });
            return data;
        })
    }).catch((err) => {
        console.log('error: ', err);
        // dispatch(setLoading(false));
        throw (err);
    })
}, 'FETCH_ERROR');


export const fetchInsurantData = (token, url) => wrap((dispatch) => {
    // dispatch(setLoading(true));
    const options = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
        },
    }

    return fetch(`${insurantPortalUrl}/jsonapi/v1/insurantdata?token=${token}`, options).then((res) => {
        return res.json().then((data) => {
            dispatch({
                "type": constants.FETCHED_INSURANT_DATA,
                "payload": {
                    data: data.data,
                    url: url
                }
            });
            return data;
        });
    }).catch((err) => {
        console.log('error: ', err);
        // dispatch(setLoading(false));
        throw (err);
    })
}, 'FETCH_ERROR');



// handling errors from redux-thunk async action creators
// source: https://thecodebarbarian.com/async-await-with-react-and-redux-thunk
export const wrap = (fn, type = '') => {
    return function (dispatch) {
        return fn(dispatch).catch((error) => {
            console.log('error in wrap: ', error);
            dispatch({ type, error: error.message });
            // eslint-disable-next-line
            toastr.error(`Etwas ist schief gelaufen!`, error.code + ": " + error.title, { timeOut: 3000 });
        });
    };
}



