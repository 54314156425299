import React from 'react';
import './Chart.scss';
// import { ChartSettings } from './chartSettings';
import { BlockCapital } from './BlockCapital';
import { BlockSalary } from './BlockSalary';
import { BlockPartialPension } from './BlockPartialPension';
import { BlockEarlyPension } from './BlockEarlyPension';
// import { BlockAHVPension } from './BlockAHVPension';
import { BlockPension } from './BlockPension';
import { Block65plus } from './Block65plus';
import { formatNumber } from "../../../utils/react";
// import { debounce } from '../../../utils/react';
import { Button, EButtonType } from "../../../components/Button";
import { Grid } from '../Grid';
// import { ChartSettings } from './chartSettings';
import { ReactComponent as IconPlus } from '../../../assets/icons/plus.svg';
import classNames from 'classnames';
import Tooltip from 'rc-tooltip';
// import { ReactComponent as HelpIcon } from "../../../../assets/icons/Help Icon.svg";
// import { Handles } from 'react-compound-slider';

import { defineMessages } from 'react-intl';
import FormattedMessageCustom from '../../../components/FormattedMessageCustom';

const translatableStrings = defineMessages({
    info: {
        id: 'pageChart.info',
        defaultMessage: 'Info'
    },
    salary: {
        id: 'pageChart.salary',
        defaultMessage: 'Lohn'
    },
    partialPension: {
        id: 'pageChart.partialPension',
        defaultMessage: 'Teilpension'
    },
    teilpensionNotPossible: {
        id: 'pageChart.teilpensionNotPossible',
        defaultMessage: 'Es ist maximal ein Teilpensionierungsschritt möglich.'
    },
    teilpensionNotPossiblePKAR: {
        id: 'pageChart.teilpensionNotPossiblePKAR',
        defaultMessage: 'Es kann aus technischen Gründen nur ein Teilpensionierungsschritt erfasst werden.'
    }
});
interface State {
    alignToStart: boolean;
}

interface Props {
    ages: Array<number>;
    ahvPensionierungObj: any;
    animateCounter: number;
    arbeitPensums: Array<any>;
    totalCapitalForAnnnuity: number;
    blockWidths: Array<any>;
    calculateCounterTrigger: number;
    calculationSteps: any;
    capitalPurchaseAmountMax: number;
    capitalWithdrawals: Array<number>;
    capitalWithdrawalsTotal: number;
    chartRightRef: any;
    containerWidthPx: number;
    conversionRate: number;
    currentAge: Array<number>;
    currentMonths: Array<number>;
    currentYears: Array<number>;
    descendingStepsToEnteringIndex: any;
    defaultRetirmentAgeFemale: number;
    defaultRetirmentAgeMale: number;
    earlyPensionObj: any;
    erwerbstatigkeitObj: any;
    firstAltersrente: any;
    gender: string;
    goToRenten: any;
    handleDrag: any;
    handleWrapperWidth: number;
    handlesLimits: Array<any>;
    handlesXPos: Array<number>;
    intl: any;
    lang: string;
    monthlyOrYearly: string;
    months: Array<number>;
    nominalRetirementDate: any;
    openModal: any;
    partialPensions: any;
    pensionEndDate?: number;
    pensionierungObj: any;
    purchasedCapitals: Array<number>;
    removeTeilPension: any;
    rerender: any;
    retirementPercentages: Array<any>;
    retirementSteps: Array<any>;
    retirementStepsInitial: any;
    salaryEndDate?: number;
    sparguthaben: number;
    startDragging: any;
    totalPossibleCapital: number;
    totalCapital: number;
    totalCapitalForBridgingPension: number;
    updateOnResize: any;
    userDataWI: any;
    yearMax: number;
    yearMin: number;
    yearRatio: number;
    yearWidthPx: number;
    yearWidthPxWhenNotDragging: number;
    years: Array<number>;
}


export class Chart extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            alignToStart: true,
        }
        console.log("Chart :: created", document.querySelector('.grid'));
    }
    private chartContainer: any;

    componentDidMount() {
        // console.log('Chart :: did mount', this.props, this.chartRightRef.clientWidth);
    }

    componentWillUnmount() {
        // document.body.classList.remove("Visualisation");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("Chart :: componenentDidUpdate");
        if (this.props.animateCounter > 1) {
            // console.log('will add forcebvisible', document.querySelector('.addTeilContainer .addTeilButton2') );
            (document.querySelector('.addTeilContainer .addTeilButton2') as any).classList.add("forceVisible");
        };
        // Object.entries(this.props).forEach(([key, val]) =>
        //     prevProps[key] !== val && console.log(`Prop chart '${key}' changed`)
        // );
        // if (this.state) {
        //     Object.entries(this.state).forEach(([key, val]) =>
        //         prevState[key] !== val && console.log(`State chart'${key}' changed`)
        //     );
        // }
    }

    removeHandle = (date, idx) => {
        console.log('removeHandle', date, idx);
        this.props.removeTeilPension(date, idx);
    }

    rerender = (xPosPx, idx, fromResize) => {
        // console.log('rerender Chart xPosPx', xPosPx);
        this.props.rerender(xPosPx, idx, fromResize);
    }

    wrapInTooltip = (el, text, showBoolean, placement?) => {
        console.log('wrapintooltipPension', el, text, showBoolean);
        if (showBoolean) {
            return <Tooltip
                mouseEnterDelay={0}
                mouseLeaveDelay={0}
                destroyTooltipOnHide={true}
                placement={placement ? placement : 'top'}
                trigger={"hover"}
                key={Math.random() + "tooltip"}
                overlay={<div>{text}</div>}
            >
                {el}
            </Tooltip>
        } else {
            return el;
        }
    }

    getMaxVal = () => {
        let bridgingPensions = [];
        // let capitalWithdrawals = [];
        // let capitalBuyIns = [];

        this.props.calculationSteps.forEach((step, idx) => {
            step && bridgingPensions.push(step[this.props.monthlyOrYearly].bridgingPension);
        })

        // this.props.sliderValues.forEach((slider, idx) => {
        //     slider.bridgingPensionAmount && bridgingPensions.push(slider.bridgingPensionAmount);
        // })

        // console.log('getMaxVal: ', this.props.pensionierungObj[this.props.monthlyOrYearly] && this.props.pensionierungObj[this.props.monthlyOrYearly].totalIncome,
        // 'totalincome salary', this.props.erwerbstatigkeitObj[this.props.monthlyOrYearly] && this.props.erwerbstatigkeitObj[this.props.monthlyOrYearly].totalIncome,
        // 'bridignpensions: ', bridgingPensions)
        return this.props.pensionierungObj[this.props.monthlyOrYearly] ? Math.max(
            this.props.pensionierungObj[this.props.monthlyOrYearly].totalIncome,
            // this.props.ahvPensionierungObj[this.props.monthlyOrYearly].totalIncome,
            this.props.erwerbstatigkeitObj[this.props.monthlyOrYearly].totalIncome,
            ...bridgingPensions) + 30 : 0;
    }

    calculateYearWidhts = () => {
        let widths = new Array(12).fill(0);
        console.log('widths', widths);
        let lastAge = 58;
        let yearIdx = 0;
        let deltaAge = 0;
        this.props.blockWidths.forEach((blockObj, idx) => {
            deltaAge = blockObj.age - lastAge;
            const currentAge = blockObj.age;
            yearIdx = blockObj.age - 58;
            // console.log(
            // 'blockObj', blockObj,
            //     'lastAge', lastAge,
            //     'currentAge', currentAge,
            //     'deltaAge', deltaAge,
            //     'yearIdx', yearIdx,
            //     'idx', idx,
            //     'width', blockObj.width,
            //     'name', blockObj.name,
            //     'widths', widths
            // );
            if (deltaAge > 0 && lastAge < 65) {
                const widthDivided = (currentAge === lastAge) ? blockObj.width / (deltaAge || 1) : blockObj.width / deltaAge;
                // console.log('widthDivided', widthDivided);
                for (let index = 0; index <= yearIdx; index++) {
                    // console.log('for index', index, 'width index',widths[index], 'yearidx', yearIdx);
                    if ((widths[index] === 0 && (yearIdx < 7)) || (widths[index] === 0 && blockObj.name === "early")) {
                        widths[index] = widthDivided
                    }
                }
            } else if ((currentAge <= 65 && yearIdx < 7)) {
                widths[yearIdx] += blockObj.width;
            }
            lastAge = currentAge;
            // console.log('widthsAfter', widths);
        });
        // console.log('calculateYearWidths', this.props.blockWidths, 'widths', widths);
        return widths;
    }

    render() {
        return (
            <div className={classNames('Chart crossFadeOut', {
                // "retirementPossible": this.props.userDataWI.partialPensionLimits.isAdditionalStepPossible
            })}>
                <div className="blockContainer" ref={c => this.chartContainer = c} >
                    <div className="capitalMobile d-flex d-md-none flex-column" style={{ 'opacity': 0 }}>
                        <p className="font-weight-bold">Kapital <span className="font-weight-normal">
                            CHF {formatNumber(this.props.totalCapitalForAnnnuity)}
                        </span>
                            <Button
                                className="button openCapitalButton"
                                onClick={() => this.props.openModal("capital")}
                                buttonType={EButtonType.BasicLink}
                            >
                                <FormattedMessageCustom id={translatableStrings.info.id} text={this.props.intl.formatMessage(translatableStrings.info)} />
                            </Button></p>
                        {
                            this.props.retirementSteps.length === 1 && <p className="font-weight-bold" >Umwandlungssatz <span className="font-weight-normal">{(0).toFixed(3)}%</span></p>
                        }
                    </div>

                    <div className="chartLeft d-none d-md-flex">
                        <div className="chartLeftTop">
                            <BlockCapital
                                // capitalPurchaseAmountMax={this.props.capitalPurchaseAmountMax}
                                animateCounter={this.props.animateCounter}
                                bridgingPensionAmount={this.props.totalCapitalForBridgingPension}
                                calculateCounterTrigger={this.props.calculateCounterTrigger}
                                capitalWithdrawals={this.props.capitalWithdrawals}
                                capitalWithdrawalsTotal={this.props.capitalWithdrawalsTotal}
                                conversionRate={this.props.conversionRate}
                                intl={this.props.intl}
                                openModal={this.props.openModal}
                                partialPensionsNo={this.props.partialPensions.length}
                                purchasedCapitals={this.props.purchasedCapitals}
                                sparguthaben={this.props.sparguthaben}
                                totalCapital={this.props.totalCapital || 1}
                                totalCapitalForAnnnuity={this.props.totalCapitalForAnnnuity}
                                totalPossibleCapital={this.props.totalPossibleCapital || 1}
                                wrapInTooltip={this.wrapInTooltip}
                            />
                        </div>
                        <div className="grid">
                            {this.wrapInTooltip(
                                <div key="add1" className="w-100 addTeilContainer d-block">
                                    <Button
                                        className={classNames(
                                            "button text-center strokeGrey font-12 text-uppercase font-600 addTeilButton2", {
                                            "invisible": this.props.partialPensions.length === 4
                                        }
                                        )}
                                        isDisabled={!this.props.userDataWI.partialPensionLimits.isAdditionalStepPossible}
                                        onClick={this.props.goToRenten}
                                        buttonType={EButtonType.InvertedPill}
                                    >
                                        <span style={{
                                            marginRight: 12
                                        }}>
                                            <IconPlus />
                                        </span>
                                        <FormattedMessageCustom id={translatableStrings.partialPension.id} text={this.props.intl.formatMessage(translatableStrings.partialPension)} />
                                    </Button>
                                </div>,
                                <FormattedMessageCustom id={translatableStrings[this.props.userDataWI.tenantName.toUpperCase() == 'PKAR' ? 'teilpensionNotPossiblePKAR' : 'teilpensionNotPossible'].id} text={this.props.intl.formatMessage(translatableStrings[this.props.userDataWI.tenantName.toUpperCase() == 'PKAR' ? 'teilpensionNotPossiblePKAR' : 'teilpensionNotPossible'])} />,
                                !this.props.userDataWI.partialPensionLimits.isAdditionalStepPossible, "bottom"
                            )}
                        </div>

                    </div>

                    <div className="scrollableContainer">
                        <div className={classNames("chartRight", {
                            "earlyPension": this.props.earlyPensionObj,
                            "teilPensions4": this.props.retirementSteps.length === 5.
                        })}
                            ref={this.props.chartRightRef}>
                            <div className={classNames("chartRightTop", {
                                // "spaceBetween": this.props.partialPensions.length === 0 || !Boolean(this.props.earlyPensionObj[this.props.monthlyOrYearly]) 
                                "flexStart": this.state.alignToStart,
                                // "flexStart": this.props.partialPensions.length > 0,
                            })}
                            // ref={this.props.chartRightTopRef}
                            >
                                {/* {console.log('test', this.props.blockWidths)} */}
                                <BlockSalary
                                    intl={this.props.intl}
                                    animateCounter={this.props.animateCounter}
                                    width={this.props.blockWidths[0] ? this.props.blockWidths[0].width : 1}
                                    salary={this.props.erwerbstatigkeitObj[this.props.monthlyOrYearly] ? this.props.erwerbstatigkeitObj[this.props.monthlyOrYearly].salary : 0}
                                    partialPensionsNo={this.props.partialPensions.length}
                                    isEarlyPensionPresent={Boolean(this.props.earlyPensionObj[this.props.monthlyOrYearly])}
                                    totalIncome={this.props.erwerbstatigkeitObj[this.props.monthlyOrYearly] ? this.props.erwerbstatigkeitObj[this.props.monthlyOrYearly].totalIncome : 0}
                                    maxVal={this.getMaxVal()}
                                    segment={1}
                                    wrapInTooltip={this.wrapInTooltip}
                                />

                                {this.props.partialPensions[0] && this.props.handlesXPos.length > 1 && <BlockPartialPension
                                    animateCounter={this.props.animateCounter}
                                    // activateSegment={this.props.activateSegment}
                                    // activeSegment={this.props.activeSegment}
                                    bridgingPensionAmount={this.props.partialPensions[0] ? this.props.partialPensions[0][this.props.monthlyOrYearly].bridgingPension : 0}
                                    segment={2}
                                    intl={this.props.intl}
                                    partialPensionsNo={this.props.partialPensions.length}
                                    maxVal={this.getMaxVal()}
                                    salary={this.props.partialPensions[0][this.props.monthlyOrYearly].salary}
                                    basicPension={this.props.partialPensions[0][this.props.monthlyOrYearly].basicPension}
                                    totalIncome={this.props.partialPensions[0][this.props.monthlyOrYearly].totalIncome}
                                    totalPension={this.props.partialPensions[0][this.props.monthlyOrYearly].totalPension}
                                    isEarlyPensionPresent={Boolean(this.props.earlyPensionObj[this.props.monthlyOrYearly])}
                                    width={this.props.blockWidths[1].width}
                                    wrapInTooltip={this.wrapInTooltip}
                                />}
                                {this.props.partialPensions[1] && this.props.handlesXPos.length > 2 && <BlockPartialPension
                                    animateCounter={this.props.animateCounter}
                                    intl={this.props.intl}
                                    // activateSegment={this.props.activateSegment}
                                    // activeSegment={this.props.activeSegment}
                                    bridgingPensionAmount={this.props.partialPensions[1] ? this.props.partialPensions[1][this.props.monthlyOrYearly].bridgingPension : 0}
                                    salary={this.props.partialPensions[1][this.props.monthlyOrYearly].salary}
                                    basicPension={this.props.partialPensions[1][this.props.monthlyOrYearly].basicPension}
                                    partialPensionsNo={this.props.partialPensions.length}
                                    isEarlyPensionPresent={Boolean(this.props.earlyPensionObj[this.props.monthlyOrYearly])}
                                    totalIncome={this.props.partialPensions[1][this.props.monthlyOrYearly].totalIncome}
                                    totalPension={this.props.partialPensions[1][this.props.monthlyOrYearly].totalPension}
                                    segment={3}
                                    maxVal={this.getMaxVal()}
                                    // salary={850}
                                    width={this.props.blockWidths[2].width}
                                    wrapInTooltip={this.wrapInTooltip}
                                />}
                                {this.props.partialPensions[2] && this.props.handlesXPos.length > 3 && <BlockPartialPension
                                    animateCounter={this.props.animateCounter}
                                    // activateSegment={this.props.activateSegment}
                                    // activeSegment={this.props.activeSegment}
                                    bridgingPensionAmount={this.props.partialPensions[2] ? this.props.partialPensions[2][this.props.monthlyOrYearly].bridgingPension : 0}
                                    intl={this.props.intl}
                                    salary={this.props.partialPensions[2][this.props.monthlyOrYearly].salary}
                                    partialPensionsNo={this.props.partialPensions.length}
                                    isEarlyPensionPresent={Boolean(this.props.earlyPensionObj[this.props.monthlyOrYearly])}
                                    basicPension={this.props.partialPensions[2][this.props.monthlyOrYearly].basicPension}
                                    totalIncome={this.props.partialPensions[2][this.props.monthlyOrYearly].totalIncome}
                                    totalPension={this.props.partialPensions[2][this.props.monthlyOrYearly].totalPension}
                                    segment={4}
                                    maxVal={this.getMaxVal()}
                                    // salary={400}
                                    wrapInTooltip={this.wrapInTooltip}
                                    width={this.props.blockWidths[3].width}
                                />}
                                {this.props.partialPensions[3] && this.props.handlesXPos.length > 4 && <BlockPartialPension
                                    animateCounter={this.props.animateCounter}
                                    // activateSegment={this.props.activateSegment}
                                    // activeSegment={this.props.activeSegment}
                                    bridgingPensionAmount={this.props.partialPensions[3] ? this.props.partialPensions[3][this.props.monthlyOrYearly].bridgingPension : 0}
                                    segment={5}
                                    intl={this.props.intl}
                                    salary={this.props.partialPensions[3][this.props.monthlyOrYearly].salary}
                                    basicPension={this.props.partialPensions[3][this.props.monthlyOrYearly].basicPension}
                                    partialPensionsNo={this.props.partialPensions.length}
                                    isEarlyPensionPresent={Boolean(this.props.earlyPensionObj[this.props.monthlyOrYearly])}
                                    totalIncome={this.props.partialPensions[3][this.props.monthlyOrYearly].totalIncome}
                                    totalPension={this.props.partialPensions[3][this.props.monthlyOrYearly].totalPension}
                                    maxVal={this.getMaxVal()}
                                    wrapInTooltip={this.wrapInTooltip}
                                    width={this.props.blockWidths[4].width}
                                />}
                                {this.props.earlyPensionObj[this.props.monthlyOrYearly] && <BlockEarlyPension
                                    animateCounter={this.props.animateCounter}
                                    calculateCounterTrigger={this.props.calculateCounterTrigger}
                                    basicPension={this.props.earlyPensionObj[this.props.monthlyOrYearly] ? this.props.earlyPensionObj[this.props.monthlyOrYearly].basicPension : 0}
                                    bridgingPensionAmount={this.props.earlyPensionObj[this.props.monthlyOrYearly] ? this.props.earlyPensionObj[this.props.monthlyOrYearly].bridgingPension : 0}
                                    maxVal={this.getMaxVal()}
                                    partialPensionsNo={this.props.partialPensions.length}
                                    segment={1}
                                    intl={this.props.intl}
                                    totalIncome={this.props.earlyPensionObj[this.props.monthlyOrYearly] ? this.props.earlyPensionObj[this.props.monthlyOrYearly].totalIncome : 0}
                                    width={this.props.blockWidths[this.props.blockWidths.length - 2].width}
                                    wrapInTooltip={this.wrapInTooltip}
                                />}
                                <BlockPension
                                    // capitalWithdrawalPercentage={this.props.sliderValues[0]['capitalWithdrawalPercentage']}
                                    ahvPension={this.props.pensionierungObj[this.props.monthlyOrYearly] ? this.props.pensionierungObj[this.props.monthlyOrYearly].ahvPension : 0}
                                    ahvPensionierungObj={this.props.ahvPensionierungObj}
                                    animateCounter={this.props.animateCounter}
                                    basicPension={this.props.pensionierungObj[this.props.monthlyOrYearly] ? this.props.pensionierungObj[this.props.monthlyOrYearly].basicPension : 0}
                                    intl={this.props.intl}
                                    isEarlyPensionPresent={Boolean(this.props.earlyPensionObj[this.props.monthlyOrYearly])}
                                    firstPension={(this.props.firstAltersrente && this.props.firstAltersrente[this.props.monthlyOrYearly]) ? this.props.firstAltersrente[this.props.monthlyOrYearly] : 0}
                                    maxVal={this.getMaxVal()}
                                    nominalRetirementDate={this.props.nominalRetirementDate}
                                    retirementSteps={this.props.retirementSteps}
                                    salary={this.props.pensionierungObj[this.props.monthlyOrYearly] ? this.props.pensionierungObj[this.props.monthlyOrYearly].salary : 0}
                                    segment={this.props.handlesXPos.length + 1}
                                    totalIncome={this.props.pensionierungObj[this.props.monthlyOrYearly] ? this.props.pensionierungObj[this.props.monthlyOrYearly].totalIncome : 0}
                                    width={this.props.blockWidths[this.props.blockWidths.length - 1] ? this.props.blockWidths[this.props.blockWidths.length - 1].width : 1}
                                    wrapInTooltip={this.wrapInTooltip}
                                    year={this.props.ages[0]}
                                />
                                {(Object.keys(this.props.ahvPensionierungObj).length > 0) &&
                                    // (parseDate(this.props.nominalRetirementDate, "dateObject") < parseDate(this.props.retirementSteps[0], "dateObject")) && 
                                    this.props.ahvPensionierungObj.label === "AHV-Pensionierung" &&
                                    <Block65plus
                                        ahvPension={this.props.pensionierungObj[this.props.monthlyOrYearly] ? this.props.pensionierungObj[this.props.monthlyOrYearly].ahvPension : 0}
                                        animateCounter={this.props.animateCounter}
                                        basicPension={this.props.firstAltersrente[this.props.monthlyOrYearly] ? this.props.firstAltersrente[this.props.monthlyOrYearly] : 0}
                                        containerWidthPx={this.props.containerWidthPx}
                                        gender={this.props.gender}
                                        maxVal={this.getMaxVal()}
                                        nominalRetirementDate={this.props.nominalRetirementDate}
                                        retirementSteps={this.props.retirementSteps}
                                        defaultRetirmentAgeFemale={this.props.defaultRetirmentAgeFemale}
                                        defaultRetirmentAgeMale={this.props.defaultRetirmentAgeMale}
                                    // width={this.props.containerWidthPx - ((document.querySelector('.gridYear[data-age="65"]') as any) ? (document.querySelector('.gridYear[data-age="65"]') as any).offsetLeft : 0) - 35}
                                    // height={(document.querySelector('#barPensionGovWrapper') as any) ? (document.querySelector('#barPensionGovWrapper') as any).clientHeight : 0}
                                    />}
                                {/* {this.props.calculationSteps[1] && this.props.calculationSteps[1].label === "AHV-Pensionierung" && <BlockAHVPension
                                // animateCounter={this.state.blockEarlyPensionAnimateCounter}
                                animateCounter={this.props.animateCounter}
                                basicPension={this.props.ahvPensionierungObj[this.props.monthlyOrYearly].basicPension}
                                salary={this.props.ahvPensionierungObj[this.props.monthlyOrYearly].salary}
                                bridgingPensionAmount={this.props.sliderValues[0]['bridgingPensionAmount']}
                                maxVal={this.getMaxVal()}
                                segment={1}
                                totalIncome={this.props.ahvPensionierungObj[this.props.monthlyOrYearly].totalIncome}
                                width={this.props.blockWidths[1]}
                                wrapInTooltip={this.wrapInTooltip}
                            />}                                 */}

                            </div>
                            <Grid
                                // handleDate={[this.props.pensionStartDate, ...this.props.retirementSteps]}
                                ages={this.props.ages}
                                blockWidths={this.props.blockWidths}
                                yearWidths={this.calculateYearWidhts()}
                                containerWidthPx={this.props.containerWidthPx}
                                isEarlyPensionPresent={Boolean(this.props.earlyPensionObj[this.props.monthlyOrYearly])}
                                currentAge={this.props.currentAge}
                                currentMonths={this.props.currentMonths}
                                // draggingDisabled={this.props.draggingDisabled}
                                currentYears={this.props.currentYears}
                                handleDrag={this.props.handleDrag}
                                handleWrapperWidth={this.props.handleWrapperWidth}
                                handlesXPos={this.props.handlesXPos}
                                handlesLimits={this.props.handlesLimits}
                                initialHandleDate={[...this.props.retirementStepsInitial]}
                                intl={this.props.intl}
                                nominalRetirementDate={this.props.nominalRetirementDate}
                                months={this.props.months}
                                lang={this.props.lang}
                                removeHandle={(date, idx) => this.removeHandle(date, idx)}
                                rerender={this.rerender}
                                // resetControls={this.props.resetControls}
                                retirementPercentages={this.props.retirementPercentages}
                                retirementSteps={this.props.retirementSteps}
                                startDragging={this.props.startDragging}
                                updateOnResize={this.props.updateOnResize}
                                yearMax={this.props.yearMax}
                                yearMin={this.props.yearMin}
                                yearRatio={this.props.yearRatio}
                                yearWidthPx={this.props.yearWidthPx}
                                yearWidthPxWhenNotDragging={this.props.yearWidthPxWhenNotDragging}
                                years={this.props.years}
                                descendingStepsToEnteringIndex={this.props.descendingStepsToEnteringIndex}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// @ts-ignore
export default React.forwardRef((props, ref) => <Chart chartRightRef={ref} {...props} />)
