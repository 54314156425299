import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
// import { bindActionCreators } from 'redux';
import { RootState } from '../../../../reducers/index';
import './BlockSalary.scss';
import gsap from 'gsap';
import { ChartSettings } from '../chartSettings';
import { formatNumber } from '../../../../utils/react';
import { animationConfig } from '../../../../constants';
// import { start } from 'repl';
import { defineMessages } from 'react-intl';
import FormattedMessageCustom from '../../../../components/FormattedMessageCustom';

const translatableStrings = defineMessages({
    employment: {
        id: 'pageChart.employment',
        defaultMessage: 'Erwerbstätigkeit'
    },
    salary: {
        id: 'pageChart.salary',
        defaultMessage: 'Lohn'
    }
});
interface State {
    // mobileLabelPos: number;
}

interface Props {
    animateCounter: number;
    width: any;
    // manipulating: boolean;
    salary: number;
    totalIncome: number;
    maxVal: number;
    intl: any;
    // activateSegment: any;
    segment: number;
    isEarlyPensionPresent: boolean;
    // salaryStartDate: number;
    // salaryEndDate: number;
    wrapInTooltip: any;
    partialPensionsNo: number;
}

export class BlockSalary extends React.Component<Props, State> {
    private barSalaryLabel = null;
    private barSalaryPerc = null;
    private barMain = null;
    private percTitle = null;
    private percValue = null;

    constructor(props: any) {
        super(props);
        console.log("BlockSalary constructor called");
        this.state = {
            // mobileLabelPos: 17
        };
    }
    private timeline = null;
    private timelineMobile = null;
    private scrollTimeout = null;
    private scrollRunAtLeastOnce = false;
    componentDidMount() {
        console.log('BlockSalary app did mount', this.props, this.barSalaryPerc);
        this.timeline = gsap.timeline({ paused: true });
        this.timeline && this.timeline.pause();
        this.timelineMobile = gsap.timeline({ paused: true });
        this.timelineMobile && this.timelineMobile.pause();
        // if (window.innerWidth <= 896) {
        //     document.querySelector('.scrollableContainer').addEventListener('scroll', this.handleScroll);
        // }
    }
    componentWillUnmount() {
        // if (window.innerWidth <= 896) {
        //     document.querySelector('.scrollableContainer').removeEventListener('scroll', this.handleScroll);
        // }
    }

    // handleScroll = (e) => {
    //     clearTimeout(this.scrollTimeout);
    //     if (!this.scrollRunAtLeastOnce) {
    //         this.timelineMobile.to(document.querySelector('.blockSalary .stickyLabelContainer'), { x: -110, ease: "Linear.easeNone", duration: 0.2 });
    //     }
    //     this.scrollTimeout = setTimeout(() => {
    //         this.scrollRunAtLeastOnce = true;
    //         const currentScroll = e.target.scrollLeft;
    //         const availableWidth = document.querySelector('.blockSalary') ? document.querySelector('.blockSalary').clientWidth : 0;
    //         // console.log('scrolled horizontally', currentScroll,
    //         // 'availableWidth', availableWidth,
    //         // 'labelwidth', labelWidth,
    //         // );
    //         if (currentScroll + 15 > availableWidth - 75) {
    //             this.timelineMobile.play();
    //         } else {
    //             this.timelineMobile.reverse();
    //         }
    //     }, 10);
    // }

    runTimeline = (oldWidth, newWidth) => {
        console.log('blockSalary runtimelineinitial', this.props.animateCounter);
        this.timeline = gsap.timeline({ paused: true });
        this.timeline && this.timeline.pause();
        let startSec = animationConfig().startSec + 0.4;
        startSec = startSec * animationConfig().scale;

        this.timeline
            .fromTo(this.barMain, { opacity: this.props.animateCounter > 1 ? 1 : 0 }, { opacity: 1 }, startSec)
            // Erwerb label
            .fromTo(this.barSalaryLabel,
                { y: -40, autoAlpha: 0, ease: animationConfig().easing, stagger: 0.2 },
                { autoAlpha: 1, y: 0 },
                startSec + 0.6);

        this.props.animateCounter === 1 && this.timeline.from(this.barSalaryPerc, { maxHeight: 0, ease: animationConfig().easing, duration: 1 }, startSec + 0.3);
        // label inside gray pillar
        this.timeline.fromTo([this.percTitle, this.percValue], { autoAlpha: 0 }, { autoAlpha: 1 }, startSec + 1)
            .fromTo([this.percTitle, this.percValue], { y: 0 }, { y: 0 }, startSec + 0.9);

        this.timeline
            .timeScale(animationConfig().scale || 0.75)
            .play();

        this.timeline.eventCallback("onStart", () => {
            console.log('onStart blocksalary animateCounter, last partial pension length = 0 and animated', this.props.animateCounter, this.props.partialPensionsNo);
            const emptyCalculateResponse = Boolean(typeof this.props.partialPensionsNo === 'undefined' && this.props.animateCounter === 1);
            setTimeout(() => {
                if (this.props.partialPensionsNo === 0 || emptyCalculateResponse) {
                    console.log('will dispatch lastPartialPensionAnimated blocksalary');
                    (window as any).dispatchEvent((window as any).lastPartialPensionAnimated);
                }
            }, 350)
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("BlockSalary componenentDidUpdate", prevProps, prevState, snapshot);

        Object.entries(this.props).forEach(([key, val]) => {
            if (prevProps[key] !== val) {
                console.log(`Prop BlockSalary '${key}' changed`);
            }
        }
        );
        // this.animateIn();
        // Object.entries(this.props).forEach(([key, val]) =>
        //     prevProps[key] !== val && console.log(`Prop BlockSalary'${key}' changed`)
        // );
        // if (this.state) {
        //     Object.entries(this.state).forEach(([key, val]) =>
        //         prevState[key] !== val && console.log(`State BlockSalary'${key}' changed`)
        //     );
        // }

        // This triggers earlyPension initial showing and dotted line update when no earlypension is present
        if (this.props.animateCounter > 1 && this.props.partialPensionsNo === 0) {
            console.log('will dispatch blockCapitalAnimatedWhenNoPartialPensions in blocksalary in didupdate', this.props.animateCounter,
                'isEarlyPension: ', this.props.isEarlyPensionPresent);
            let list = ['.dottedLineHeight', '.dottedLineWidth', '#barPensionGovWrapper .iconClickable'];
            list.forEach(arr => {
                let title = document.querySelector(arr);
                if (title) {
                    (title as any).style.opacity = '0';
                }
            })
            setTimeout(() => {
                (window as any).dispatchEvent((window as any).lastPartialPensionAnimated);
                // (window as any).dispatchEvent((window as any).blockCapitalAnimatedWhenNoPartialPensions);
            }, 300)
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const widthChanged = nextProps.width !== this.props.width;
        const animateCounterIncreased = nextProps.animateCounter > this.props.animateCounter;
        const salaryChanged = nextProps.salary !== this.props.salary; // use it to reload chart
        // const maxValChanged = nextProps.maxVal > this.props.maxVal;
        // const mobileLabelPosChanged = nextState.mobileLabelPos !== this.state.mobileLabelPos;
        // const salaryChanged = nextProps.salary > this.props.salary;
        // const animateCounterChanged = nextProps.animateCounter > this.props.animateCounter;
        // console.log('shouldComponentUpdate Blocksalary widthChanged', widthChanged);
        // console.log('shouldComponentUpdate Blocksalary widthChanged', widthChanged, 'animateCounterCHanged: ', this.props.animateCounter, nextProps.animateCounter, animateCounterChanged);
        
        if (animateCounterIncreased || widthChanged || salaryChanged
            // || maxValChanged
            // 
        ) {
            if(animateCounterIncreased || widthChanged){
                console.log('shouldComponentUpdate TRUE BlockSalary', this.props, nextProps);
                setTimeout(() => {
                    this.props.animateCounter === 1 && this.runTimeline(this.props.width, nextProps.width);
                }, 150)
            }
            return true;
            // }
            // else if(mobileLabelPosChanged) {
            // return true;
        } else {
            console.log('shouldComponentUpdate FALSE BlockSalary');
            return false
        }
    }

    // animateIn(oldWidth, newWidth) {
    //     console.log('animateIN blockSalary', this.barMain, oldWidth, 'to', newWidth);
    //     // if (this.props.animateCounter > 0) {
    //     this.runTimeline();
    //     // } else {
    //     // this.runTimelineInitial();
    //     // }
    //     console.log('timeline', this.timeline);
    //     this.timeline
    //         .restart()
    //         .timeScale(animationConfig().scale || 0.75)
    //         .play();

    //     // gsap.fromTo(this.barMain, { width: oldWidth }, { width: newWidth, ease: 'sine.out', duration: 0.9 });
    // }

    render() {
        const availableHeight = document.querySelector('.chartRightTop') ? document.querySelector('.chartRightTop').clientHeight - 75 : 0;
        const realValueToPxRatio = availableHeight / (this.props.maxVal || 1);
        console.log('render block salary availableheig', availableHeight, realValueToPxRatio,
            'iphone debug blocksalary', this.props.salary, this.props.animateCounter, realValueToPxRatio
        );

        return (
            <div ref={c => this.barMain = c} className={classNames("blockSalary blockPadding blockFullHeight", {
                // 'manipulating': this.props.manipulating
            })}
                style={{
                    maxWidth: this.props.width,
                    opacity: this.props.animateCounter < 2 ? 0 : 1
                }}>
                <div id="barSalaryLabelWrapper" className="blockLabelWrapper" style={{
                    height: this.props.maxVal - this.props.salary
                }}>
                    <div id="barSalaryLabel" className="blockLabel" ref={c => this.barSalaryLabel = c} style={{
                        opacity: 0
                    }}>
                        <FormattedMessageCustom id={translatableStrings.employment.id} text={this.props.intl.formatMessage(translatableStrings.employment)}>
                            <p className="blockTitle">
                            </p>
                        </FormattedMessageCustom>
                        <p className="blockValue">CHF {formatNumber(this.props.salary)}</p>
                    </div >
                </div >
                {this.props.wrapInTooltip(
                    <div id="barSalaryPercWrapper" className={classNames('blockWrapper', {
                    })}
                        style={{
                            height: this.props.salary,
                            minHeight: 2,
                            maxHeight: this.props.salary * realValueToPxRatio,
                            opacity: this.props.animateCounter === 0 ? 0 : 1
                        }}>
                        <div id="barSalaryPerc" className="blockStyleRounded blockElasticHeight" ref={c => this.barSalaryPerc = c}
                            style={{
                                height: '100%',
                                maxHeight: ChartSettings.maxBlockHeight * 6,
                            }}
                        >
                            <span className="stickyLabelContainer"
                                style={{
                                    left: window.innerWidth <= 896 ? 32 : 0
                                }}
                            >
                                <FormattedMessageCustom id={translatableStrings.salary.id} text={this.props.intl.formatMessage(translatableStrings.salary)}>
                                    <p className={classNames("blockTitle", {
                                        "inTooltip": this.props.salary * realValueToPxRatio < 50
                                    })} ref={c => this.percTitle = c}>
                                    </p>
                                </FormattedMessageCustom>
                                <p className={classNames("blockValue", {
                                    "inTooltip": this.props.salary * realValueToPxRatio < 50
                                })} ref={c => this.percValue = c}>CHF {formatNumber(this.props.salary)}</p>
                            </span>
                        </div>
                    </div>,
                    [
                        <FormattedMessageCustom id={translatableStrings.salary.id} text={this.props.intl.formatMessage(translatableStrings.salary)} key="percTitle1">
                            <p className="blockTitle" ref={c => this.percTitle = c}></p>
                        </FormattedMessageCustom>,
                        <p key="percTitle2" className="blockValue" ref={c => this.percValue = c}>CHF {formatNumber(this.props.salary)}</p>
                    ],
                    this.props.salary * realValueToPxRatio < 50
                )}
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockSalary);
