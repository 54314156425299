import React, { createRef } from "react";
import { Chart } from "../Chart";
import { Button, EButtonType } from "../../../components/Button";
import "./ChartContainer.scss";
import ChartControls from "./ChartControls";
import TeilPensionOverview from "./TeilPensionOverview";
import { postExample } from "../../../constants/payloads";
import { getParameterByName } from "../../../utils/toSearchParams";
import {
    parseDate,
    daysInMonth,
    getMonth,
    getYear,
} from "../../../utils/react";
import sorting from "../../../utils/sorting";
import Tab from "@material/react-tab";
import { BlockCapital } from "../Chart/BlockCapital";
import Tooltip from "rc-tooltip";
import Modal from "react-modal";
import { removeInArrayByIdx } from "../../../utils/array";
import { ReactComponent as IconClose } from "../../../assets/icons/Close_Icon.svg";
import { ChartSettings } from "../Chart/chartSettings";
import classNames from "classnames";
import { defined } from "../../../utils/variableEvaluation";
// import { ReactComponent as IconPlus } from '../../../assets/icons/plus.svg';
import gsap from "gsap";
import PKlogo from "../../../assets/imgs/pkar_logo.png";
import logo from "../../../assets/icons/logo.png";
import { months } from "../../../constants";
import { animationConfig } from "../../../constants";
// import { months } from '../../../constants';
import { defineMessages } from "react-intl";
import FormattedMessageCustom from "../../../components/FormattedMessageCustom";

const translatableStrings = defineMessages({
    lastMonth: {
        id: "pageChart.lastMonth",
        defaultMessage: "Pensionsstart",
    },
    capitalOverview: {
        id: "pageChart.capitalOverview",
        defaultMessage: "Übersicht Ihres Kapitals",
    },
    purpose: {
        id: "pageChart.purpose",
        defaultMessage: "Verwendung",
    },
    list: {
        id: "pageChart.list",
        defaultMessage: "Liste",
    },
    close: {
        id: "pageChart.close",
        defaultMessage: "SCHLIESSEN",
    },
});

(window as any).yearWidthPxReal = 0;
interface State {
    // activeSegment: number;
    ages: Array<number>;
    animateCounter: number;
    blockDataObjs: Array<any>;
    containerWidthPx: number;
    containerWidthPxMinusMargins: number;
    capitalOverviewListe: number;
    currentAges: Array<number>;
    currentMonths: Array<number>;
    currentIndex: number;
    currentYears: Array<number>;
    handleWrapperWidth: number;
    handlesXPosLinear: Array<number>;
    handlesXPosReal: Array<number>;
    handlesLimits: Array<any>;
    months: Array<number>;
    mobile: boolean;
    modalIsOpen: boolean;
    modalControlsIsOpen: boolean;
    modalContent: string;
    mobileDragging: boolean;
    yearRatio: number;
    yearWidthPx: number;
    yearWidthPxReal: number;
    years: Array<number>;
}

interface Props {
    arbeitPensums: Array<any>;
    calculatePost: any;
    calculateMaxPurchase: any;
    clearControls: any;
    calculationSteps: any;
    calculations: any;
    calculationsObj: any;
    calculateCounterTrigger: number;
    calculateCounter: number;
    clearData: any;
    history: any;
    lastCalculatePayload: any;
    intl: any;
    forceRecalculate: any;
    loading: boolean;
    lang: string;
    location: any;
    mobileModal: boolean;
    partialPensionLimits: any;
    isBridingPossible: boolean;
    defaultRetirmentAgeFemale: number;
    defaultRetirmentAgeMale: number;
    period?: string;
    removeTeilPension: any;
    recalculationPending: any;
    resized: any;
    retirementPercentages: Array<any>;
    retirementSteps: Array<any>;
    removeCapitalPurchase: any;
    setUserDataZeitpunkt: any;
    setCapitalPurchase: any;
    setCapitalWithdrawal: any;
    setBridgingPension: any;
    setUserDataLohnklasse: any;
    userDataWI: any;
    userDataWIZeitpunktUpdate: any;
    insurantPortalData: any;
    insurantPortalUrl: any;
    simulatePost: any;
    setDivorcePostings: any;
    monthlyOrYearly: string;
    currentSituation: any;
    chartControlsRef: any;
    setSincronizeMaxPurchases: any;
    hasPayrollClasses: boolean;
}

Modal.setAppElement("#root");

export class ChartContainer extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        console.log("chart constructor");
        this.state = {
            // activeSegment: 0,
            ages: [...this.initialAges],
            currentAges: [...this.initialAges],
            blockDataObjs: [{}, {}],
            animateCounter: 0,
            capitalOverviewListe: 2,
            containerWidthPx: 0,
            containerWidthPxMinusMargins: 0,
            currentIndex: 0,
            handleWrapperWidth: 69,
            handlesXPosLinear: [],
            handlesXPosReal: [],
            handlesLimits: [[], [], [], [], []],
            modalIsOpen: false,
            modalControlsIsOpen: false,
            modalContent: "",
            months: [...this.retirementMonths],
            currentMonths: [...this.retirementMonths],
            years: [...this.retirementYears],
            currentYears: [...this.retirementYears],
            yearRatio: 1,
            yearWidthPx: 0,
            yearWidthPxReal: 0,
            mobileDragging: false,
            mobile: window.innerWidth > 896 ? false : true,
        };
        this.timeline = gsap.timeline({ paused: true });
        this.timeline && this.timeline.pause();
    }
    private timeline = null;
    private leftInterval = null;
    private rightInterval = null;
    private chartRightRef = createRef<Chart>();
    private calculateId: any;
    private birthDayMonth: number = 0;
    private earliestYear: number = getYear(
        this.props.userDataWI.earliestRetirement
    );
    private retirementYears: Array<number> =
        this.props.userDataWI.RetirementSteps.map((stepDate) => getYear(stepDate));
    private retirementMonths: Array<number> =
        this.props.userDataWI.RetirementSteps.map((stepDate) =>
            getMonth(stepDate, 1)
        );
    private currentYearEl: any = null;
    private currentYearElOffset: number = null;
    private indexToRemove: number = null;
    private retirementStepIndex: number = 1;
    private lasthandlesXPosReal: any = null;
    // private borderWidthsCorrection: number = 2;

    getAge = (date1, date2?) => {
        let refDate = date2 || this.props.userDataWI.Geburtsdatum;
        let offset = getMonth(this.props.userDataWI.Geburtsdatum);
        let age = new Date(date1).getFullYear() - new Date(refDate).getFullYear();
        // console.log('getAge',
        //     date1,
        //     new Date(date1),
        //     new Date(date1).getFullYear(),
        //     'refdate', refDate,
        //     new Date(refDate).getFullYear(),
        //     'age: ', age,
        //     "month", new Date(date1).getMonth(),
        //     "offset", offset
        // );
        if (new Date(date1).getMonth() < offset) {
            console.log("will decrease age by 1");
            return age - 1;
        } else {
            return age;
        }
    };
    private initialAges: Array<number> =
        this.props.userDataWI.RetirementSteps.map((stepDate) =>
            this.getAge(stepDate)
        );

    componentDidMount() {
        // Events
        // (window as any).blockCapitalAnimatedWhenNoPartialPensions = new Event('blockCapitalAnimatedWhenNoPartialPensions');
        (window as any).lastPartialPensionAnimated = new Event(
            "lastPartialPensionAnimated"
        );
        (window as any).animationFinishedEvent = new Event("animationFinished");

        const initialContainerWidth = (this.chartRightRef.current as any)
            .clientWidth;
        const yearWidth =
            (initialContainerWidth -
                ChartSettings.chartLeftPadding -
                ChartSettings.chartRightPadding) /
            12;
        const yearWidthPxReal =
            (initialContainerWidth -
                ChartSettings.chartLeftPadding -
                ChartSettings.chartRightPadding) /
            15;

        const modalContent = getParameterByName(
            "content",
            this.props.location.search
        );
        let modalIsOpen = false;
        let modalControlsIsOpen = false;
        if (modalContent === "capital") {
            modalIsOpen = true;
        } else if (
            modalContent === "capitalbuyin" ||
            modalContent === "capitalbuyinedit" ||
            modalContent === "capitalwithdrawal" ||
            modalContent === "showallwithdrawals"
        ) {
            modalControlsIsOpen = true;
        }
        console.log(
            "ChartContainer :: did mount",
            initialContainerWidth,
            yearWidth,
            this.props.location.search.length,
            yearWidthPxReal,
            modalContent,
            modalControlsIsOpen
        );

        this.setState(
            {
                containerWidthPx: initialContainerWidth,
                containerWidthPxMinusMargins:
                    initialContainerWidth -
                    ChartSettings.chartLeftPadding -
                    ChartSettings.chartRightPadding,
                yearWidthPxReal: yearWidthPxReal,
                yearWidthPx: yearWidth,
                yearRatio: yearWidth / yearWidthPxReal,
                modalIsOpen: modalIsOpen,
                modalControlsIsOpen: modalControlsIsOpen,
                modalContent: modalContent,
            },
            () => {
                // let forcePillarWidthsRecalculation = false;
                // if (this.props.userDataWI.capitalWithdrawals[0] || this.props.userDataWI.bridgingPension || this.props.userDataWI.capitalPurchases[0]) {
                //     forcePillarWidthsRecalculation = true;
                // }
                console.log(
                    "updateTriggeredFromProps true did mount after setstate forcePillarWidthsRecalculation true"
                    // forcePillarWidthsRecalculation
                );
                !this.props.loading && this.updateTriggeredFromProps(true);
            }
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("ChartContainer :: componenentDidUpdate");
        // Object.entries(this.props).forEach(([key, val]) =>
        //     prevProps[key] !== val && console.log(`Prop Chartcontainer'${key}' changed`)
        // );
        // if (this.state) {
        //     Object.entries(this.state).forEach(([key, val]) =>
        //         prevState[key] !== val && console.log(`State Chartcontainer'${key}' changed`)
        //     );
        // }
        if (
            prevProps.period !== this.props.period
            // || (this.props.calculateCounterTrigger > 0 && prevProps.calculateCounterTrigger !== this.props.calculateCounterTrigger)
        ) {
            // if (prevProps.period !== this.props.period || (Object.keys(prevProps.calculations.capital).length !== Object.keys(this.props.calculations.capital).length ) ) {
            console.log("animateCounter increase didupdate");
            this.setState({
                animateCounter: this.state.animateCounter + 1,
            });
        }

        if (
            this.props.calculateCounterTrigger > 0 &&
            prevProps.calculateCounterTrigger < this.props.calculateCounterTrigger &&
            !this.props.loading
        ) {
            console.log(
                "updateTriggeredFromProps calculateCounterTrigger change forcePillarWidthsRecalculation",
                false
            );
            // calculateCounterTrigger > 1 works for all except bridgingPension
            // zurucksetzen click
            const initialAges = this.props.userDataWI.RetirementSteps.map(
                (stepDate) => this.getAge(stepDate)
            );
            // if (JSON.stringify(this.initialAges) != JSON.stringify(initialAges)) {
            const retirementYears = this.props.userDataWI.RetirementSteps.map(
                (stepDate) => getYear(stepDate)
            );
            const retirementMonths = this.props.userDataWI.RetirementSteps.map(
                (stepDate) => getMonth(stepDate, 1)
            );
            this.setState(
                {
                    ages: [...initialAges],
                    currentAges: [...initialAges],
                    months: [...retirementMonths],
                    currentMonths: [...retirementMonths],
                    years: [...retirementYears],
                    currentYears: [...retirementYears],
                },
                () => {
                    this.updateTriggeredFromProps(true);
                }
            );
            // } else {
            // this.updateTriggeredFromProps(false);
            // }
        } else if (
            prevProps.retirementSteps.length !== this.props.retirementSteps.length
        ) {
            console.log("will add/remove new handle");

            let newAges,
                newMonths,
                newYears,
                newHandlesXPosLinear,
                newHandlesXPosReal;
            newAges = removeInArrayByIdx([...this.state.ages], this.indexToRemove);
            newMonths = removeInArrayByIdx(
                [...this.state.months],
                this.indexToRemove
            );
            newYears = removeInArrayByIdx([...this.state.years], this.indexToRemove);
            newHandlesXPosLinear = removeInArrayByIdx(
                [...this.state.handlesXPosLinear],
                this.indexToRemove
            );
            newHandlesXPosReal = removeInArrayByIdx(
                [...this.state.handlesXPosReal],
                this.indexToRemove
            );
            // console.log('will set new state: ', newHandlesXPosLinear, newAges, newMonths, newYears, newAges, newHandlesXPosReal);
            this.setState({
                handlesXPosLinear: newHandlesXPosLinear,
                handlesXPosReal: newHandlesXPosReal,
                ages: newAges,
                currentAges: newAges,
                months: newMonths,
                currentMonths: newMonths,
                years: newYears,
                currentYears: newYears,
            });
            console.log(
                "componentDidUpdate updateTriggeredFromProps true retirementSteps.length"
            );
            this.updateTriggeredFromProps(true);
        }

        if (
            prevProps.calculations !== this.props.calculations ||
            prevProps.calculationSteps !== this.props.calculationSteps
        ) {
            this.props.calculations.forEach((step, idx) => {
                step.linkedRetirementStep && (this.retirementStepIndex = idx);
            });
            console.log("retirementStepIndex", this.retirementStepIndex);
        }
    }

    updateOnResize = () => {
        console.log("updateOnResize chartContainer", "initialContainerWidth: ");
        this.props.resized();
    };

    getMonthFromPx = (px) => {
        // console.log('getMonthFromPx', px, this.state.yearWidthPx, px / this.state.yearWidthPx);
        const yearFraction =
            px / this.state.yearWidthPx + (this.birthDayMonth + 1) / 12;

        const monthNumber = (yearFraction % 1) * 12;
        const monthNumberInteger = Math.floor(monthNumber);
        let monthIdx; // = monthNumberInteger + this.birthDayMonth;

        if (monthNumberInteger > 0) {
            monthIdx = monthNumberInteger - 1;
        } else {
            monthIdx = 11;
        }

        // console.log('getMonthFromPx',
        //     'yearFraction', yearFraction,
        //     'monthNumber', monthNumber,
        //     'monthNumberInteger', monthNumberInteger,
        //     'monthidx', monthIdx,
        //     months[monthIdx],
        // );
        return monthIdx;
    };

    getYearFromPx = (px) => {
        const yearFraction = px / this.state.yearWidthPx;
        const yearFractionOffset = this.birthDayMonth / 12;
        const fullYears = Math.floor(yearFraction + yearFractionOffset);

        console.log(
            "getYearFromPx yearFraction",
            yearFraction,
            "offset from birthMonth",
            yearFractionOffset,
            "fullyears",
            fullYears,
            new Date(this.props.userDataWI.earliestRetirement).getFullYear()
        );
        return (
            new Date(this.props.userDataWI.earliestRetirement).getFullYear() +
            fullYears
        );
    };

    getAgeFromPx = (px) => {
        const yearFraction = px / this.state.yearWidthPx;
        const fullYears = Math.floor(yearFraction);

        console.log(
            "getYearFromPx yearFraction",
            yearFraction,
            // 'offset from birthMonth', yearFractionOffset,
            "fullyears",
            fullYears,
            new Date(this.props.userDataWI.earliestRetirement).getFullYear()
        );
        return 58 + fullYears;
    };

    startDragging = (idx) => {
        let newMonths = [...this.state.currentMonths];
        let newYears = [...this.state.currentYears];
        let newCurrentAges = [...this.state.currentAges];
        // if (this.state.handlesXPosLinear[idx] !== this.state.handlesXPosReal[idx]) {
        const monthNumber = this.getMonthFromPx(this.state.handlesXPosReal[idx]);
        const xPos = this.state.handlesXPosReal[idx];
        // console.log('startDragging TRUE: idx', idx,
        //     'birthDayMonth', this.birthDayMonth,
        //     'newMonths: ', newMonths,
        //     'newYears: ', newYears,
        //     'monthNumber', monthNumber
        // );
        newMonths[idx] = monthNumber;
        newYears[idx] = this.getYearFromPx(xPos);
        newCurrentAges[idx] = this.getAgeFromPx(xPos);
        let newHandlesXPos = [...this.state.handlesXPosLinear];
        let newHandlesXPosExceptCurrent = [...this.state.handlesXPosLinear];
        newHandlesXPosExceptCurrent[idx] = [...this.state.handlesXPosReal][idx];
        console.log(
            "startDragging 2 TRUE if months",
            newMonths,
            "newYears",
            newYears
            // 'this.lasthandlesXPosReal',this.lasthandlesXPosReal
        );

        this.setState({
            currentMonths: newMonths,
            currentYears: newYears,
            currentAges: newCurrentAges,
            mobileDragging: window.innerWidth <= 896,
            mobile: window.innerWidth <= 896,
            currentIndex: idx,
            // months: newMonths,
            // years: newYears,
            // ages: newCurrentAges
            handlesXPosLinear: newHandlesXPos,
            handlesXPosReal: newHandlesXPosExceptCurrent,
        });
    };

    handleDrag = (xPos, idx, isStop, deltaX?) => {
        let newMonths = [...this.state.months];
        let newYears = [...this.state.years];
        let newCurrentAges = [...this.state.ages];
        let preventUpdate = false;
        // let nothingChanged = false;
        const currentAge = this.getAgeFromPx(xPos);
        const windowWidth = window.innerWidth;

        let previousAge, nextAge;
        if (windowWidth < 897) {
            previousAge = currentAge - 1;
            nextAge = currentAge + 1;

            clearInterval(this.leftInterval);
            clearInterval(this.rightInterval);

            if (
                document
                    .querySelector(`.handleWrapper.react-draggable-dragging`)
                    .getBoundingClientRect().right >
                windowWidth - this.state.yearWidthPxReal
            ) {
                this.rightInterval = setInterval(() => {
                    console.log("SETINTERVAL RIGHT");
                    if (document.querySelector(".scrollableContainer")) {
                        document.querySelector(".scrollableContainer").scrollLeft <
                            windowWidth + this.state.yearWidthPxReal &&
                            document.querySelector(".scrollableContainer").scrollBy({
                                left: this.state.yearWidthPxReal * 0.2,
                                // left: this.state.yearWidthPxReal * 1,
                                // behavior: 'smooth'
                            });
                    } else {
                        clearInterval(this.rightInterval);
                    }
                }, 30);
                // }, 100)
            }

            if (
                document
                    .querySelector(`.handleWrapper.react-draggable-dragging`)
                    .getBoundingClientRect().left < this.state.yearWidthPxReal
            ) {
                this.leftInterval = setInterval(() => {
                    console.log("SETINTERVAL LEFT");
                    if (document.querySelector(".scrollableContainer")) {
                        document.querySelector(".scrollableContainer").scrollLeft > 0 &&
                            document.querySelector(".scrollableContainer").scrollBy({
                                left: -this.state.yearWidthPxReal * 0.2,
                                // left: -this.state.yearWidthPxReal * 1,
                                // behavior: 'smooth'
                            });
                    } else {
                        clearInterval(this.leftInterval);
                    }
                    // }, 100)
                }, 30);
            }
        }

        newMonths[idx] = this.getMonthFromPx(xPos);
        newYears[idx] = this.getYearFromPx(xPos);
        newCurrentAges[idx] = currentAge;

        if (
            this.state.months[idx] === newMonths[idx] &&
            this.state.years[idx] === newYears[idx] &&
            isStop
        ) {
            document.body.classList.remove("dragStarted");
            // nothingChanged = true;
        }

        if (this.state.currentMonths[idx] !== newMonths[idx]) {
            console.log(
                "will vibrate",
                this.state.currentMonths[idx],
                newMonths[idx]
            );
            window.navigator.vibrate && window.navigator.vibrate(40);
        }

        console.log(
            "handleDrag",
            "idx",
            idx,
            "isStop",
            isStop,
            "currentAge",
            currentAge,
            "previousAge",
            document.querySelector(`.gridYear[data-age="${previousAge}"]`),
            "nextAge",
            document.querySelector(`.gridYear[data-age="${nextAge}"]`),
            "preventUpdate",
            preventUpdate,
            "deltaX",
            deltaX,
            "this.state.yearWidthPx / 11",
            this.state.yearWidthPx / 11,
            "newMonths[idx] === this.state.currentMonths[idx",
            newMonths[idx],
            this.state.currentMonths[idx],
            "newYears[idx] !== this.state.currentYears[idx]",
            newYears[idx],
            this.state.currentYears[idx]
        );

        let newHandlesXPos = [...this.state.handlesXPosLinear];

        newHandlesXPos[idx] = xPos;

        let leftLimit, rightLimit;
        const stepsNo = this.state.currentAges.length;
        console.log("calculationSteps", this.props.calculationSteps, idx);
        leftLimit = this.getXPosFromDate(
            this.props.calculationSteps[idx].limitSet.minimumLimit.retirementDate,
            this.birthDayMonth - 1
        );
        rightLimit = this.getXPosFromDate(
            this.props.calculationSteps[idx].limitSet.maximumLimit.retirementDate,
            this.birthDayMonth - 1
        );
        console.log("leftLimit", leftLimit);
        console.log("rightLimit", rightLimit);

        if (stepsNo > 1) {
            // last handle
            if (stepsNo === idx + 1) {
                console.log("lastHandle");
                rightLimit =
                    this.state.handlesXPosLinear[idx - 1] -
                    (this.state.yearWidthPxReal / 12) * 0.5;
            } else if (idx === 0) {
                // first handle
                console.log("firstHandle", leftLimit);
                leftLimit =
                    this.state.handlesXPosLinear[idx + 1] +
                    (this.state.yearWidthPxReal / 12) * 1;
                // + this.state.yearWidthPxReal / 12;
            } else {
                // in between handle
                // THIS NEEDS A CHECK FOR MORE THAN 2 handles
                rightLimit =
                    this.state.handlesXPosLinear[idx - 1] -
                    (this.state.yearWidthPxReal / 12) * 0.5;
                leftLimit =
                    this.state.handlesXPosLinear[idx + 1] +
                    (this.state.yearWidthPxReal / 12) * 1;
            }
        }

        let limits = [...this.state.handlesLimits];
        limits[idx] = [
            leftLimit + this.state.containerWidthPxMinusMargins / 156,
            rightLimit,
        ];
        // console.log('getLimits left', leftLimit, rightLimit, 'correctedHandlesPos', newHandlesXPos);
        if (!preventUpdate) {
            console.log(
                "updating state of chartcontainer"
                // this.lasthandlesXPosReal
            );
            this.setState({
                currentMonths: newMonths,
                currentYears: newYears,
                currentAges: newCurrentAges,
                handlesXPosLinear: newHandlesXPos,
                handlesXPosReal: newHandlesXPos,
                handlesLimits: limits,
                mobile: window.innerWidth <= 896,
                mobileDragging: !isStop,
                // draggingDisabled: draggingDisabled,
                // set it to prevent instant handle jump
            });
        } else {
            this.setState({
                mobile: window.innerWidth <= 896,
                mobileDragging: !isStop,
            });
        }
        // it helps to break interval loop in case of miclick
        if (isStop) {
            // this.lasthandlesXPosReal = [...newHandlesXPos];
            clearInterval(this.leftInterval);
            clearInterval(this.rightInterval);
        }
    };

    getRealHandlePositions = (blockDataObjs) => {
        const blocksWithHandles = [...blockDataObjs].filter((obj) => {
            return obj.hasHandle === true;
        });
        console.log("getRealHandlePositions blocksWithHandles", blocksWithHandles);

        const newXPositionsReal = blocksWithHandles
            .map((obj, idx) => {
                const accumulator = blocksWithHandles
                    .slice(0, idx)
                    .reduce((acc, currentVal) => {
                        console.log("reducer newXPositionsReal", acc, currentVal);
                        return acc + currentVal.width;
                    }, 0);

                // console.log('reverse map',
                //     obj.name, obj.width,
                //     'accumulator', accumulator,
                //     'idx', idx,
                //     'obj.width', obj.width,
                // )
                return obj.width + accumulator;
            })
            .reverse();
        console.log("getRealHandlePositions return", newXPositionsReal);

        return newXPositionsReal;
    };

    updateTriggeredFromProps = (forcePillarWidthsRecalculation?) => {
        console.log(
            "inside updateTriggeredFromProps forcePillarWidthsRecalculation",
            forcePillarWidthsRecalculation,
            this.props.calculateCounter,
            "loading",
            this.props.loading
        );
        this.currentYearEl = document.querySelector(".gridYear.current") as any;
        this.currentYearElOffset = this.currentYearEl
            ? this.currentYearEl.offsetLeft
            : 0;
        this.calculate().then((res) => {
            console.log(
                "calculate then triggered from props: ",
                this.props.calculationsObj,
                this.currentYearEl,
                this.currentYearElOffset
            );
            // added by controls, remove now
            document.body.classList.remove("dragStartedFake");

            let pensKey = "Pensionierung";
            if (this.props.calculationsObj[pensKey]) {
                pensKey = "Pensionierung";
            } else {
                pensKey = "AHV-Pensionierung";
            }
            this.birthDayMonth = getMonth(this.props.userDataWI.Geburtsdatum, 1);

            console.log(
                "chartcontainer: ",
                "currentYearElOffset",
                this.currentYearElOffset,
                "yearRatio",
                this.state.yearRatio,
                "birthDayMonth",
                this.birthDayMonth
            );

            let newXPositions = this.props.userDataWI.RetirementSteps.map(
                (stepDate) => this.getXPosFromDate(stepDate, this.birthDayMonth - 1)
            );

            // const initialContainerWidth = document.querySelector('.scrollableContainer .chartRight').clientWidth;
            let initialContainerWidth = document.querySelector(
                ".scrollableContainer .chartRight"
            )?.clientWidth;
            console.log("initialContainerWidth", initialContainerWidth);
            const blockDataObjs = this.getBlockObjs(
                initialContainerWidth -
                ChartSettings.chartRightPadding -
                ChartSettings.chartLeftPadding,
                newXPositions
            );
            const newXPositionsReal = this.getRealHandlePositions(blockDataObjs);

            console.log(
                "inside updateTriggeredFromProps then ",
                "newXPositionsReal",
                newXPositionsReal,
                "blockDataObjs",
                blockDataObjs,
                "animateCounter increase updateTriggeredFromProps",
                forcePillarWidthsRecalculation
            );

            this.setState(
                {
                    animateCounter: forcePillarWidthsRecalculation
                        ? this.state.animateCounter + 1
                        : this.state.animateCounter,
                    blockDataObjs: blockDataObjs,
                    handlesXPosLinear: [...newXPositions],
                    handlesXPosReal: [...newXPositionsReal],
                    // ages: newAges,
                    // months: newMonths,
                    // years: newYears,
                },
                () => {
                    //grid
                }
            );
        });
    };

    // also triggered by window resize in Grid component
    rerender = (xPos, idx, fromResize) => {
        let month = this.state.currentMonths[idx];
        let year = this.state.currentYears[idx];
        if (month === 0) {
            month = 12;
            year -= 1;
        } else {
            month -= 0;
        }
        const newDate = parseDate(
            `${month}.${daysInMonth(month, year)}.${year}`,
            "secsToDDMMYYYY"
        );
        console.log(
            "rerender chartContainer: ",
            " xPos",
            xPos,
            "idx",
            idx,
            "month",
            this.state.months,
            "yearOfIdx",
            this.state.currentYears[idx],
            "currentage",
            this.state.currentAges[0],
            "fromResize",
            fromResize,
            "newDate",
            newDate
        );

        let initialContainerWidth = document.querySelector(
            ".scrollableContainer .chartRight"
        ).clientWidth;

        const update = () => {
            console.log(
                "update function",
                "clientwidht",
                (document.querySelector(".scrollableContainer .chartRight") as any)
                    .clientWidth,
                "offsetwidht",
                (document.querySelector(".scrollableContainer .chartRight") as any)
                    .offsetWidth,
                "scrollWidth",
                (document.querySelector(".scrollableContainer .chartRight") as any)
                    .scrollWidth
            );
            // const initialContainerWidth = (this.chartRightRef.current as any).clientWidth;
            const yearWidth =
                (initialContainerWidth -
                    ChartSettings.chartLeftPadding -
                    ChartSettings.chartRightPadding) /
                12;
            const yearWidthPxReal =
                (initialContainerWidth -
                    ChartSettings.chartLeftPadding -
                    ChartSettings.chartRightPadding) /
                15;
            let newAges = [...this.state.currentAges];
            let newMonths = [...this.state.currentMonths];
            let newYears = [...this.state.currentYears];
            let newXPositions = this.props.userDataWI.RetirementSteps.map(
                (stepDate) =>
                    this.getXPosFromDate(stepDate, this.birthDayMonth - 1, yearWidth)
            );

            // console.log('newXPositions', newXPositions);
            const blockDataObjs = this.getBlockObjs(
                initialContainerWidth -
                ChartSettings.chartRightPadding -
                ChartSettings.chartLeftPadding,
                newXPositions
            );
            const newXPositionsReal = this.getRealHandlePositions(blockDataObjs);

            // console.log('blockData: ', blockDataObjs,
            //     'newXpositionsReal', newXPositionsReal,
            //     'sumCheck', this.state.containerWidthPxMinusMargins,
            //     'block', blockDataObjs.reduce((acc, val) => acc + val.width, 0),
            // );
            console.log("animateCounter update in rerender");
            this.setState(
                {
                    animateCounter: this.state.animateCounter + 1,
                    blockDataObjs: blockDataObjs,

                    handlesXPosLinear: newXPositions,
                    handlesXPosReal: newXPositionsReal,

                    ages: newAges,
                    months: newMonths,
                    years: newYears,

                    containerWidthPx: initialContainerWidth,
                    containerWidthPxMinusMargins:
                        initialContainerWidth -
                        ChartSettings.chartRightPadding -
                        ChartSettings.chartLeftPadding,
                    yearWidthPxReal: yearWidthPxReal,
                    yearWidthPx: yearWidth,
                    yearRatio: yearWidth / yearWidthPxReal,
                },
                () => {
                    document.body.classList.remove("dragStarted");
                }
            );
        };

        if (fromResize) {
            document.body.classList.remove("dragStarted");
            update();
            // twice to recalculate with new yearWidthPx values
            update();
        } else {
            this.props.userDataWIZeitpunktUpdate({ index: idx, date: newDate });
            setTimeout(() => {
                this.calculate().then((res) => {
                    console.log("calculate then inside rerender: ", res, "xPos", xPos);
                    update();
                });
            }, 300);
        }
    };

    getPosOfHandleCenter = (idx, handlesXPos?) => {
        return handlesXPos ? handlesXPos[idx] : this.state.handlesXPosLinear[idx]; // + ChartSettings.handleWidth;
    };

    ensureMinWidths = (blockWidthsObjs, containerWidthPxMinusMargins) => {
        let widthDeltas = [];
        // let widthDeltaRatios = [];
        let remainingDelta1, remainingDelta2;
        let reducedFromLeft = false;
        let reducedFromRight = false;

        console.log(
            "ensureMinWidths entryObj1 ",
            blockWidthsObjs,
            "containerwidth1",
            this.state.containerWidthPxMinusMargins,
            "sum1",
            blockWidthsObjs.reduce((acc, val) => {
                return acc + val.width;
            }, 0)
            //  'minBlockWidths', minBlockWidths
        );

        blockWidthsObjs.forEach((blockWidthObj, idx) => {
            widthDeltas[idx] = blockWidthObj.width - ChartSettings.minBlockWidth1;
            // widthDeltaRatios[idx] = widthDeltas[idx] / this.state.containerWidthPxMinusMargins
        });
        console.log(
            "blockWidthsObjs: ",
            JSON.parse(JSON.stringify(blockWidthsObjs)),
            "widthDeltas: ",
            JSON.parse(JSON.stringify(widthDeltas))
            // 'widthDeltaRatios: ', JSON.parse(JSON.stringify(widthDeltaRatios)),
        );

        const reduceFromLeft = () => {
            reducedFromLeft = true;
            // reduce from left to distribute borrowed widths
            remainingDelta2 = blockWidthsObjs.reduce(
                (accumulator, currentBlock, index, array) => {
                    console.log(
                        "reduce from left",
                        index,
                        array,
                        "acc: ",
                        accumulator,
                        // 'widthDeltaRatios', widthDeltaRatios,
                        "widthDeltas",
                        widthDeltas
                    );
                    // console.log('widthdeltaindex', index, JSON.parse(JSON.stringify(widthDeltas)));
                    // console.log('array index', index, JSON.parse(JSON.stringify(array)));
                    // console.log('accumulator index', index, JSON.parse(JSON.stringify(accumulator)));
                    if (widthDeltas[index] < 0) {
                        const delta = widthDeltas[index];
                        // should last block be zeroed also ? remove equal sign if not
                        if (index <= array.length - 1) {
                            array[index].width += widthDeltas[index] * -1;
                            widthDeltas[index] = 0;
                            // widthDeltaRatios[index] = 0;
                        }
                        console.log(
                            "accumulator when widthDelta lower than 0: ",
                            accumulator,
                            "delta",
                            delta,
                            JSON.parse(JSON.stringify(array[index]))
                        );
                        return accumulator + delta;
                    }

                    if (accumulator < 0) {
                        const delta = widthDeltas[index];
                        if (delta > Math.abs(accumulator)) {
                            array[index].width += accumulator;
                            widthDeltas[index] += accumulator;
                            // widthDeltaRatios[index] = widthDeltas[index] / this.state.containerWidthPxMinusMargins;
                            console.log(
                                "will set accumulator to 0 else, index",
                                index,
                                JSON.parse(JSON.stringify(widthDeltas)),
                                // 'delta ratios', JSON.parse(JSON.stringify(widthDeltaRatios)),
                                JSON.parse(JSON.stringify(array[index]))
                            );
                            return 0;
                        } else {
                            // array[index].width += accumulator * -1;
                            // widthDeltas[index] += accumulator * -1;
                            console.log(
                                "will set accumulator to widthDelta[index], index",
                                index,
                                "delta",
                                delta,
                                JSON.parse(JSON.stringify(widthDeltas)),
                                JSON.parse(JSON.stringify(array[index]))
                            );
                            return accumulator;
                        }
                    }
                    console.log("accumulator from left: ", accumulator);
                    return accumulator;
                },
                0
            );
        };

        const reduceFromRight = () => {
            widthDeltas.reverse();
            reducedFromRight = true;
            // widthDeltaRatios.reverse();
            // remainingDelta2 =
            remainingDelta1 = blockWidthsObjs
                .reverse()
                .reduce((accumulator, currentBlock, index, array) => {
                    console.log(
                        "reduce from right",
                        index,
                        array,
                        "acc: ",
                        accumulator,
                        // 'widthDeltaRatios', widthDeltaRatios,
                        "widthDeltas",
                        widthDeltas
                    );

                    if (widthDeltas[index] < 0) {
                        // const delta = widthDeltas[index];
                        // array[index].width += widthDeltas[index] * -1;
                        // widthDeltas[index] = 0;
                        // return accumulator + delta;
                        const delta = widthDeltas[index];
                        console.log("widthdelta below 0", delta);
                        // should last block be zeroed also ? remove equal sign if not
                        if (index <= array.length - 1) {
                            array[index].width += widthDeltas[index] * -1;
                            widthDeltas[index] = 0;
                            // widthDeltaRatios[index] = 0;
                        }
                        console.log(
                            "accumulator when widthDelta lower than 0: ",
                            accumulator,
                            "delta",
                            delta,
                            "width delta index",
                            widthDeltas[index],
                            // 'width deltaRations index', widthDeltaRatios[index],
                            "array index",
                            JSON.parse(JSON.stringify(array[index]))
                        );
                        return accumulator + delta;
                    }

                    // if (accumulator < 0) {
                    //     array[index].width += accumulator;
                    //     widthDeltas[index] += accumulator;
                    //     return 0
                    // }
                    if (accumulator < 0) {
                        const delta = widthDeltas[index];
                        if (delta > Math.abs(accumulator)) {
                            array[index].width += accumulator;
                            widthDeltas[index] += accumulator;
                            // widthDeltaRatios[index] = widthDeltas[index] / this.state.containerWidthPxMinusMargins;
                            console.log(
                                "will set accumulator to 0 else, index",
                                index,
                                JSON.parse(JSON.stringify(widthDeltas)),
                                // JSON.parse(JSON.stringify(widthDeltaRatios)),
                                JSON.parse(JSON.stringify(array[index]))
                            );
                            return 0;
                        } else {
                            // array[index].width += accumulator * -1;
                            // widthDeltas[index] += accumulator * -1;
                            console.log(
                                "will set accumulator to widthDelta[index], index",
                                index,
                                "delta",
                                delta,
                                JSON.parse(JSON.stringify(widthDeltas)),
                                JSON.parse(JSON.stringify(array[index]))
                            );
                            return accumulator;
                        }
                    }
                    console.log("accumulator from right: ", accumulator);
                    return accumulator;
                }, 0);
            blockWidthsObjs.reverse();
            widthDeltas.reverse();
            // widthDeltaRatios.reverse();
        };

        // if ((this.state.currentYears[0] === new Date(this.props.userDataWI.retirementDateAt65).getFullYear() &&
        // this.state.currentMonths[0] === new Date(this.props.userDataWI.retirementDateAt65).getMonth()
        // ) ||
        //     this.props.calculationsObj["Frühpensionierung"] && (this.props.userDataWI.retirementDateAt65 > this.props.userDataWI.RetirementSteps[0])
        //     ) {
        //     reduceFromRight();
        // } else {
        const nominalAge =
            this.props.userDataWI.Geschlecht === "weiblich" ? this.props.defaultRetirmentAgeFemale : this.props.defaultRetirmentAgeMale;
        for (let index = 0; index < widthDeltas.length; index++) {
            // console.log('inside for', index);
            if (widthDeltas[index] < 0) {
                if (index < Math.floor(widthDeltas.length / 2)) {
                    console.log(
                        "blockWidthsObjs",
                        blockWidthsObjs[index],
                        blockWidthsObjs[index - 1],
                        index,
                        this.props.retirementSteps.length
                    );
                    if (index > 0) {
                        if (
                            blockWidthsObjs[index] &&
                            defined(blockWidthsObjs[index - 1]) &&
                            ((blockWidthsObjs[index]["age"] ===
                                blockWidthsObjs[index - 1]["age"] &&
                                blockWidthsObjs[index]["age"] >= nominalAge - 1) ||
                                (blockWidthsObjs[index]["age"] ===
                                    blockWidthsObjs[index - 1]["age"] + 1 &&
                                    this.props.retirementSteps.length < 4 &&
                                    blockWidthsObjs[index]["age"] >= nominalAge - 1))
                        ) {
                            console.log("right1", blockWidthsObjs[index]["age"]);
                            reduceFromRight();
                        } else {
                            console.log("left1");
                            reduceFromLeft();
                        }
                    } else {
                        console.log("left2");
                        reduceFromLeft();
                    }
                    break;
                } else {
                    console.log("right2");
                    reduceFromRight();
                    break;
                }
            }
        }
        // }
        // reduceFromLeft();
        console.log(
            "after first reduce",
            JSON.parse(JSON.stringify(blockWidthsObjs)),
            JSON.parse(JSON.stringify(widthDeltas)),
            "remainindela1",
            remainingDelta1,
            "remainindela2",
            remainingDelta2,
            "reducedFromRight",
            reducedFromRight,
            "reducedFromLeft",
            reducedFromLeft
        );
        // reduce from right to distribut borrowed widths for pensionierung mostly
        if (remainingDelta1 < 0 || remainingDelta2 < 0) {
            reducedFromRight && reduceFromLeft();
            reducedFromLeft && reduceFromRight();
        }

        // const initialContainerWidth = (this.chartRightRef.current as any).clientWidth;
        // const containerWidthPxMinusMargins = initialContainerWidth - ChartSettings.chartRightPadding - ChartSettings.chartLeftPadding;
        // do sum check and substract what's left from before the last teilpension. Check if it works for earlyPension also.
        const diff =
            blockWidthsObjs.reduce((acc, val) => {
                return acc + val.width;
            }, 0) - containerWidthPxMinusMargins;
        console.log("diff", diff);
        if (diff > 0) {
            if (
                blockWidthsObjs[blockWidthsObjs.length - 2] &&
                blockWidthsObjs[blockWidthsObjs.length - 2]["name"] === "early"
            ) {
                blockWidthsObjs[blockWidthsObjs.length - 3]["width"] += diff * -1;
            } else {
                blockWidthsObjs[blockWidthsObjs.length - 2]["width"] += diff * -1;
            }
        }
        console.log(
            "after second reduce",
            JSON.parse(JSON.stringify(blockWidthsObjs)),
            JSON.parse(JSON.stringify(widthDeltas)),
            "remainindela1",
            remainingDelta1,
            "remainindela2",
            remainingDelta2
        );

        console.log(
            "ensureMinWidths entryObj ",
            blockWidthsObjs,
            "widthDelates",
            widthDeltas,
            "containerwidth",
            containerWidthPxMinusMargins,
            "sum",
            blockWidthsObjs.reduce((acc, val) => {
                return acc + val.width;
            }, 0)
            //  'minBlockWidths', minBlockWidths
        );
        return blockWidthsObjs;
    };

    getBlockObjs = (containerWidthPxMinusMargins, handlesXPos?) => {
        const handlesNumber = handlesXPos
            ? handlesXPos.length
            : this.state.handlesXPosLinear.length;
        let newBlockWidths = [];
        let earlyPension = {
            hasHandle: null,
            width: 0,
            name: "early",
            age: null,
        };
        let pensionierung = {
            hasHandle: null,
            width: 0,
            name: "pension",
            age: null,
        };
        let erwerb = {
            hasHandle: null,
            width: 0,
            name: "erwerb",
            age: null,
        };
        const teilPensions = [1, 2, 3, 4].map(() => {
            return {
                hasHandle: null,
                width: 0,
                name: "teil",
                age: null,
            };
        });

        console.log(
            "getBlockObjs handlesNumber",
            handlesNumber,
            "state.handlesXPosLinear, ",
            this.state.handlesXPosLinear,
            "passedHandles: ",
            handlesXPos,
            "state.containerwidthPx: ",
            containerWidthPxMinusMargins,
            "earlyPension",
            this.props.calculationsObj["Frühpensionierung"]
        );

        erwerb.width = this.getPosOfHandleCenter(handlesNumber - 1, handlesXPos);
        erwerb.hasHandle = true;
        erwerb.age = this.state.currentAges[this.state.currentAges.length - 1];
        if (handlesNumber === 1) {
            //  Early Retirement
            if (this.props.calculationsObj["Frühpensionierung"]) {
                // const initialContainerWidth = (this.chartRightRef.current as any).clientWidth
                // const yearWidthPxReal = (initialContainerWidth - ChartSettings.chartLeftPadding - ChartSettings.chartRightPadding) / 20;
                pensionierung.width =
                    this.state.yearWidthPxReal *
                    (this.props.userDataWI.Geschlecht === "weiblich" ? 6 : 5);
                earlyPension.width =
                    containerWidthPxMinusMargins - pensionierung.width - erwerb.width;

                console.log(
                    "will return block widths early pension: ",
                    pensionierung.width,
                    "yearwidth",
                    this.state.yearWidthPxReal,
                    this.state.yearWidthPxReal * 5,
                    "earlyPension.width",
                    earlyPension.width,
                    "erwerb",
                    erwerb.width,
                    "containerWidthPxMinusMargins",
                    containerWidthPxMinusMargins,
                    pensionierung.width + earlyPension.width + erwerb.width
                );
                newBlockWidths = [erwerb, earlyPension, pensionierung];
            } else {
                //  Regular OR late retirement
                pensionierung.width = containerWidthPxMinusMargins - erwerb.width;
                newBlockWidths = [erwerb, pensionierung];
            }
        } else if (handlesNumber === 2) {
            teilPensions[0].width =
                this.getPosOfHandleCenter(0, handlesXPos) -
                this.getPosOfHandleCenter(handlesNumber - 1, handlesXPos);
            teilPensions[0].hasHandle = true;
            if (this.props.calculationsObj["Frühpensionierung"]) {
                teilPensions[0].age =
                    this.state.currentAges[this.state.currentAges.length - 2];
                earlyPension.age =
                    this.props.userDataWI.Geschlecht === "weiblich" ? this.props.defaultRetirmentAgeFemale : this.props.defaultRetirmentAgeMale;
                pensionierung.age = null;
                // const initialContainerWidth = (this.chartRightRef.current as any).clientWidth
                // const yearWidthPxReal = (initialContainerWidth - ChartSettings.chartLeftPadding - ChartSettings.chartRightPadding) / 15;

                pensionierung.width =
                    this.state.yearWidthPxReal *
                    (this.props.userDataWI.Geschlecht === "weiblich" ? 6 : 5);
                earlyPension.width =
                    containerWidthPxMinusMargins -
                    pensionierung.width -
                    erwerb.width -
                    teilPensions[0].width;
                // console.log('will return block widths 2 handles and earlypension ',
                //     'between handles', this.getPosOfHandleCenter(0, handlesXPos) - this.getPosOfHandleCenter(handlesNumber - 1, handlesXPos),
                //     'erwerb width', erwerb.width,
                //     'pens width', pensionierung.width,
                //     'teilpens width', teilPensions[0].width,
                //     'containerwidthpxminus margins', containerWidthPxMinusMargins,
                //     'sum', pensionierung.width + teilPensions[0].width + erwerb.width
                // );
                newBlockWidths = [erwerb, teilPensions[0], earlyPension, pensionierung];
            } else {
                teilPensions[0].age =
                    this.state.currentAges[this.state.currentAges.length - 2];
                pensionierung.width =
                    containerWidthPxMinusMargins - teilPensions[0].width - erwerb.width;

                // console.log('will return block widths 2 handles: ',
                //     'between handles', this.getPosOfHandleCenter(0, handlesXPos) - this.getPosOfHandleCenter(handlesNumber - 1, handlesXPos),
                //     'erwerb width', erwerb.width,
                //     'pens width', pensionierung.width,
                //     'teilpens width', teilPensions[0].width,
                //     'containerwidthpxminus margins', containerWidthPxMinusMargins,
                //     'sum', pensionierung.width + teilPensions[0].width + erwerb.width
                // );

                newBlockWidths = [erwerb, teilPensions[0], pensionierung];
            }
        } else if (handlesNumber === 3) {
            teilPensions[0].width =
                this.getPosOfHandleCenter(1, handlesXPos) -
                this.getPosOfHandleCenter(2, handlesXPos);
            teilPensions[0].hasHandle = true;

            if (this.props.calculationsObj["Frühpensionierung"]) {
                teilPensions[0].age =
                    this.state.currentAges[this.state.currentAges.length - 2];
                teilPensions[1].age =
                    this.state.currentAges[this.state.currentAges.length - 3];
                earlyPension.age =
                    this.props.userDataWI.Geschlecht === "weiblich" ? this.props.defaultRetirmentAgeFemale : this.props.defaultRetirmentAgeMale;
                pensionierung.age = null;
                // const initialContainerWidth = (this.chartRightRef.current as any).clientWidth;
                let initialContainerWidth = document.querySelector(
                    ".scrollableContainer .chartRight"
                ).clientWidth;
                const yearWidthPxReal =
                    (initialContainerWidth -
                        ChartSettings.chartLeftPadding -
                        ChartSettings.chartRightPadding) /
                    20;

                teilPensions[1].width =
                    this.getPosOfHandleCenter(0, handlesXPos) -
                    this.getPosOfHandleCenter(1, handlesXPos);
                teilPensions[1].hasHandle = true;
                pensionierung.width =
                    yearWidthPxReal *
                    (this.props.userDataWI.Geschlecht === "weiblich" ? 6 : 5);
                earlyPension.width =
                    containerWidthPxMinusMargins -
                    pensionierung.width -
                    erwerb.width -
                    teilPensions[0].width -
                    teilPensions[1].width;
                // console.log('will return block widths 3 handles and earlypension ',
                //     'between handles', this.getPosOfHandleCenter(0, handlesXPos) - this.getPosOfHandleCenter(handlesNumber - 1, handlesXPos),
                //     'erwerb width', erwerb.width,
                //     'pens width', pensionierung.width,
                //     'teilpens width', teilPensions[0].width,
                //     'containerwidthpxminus margins', containerWidthPxMinusMargins,
                //     'sum', pensionierung.width + teilPensions[0].width + teilPensions[1].width + erwerb.width
                // );
                newBlockWidths = [
                    erwerb,
                    teilPensions[0],
                    teilPensions[1],
                    earlyPension,
                    pensionierung,
                ];
            } else {
                teilPensions[0].age =
                    this.state.currentAges[this.state.currentAges.length - 2];
                teilPensions[1].age =
                    this.state.currentAges[this.state.currentAges.length - 3];

                pensionierung.width =
                    containerWidthPxMinusMargins -
                    this.getPosOfHandleCenter(0, handlesXPos);
                teilPensions[1].hasHandle = true;
                teilPensions[1].width =
                    this.getPosOfHandleCenter(0, handlesXPos) -
                    this.getPosOfHandleCenter(1, handlesXPos);

                // console.log('will return block widths 3 handles: ', handlesXPos,
                //     'erwerb width', erwerb.width,
                //     'teilpens width1', teilPensions[0].width, containerWidthPxMinusMargins - pensionierung.width - teilPensions[0].width - erwerb.width,
                //     'teilpens width2', teilPensions[1].width,
                //     'pens width', pensionierung.width,
                //     'containerwidthpxminus margins', containerWidthPxMinusMargins,
                //     'sum', pensionierung.width + teilPensions[0].width + teilPensions[1].width + erwerb.width
                // );

                newBlockWidths = [
                    erwerb,
                    teilPensions[0],
                    teilPensions[1],
                    pensionierung,
                ];
            }
        } else if (handlesNumber === 4) {
            teilPensions[0].width =
                this.getPosOfHandleCenter(2, handlesXPos) -
                this.getPosOfHandleCenter(3, handlesXPos);
            teilPensions[0].hasHandle = true;

            if (this.props.calculationsObj["Frühpensionierung"]) {
                teilPensions[0].age =
                    this.state.currentAges[this.state.currentAges.length - 2];
                teilPensions[1].age =
                    this.state.currentAges[this.state.currentAges.length - 3];
                teilPensions[2].age =
                    this.state.currentAges[this.state.currentAges.length - 4];
                earlyPension.age =
                    this.props.userDataWI.Geschlecht === "weiblich" ? this.props.defaultRetirmentAgeFemale : this.props.defaultRetirmentAgeMale;
                pensionierung.age = null;

                // const initialContainerWidth = (this.chartRightRef.current as any).clientWidth;
                let initialContainerWidth = document.querySelector(
                    ".scrollableContainer .chartRight"
                ).clientWidth;
                const yearWidthPxReal =
                    (initialContainerWidth -
                        ChartSettings.chartLeftPadding -
                        ChartSettings.chartRightPadding) /
                    20;
                pensionierung.width =
                    yearWidthPxReal *
                    (this.props.userDataWI.Geschlecht === "weiblich" ? 6 : 5);
                teilPensions[1].width =
                    this.getPosOfHandleCenter(1, handlesXPos) -
                    this.getPosOfHandleCenter(2, handlesXPos);
                teilPensions[1].hasHandle = true;
                teilPensions[2].hasHandle = true;
                teilPensions[2].width =
                    this.getPosOfHandleCenter(0, handlesXPos) -
                    this.getPosOfHandleCenter(1, handlesXPos);
                earlyPension.width =
                    containerWidthPxMinusMargins -
                    pensionierung.width -
                    erwerb.width -
                    teilPensions[0].width -
                    teilPensions[1].width -
                    teilPensions[2].width;

                // console.log('will return block widths 4 handles and earlypension ',
                //     'between handles', this.getPosOfHandleCenter(0, handlesXPos) - this.getPosOfHandleCenter(handlesNumber - 1, handlesXPos),
                //     'erwerb width', erwerb.width,
                //     'pens width', pensionierung.width,
                //     'teilpens width', teilPensions[0].width,
                //     'containerwidthpxminus margins', containerWidthPxMinusMargins,
                //     'sum', pensionierung.width + teilPensions[0].width + teilPensions[1].width + erwerb.width
                // );
                newBlockWidths = [
                    erwerb,
                    teilPensions[0],
                    teilPensions[1],
                    teilPensions[2],
                    earlyPension,
                    pensionierung,
                ];
            } else {
                teilPensions[0].age =
                    this.state.currentAges[this.state.currentAges.length - 2];
                teilPensions[1].age =
                    this.state.currentAges[this.state.currentAges.length - 3];
                teilPensions[2].age =
                    this.state.currentAges[this.state.currentAges.length - 4];

                pensionierung.width =
                    containerWidthPxMinusMargins -
                    this.getPosOfHandleCenter(0, handlesXPos);
                teilPensions[1].hasHandle = true;
                teilPensions[1].width =
                    this.getPosOfHandleCenter(1, handlesXPos) -
                    this.getPosOfHandleCenter(2, handlesXPos);
                teilPensions[2].hasHandle = true;
                teilPensions[2].width =
                    this.getPosOfHandleCenter(0, handlesXPos) -
                    this.getPosOfHandleCenter(1, handlesXPos);

                console.log(
                    "will return block widths 4 handles: ",
                    handlesXPos,
                    "erwerb width",
                    erwerb.width,
                    "teilpens width1",
                    teilPensions[0].width,
                    containerWidthPxMinusMargins -
                    pensionierung.width -
                    teilPensions[0].width -
                    erwerb.width,
                    "teilpens width2",
                    teilPensions[1].width,
                    "pens width",
                    pensionierung.width
                );

                newBlockWidths = [
                    erwerb,
                    teilPensions[0],
                    teilPensions[1],
                    teilPensions[2],
                    pensionierung,
                ];
            }
        } else if (handlesNumber === 5) {
            teilPensions[0].width =
                this.getPosOfHandleCenter(3, handlesXPos) -
                this.getPosOfHandleCenter(4, handlesXPos);
            teilPensions[0].hasHandle = true;

            if (this.props.calculationsObj["Frühpensionierung"]) {
                // const initialContainerWidth = (this.chartRightRef.current as any).clientWidth
                let initialContainerWidth = document.querySelector(
                    ".scrollableContainer .chartRight"
                ).clientWidth;
                const yearWidthPxReal =
                    (initialContainerWidth -
                        ChartSettings.chartLeftPadding -
                        ChartSettings.chartRightPadding) /
                    22;
                teilPensions[0].age =
                    this.state.currentAges[this.state.currentAges.length - 2];
                teilPensions[1].age =
                    this.state.currentAges[this.state.currentAges.length - 3];
                teilPensions[2].age =
                    this.state.currentAges[this.state.currentAges.length - 4];
                teilPensions[3].age =
                    this.state.currentAges[this.state.currentAges.length - 5];
                earlyPension.age =
                    this.props.userDataWI.Geschlecht === "weiblich" ? this.props.defaultRetirmentAgeFemale : this.props.defaultRetirmentAgeMale;
                pensionierung.age = null;

                teilPensions[1].width =
                    this.getPosOfHandleCenter(2, handlesXPos) -
                    this.getPosOfHandleCenter(3, handlesXPos);
                teilPensions[1].hasHandle = true;
                pensionierung.width =
                    yearWidthPxReal *
                    (this.props.userDataWI.Geschlecht === "weiblich" ? 6 : 5);
                // pensionierung.width = yearWidthPxReal * 5;
                teilPensions[2].hasHandle = true;
                teilPensions[2].width =
                    this.getPosOfHandleCenter(1, handlesXPos) -
                    this.getPosOfHandleCenter(2, handlesXPos);
                teilPensions[3].hasHandle = true;
                teilPensions[3].width =
                    this.getPosOfHandleCenter(0, handlesXPos) -
                    this.getPosOfHandleCenter(1, handlesXPos);
                earlyPension.width =
                    containerWidthPxMinusMargins -
                    pensionierung.width -
                    erwerb.width -
                    teilPensions[0].width -
                    teilPensions[1].width -
                    teilPensions[2].width -
                    teilPensions[3].width;
                // console.log('will return block widths 5 handles and earlypension ',
                //     'between handles', this.getPosOfHandleCenter(0, handlesXPos) - this.getPosOfHandleCenter(handlesNumber - 1, handlesXPos),
                //     'erwerb width', erwerb.width,
                //     'pens width', pensionierung.width,
                //     'teilpens width', teilPensions[0].width,
                //     'containerwidthpxminus margins', containerWidthPxMinusMargins,
                //     'sum', pensionierung.width + teilPensions[0].width + teilPensions[1].width + erwerb.width
                // );
                newBlockWidths = [
                    erwerb,
                    teilPensions[0],
                    teilPensions[1],
                    teilPensions[2],
                    teilPensions[3],
                    earlyPension,
                    pensionierung,
                ];
            } else {
                pensionierung.width =
                    containerWidthPxMinusMargins -
                    this.getPosOfHandleCenter(0, handlesXPos);

                teilPensions[0].age =
                    this.state.currentAges[this.state.currentAges.length - 2];
                teilPensions[1].age =
                    this.state.currentAges[this.state.currentAges.length - 3];
                teilPensions[2].age =
                    this.state.currentAges[this.state.currentAges.length - 4];
                teilPensions[3].age =
                    this.props.userDataWI.Geschlecht === "weiblich" ? this.props.defaultRetirmentAgeFemale : this.props.defaultRetirmentAgeMale;
                pensionierung.age =
                    this.state.currentAges[this.state.currentAges.length - 5];

                teilPensions[1].hasHandle = true;
                teilPensions[1].width =
                    this.getPosOfHandleCenter(2, handlesXPos) -
                    this.getPosOfHandleCenter(3, handlesXPos);
                teilPensions[2].hasHandle = true;
                teilPensions[2].width =
                    this.getPosOfHandleCenter(1, handlesXPos) -
                    this.getPosOfHandleCenter(2, handlesXPos);
                teilPensions[3].hasHandle = true;
                teilPensions[3].width =
                    this.getPosOfHandleCenter(0, handlesXPos) -
                    this.getPosOfHandleCenter(1, handlesXPos);
                // console.log('will return block widths 5 handles: ', handlesXPos,
                //     'erwerb width', erwerb.width,
                //     'teilpens width1', teilPensions[0].width, containerWidthPxMinusMargins - pensionierung.width - teilPensions[0].width - erwerb.width,
                //     'teilpens width2', teilPensions[1].width,
                //     'pens width', pensionierung.width)

                newBlockWidths = [
                    erwerb,
                    teilPensions[0],
                    teilPensions[1],
                    teilPensions[2],
                    teilPensions[3],
                    pensionierung,
                ];
            }
        }

        // Substract padding from all blocks
        console.log(
            "returnable from getBlocksObjs: ",
            JSON.parse(JSON.stringify(newBlockWidths))
        );
        return this.ensureMinWidths(newBlockWidths, containerWidthPxMinusMargins);
    };

    getXPosFromDate = (dateStr, monthOffset, yearWidthPx?) => {
        const year = getYear(dateStr);
        const month = getMonth(dateStr);
        const yearFraction = year - this.earliestYear + (month - monthOffset) / 12;
        // console.log('getXPosFromDate: ',
        // 'year', year,
        // 'month', month,
        // 'dateStr', dateStr,
        // 'monthOffset', monthOffset,
        //     'yearFraction', yearFraction
        // );
        return yearFraction * (yearWidthPx || this.state.yearWidthPx);
    };

    goToRenten = () => {
        console.log("gotorenten");
        // addToLSObject("userDataWI", "sliderValues", this.state.sliderValues);

        if (document.querySelectorAll(".crossFadeOut").length) {
            this.timeline.fromTo(
                document.querySelectorAll(".crossFadeOut"),
                { autoAlpha: 1 },
                {
                    delay: 0,
                    stagger: {
                        ...animationConfig().stagger,
                    },
                    autoAlpha: 0,
                }
            );
        }

        this.timeline.play();

        setTimeout((e) => {
            this.props.history.push({
                pathname: `/zeitpunkt`,
                search: `no=${this.props.retirementSteps.length}`,
            });
        }, 600);
    };

    removeTeilPension = (date, idx) => {
        console.log("removeTeilPension chartcontainer", date, idx);
        this.indexToRemove = idx;
        this.props.removeTeilPension({ date, idx });
    };

    calculateDebounced = () => {
        clearTimeout(this.calculateId);
        this.calculateId = setTimeout(() => {
            console.log("calculate debounced");
            this.calculate();
        }, 600);
    };

    calculate = () => {
        console.log(
            "calculate, postExample: ",
            postExample,
            "retirementSteps: ",
            this.props.userDataWI
            // this.props.userDataWI.RetirementStepsUnsorted
        );
        const sortedDates = [...this.props.userDataWI.RetirementSteps].sort(
            (a, b) => {
                return sorting(a, b, 1, true);
            }
        );
        console.log("sortedDates: ", sortedDates);

        const capitalWidthdrawalAmountMax =
            this.props.calculationSteps[0]?.limitSet.maximumLimit
                .capitalWithdrawalAmount || 9999;

        let purchases = [];

        const filteredRetirementSteps = this.props.userDataWI?.bridgingPensionSteps ? this.props.userDataWI?.bridgingPensionSteps.filter(step => step) : null;

        if (!!this.props.userDataWI.maxPurchases) {
            Object.keys(this.props.userDataWI.maxPurchases).forEach((item, index) => {
                this.props.userDataWI.capitalPurchases[index] && purchases.push({
                    date: item,
                    value: this.props.userDataWI.capitalPurchases[index].amount
                })
            })
        }

        const retirementSteps = sortedDates.map((date, idxDate) => { //monta o payload dos  retirementSteps
            // let idxDescending = this.props.userDataWI.RetirementSteps.indexOf(date);
            let idx = this.props.userDataWI.RetirementSteps.indexOf(date);
            let idxWhenWntering =
                this.props.userDataWI.descendingStepsToEnteringIndex[idx];

            let bridgingPension = null;

            // bridging pension
            bridgingPension = filteredRetirementSteps?.find(retStep => new Date(retStep.linkedRetirementStepDate).getFullYear() === new Date(date).getFullYear()) || 0;

            // if changes date pin
            if (!bridgingPension && filteredRetirementSteps?.length >= sortedDates.length) {
                // const reversedFilteredRetirementSteps = idxDate === 0 ? filteredRetirementSteps.reverse() : filteredRetirementSteps;
                // const reversedFilteredRetirementSteps = filteredRetirementSteps.reverse();
                bridgingPension = filteredRetirementSteps[idxWhenWntering] || 0;
            }


            // const bridgingPension = filteredRetirementSteps[idxDate] || 0;

            console.log(
                "retirementsSteps: date: ",
                date,
                "idx",
                idxWhenWntering,
                "idxDescending",
                idx,
                "bridgingPensionAmount",
                bridgingPension?.bridgingPensionAmount || 0,
                "retirementperc",
                this.props.userDataWI.retirementPercentage[idxWhenWntering],
                "retirementpercuns",
                this.props.userDataWI.arbeitPensums[idxWhenWntering],
                "capitalWidthdrawalAmountMax",
                capitalWidthdrawalAmountMax,
                "this.props.userDataWI.capitalWithdrawals[idxWhenWntering]",
                this.props.userDataWI.capitalWithdrawals[idxWhenWntering]
            );
            // check if exists capitalWithdrawals
            // if (typeof this.props.userDataWI.capitalWithdrawals[idxDate]) {
            //     purchases.push({
            //         value:
            //             typeof this.props.userDataWI.capitalWithdrawals[idxDate] ===
            //                 "number"
            //                 ? this.props.userDataWI.capitalWithdrawals[idxDate]
            //                 : 0,
            //         date: date,
            //     });
            // }

            // return right objects
            return {
                retirementStepId: bridgingPension?.id,
                retirementDate: date,
                bridgingPensionAmount: bridgingPension?.bridgingPensionAmount || 0, // CHANGE HERE RAFAEL
                retirementPercentage:
                    this.props.userDataWI.retirementPercentage[idxWhenWntering],
                grossSalaryAfterRetirement:
                    this.props.userDataWI.bruttolohns[idxWhenWntering],
                activityRateAfterRetirement:
                    this.props.userDataWI.arbeitPensums[idxWhenWntering],
                capitalWidthdrawalAmount: this.props.userDataWI.capitalWithdrawals[
                    idxDate
                ]
                    ? this.props.userDataWI.capitalWithdrawals[idxDate]["amount"]
                    : 0,
            };
        });

        const payroll = {};
        this.props.userDataWI.Lohnsumme &&
            (payroll["insuredSalary"] = parseFloat(this.props.userDataWI.Lohnsumme));
        this.props.userDataWI.Lohnsumme &&
            (payroll["grossSalary"] = parseFloat(this.props.userDataWI.Lohnsumme));
        this.props.userDataWI.Beschaftigungsgrad &&
            (payroll["activityRate"] = parseFloat(
                this.props.userDataWI.Beschaftigungsgrad
            ));
        this.props.userDataWI.payrollClass &&
            (payroll["payrollClass"] = parseInt(this.props.userDataWI.payrollClass));

        // build payrolls on payroll object
        const payrolls = [
            {
                activityRate: (payroll as any)?.activityRate,
                grossSalary: (payroll as any)?.grossSalary,
                validFrom: "2020-01-01T12:00:00.000Z", // First day of the year always
            },
        ];

        const acturialValuesSet = {
            totals: [{}],
            details: [],
        };
        this.props.userDataWI["AusgestelltAm"] &&
            (acturialValuesSet["totals"][0]["referenceDate"] =
                this.props.userDataWI["AusgestelltAm"]);
        this.props.userDataWI["Sparguthaben"] &&
            (acturialValuesSet["totals"][0]["basicCapital"] = parseFloat(
                this.props.userDataWI["Sparguthaben"]
            ));
        this.props.userDataWI["VPKontoAmount"] &&
            (acturialValuesSet["totals"][0]["earlyRetirementCapital"] = parseFloat(
                this.props.userDataWI["VPKontoAmount"]
            ));

        Object.keys(this.props.userDataWI["capitalPurchases"]).length &&
            Object.keys(this.props.userDataWI["capitalPurchases"]).forEach((key) => {
                this.props.userDataWI["capitalPurchases"][key].date &&
                    acturialValuesSet["details"].push({
                        referenceDate: this.props.userDataWI["capitalPurchases"][key].date,
                        totalCapital: this.props.userDataWI["capitalPurchases"][key].amount,
                    });
            });
        // this.props.userDataWI["ahvPensionAmount"] && (acturialValuesSet["earlyRetirementCapital"] = this.props.userDataWI["ahvPensionAmount"]);

        // build transactions on acturialValues
        const transactions = [];
        acturialValuesSet?.totals?.map((total: any) => {
            transactions.push({
                creationDate: total.referenceDate,
                transactionDate: total.referenceDate,
                interestDate: total.referenceDate,
                amount: total.basicCapital,
                transactionType: 16,
            });
        });

        const newData = {
            calculationType:
                this.props.userDataWI.calculationType === "Retirement"
                    ? 0
                    : this.props.userDataWI.calculationType,
            tenantName: (this.props.userDataWI.tenantName || "bvg").toUpperCase(),
            pensionType:
                this.props.userDataWI.pensionType === "Common"
                    ? 1
                    : this.props.userDataWI.pensionType,
            keyDate: new Date().toISOString(),
            // bridgingPensionAmount: this.props.userDataWI.bridgingPension,
            // capitalPurchaseDateInvalid: boolean;
            // "capitalPurchases": [],
            // "capitalPurchaseAmount": this.state.sliderValues[0]['capitalPurchaseAmount'] || 0,
            ahvPensionAmount: this.props.userDataWI.ahvPensionAmount * 12 || 0,
            retirementSteps: retirementSteps,
            simulationData: {
                purchases: purchases,
                divorcePostings: this.props.userDataWI.divorcePostings,
                payrollClass: this.props.userDataWI.payrollClass
                    ? parseInt(this.props.userDataWI.payrollClass)
                    : 0,
            },
            person: {
                birthDate: this.props.userDataWI.Geburtsdatum,
                sex: this.props.userDataWI.Geschlecht === "weiblich" ? 2 : 1,
                insuranceRelationships: [
                    {
                        transactions: transactions,
                        employment: [
                            {
                                insurancePlans: [
                                    {
                                        planCode: "BVG",
                                    },
                                ],
                                payrolls: payrolls,
                            },
                        ],
                    },
                ],
            },
        };
        // for use by maxPurchase endpoint
        (window as any).retirementSteps = retirementSteps;

        // console.log(
        //     'acturialValuesSet: ', acturialValuesSet,
        //     "postExampletotals", postExample["data"]["insurant"]["acturialValuesSet"]["totals"][0]
        // );

        let mergedPayload = {
            // ...postExample,
            data: {
                // ...postExample["data"],
                ...newData,
            },
            jsonapi: {},
        };

        // if came from insurant portal
        if (this.props.insurantPortalData && this.props.insurantPortalUrl) {
            mergedPayload = {
                ...mergedPayload,
                data: {
                    ...mergedPayload.data,
                    person: {
                        ...this.props.insurantPortalData,
                    },
                },
            };
        }

        this.props.userDataWI.insurancePlan &&
            !(this.props.insurantPortalData && this.props.insurantPortalUrl) &&
            (mergedPayload.data.person.insuranceRelationships[0].employment[0].insurancePlans[0].planCode =
                this.props.userDataWI.insurancePlan); //simulateDataStandalone

        // Comment this out, once we have more insurancePlans.
        // mergedPayload["data"]["insurant"].hasOwnProperty("employee") && delete mergedPayload["data"]["insurant"]["employee"]

        console.log("mergedPayload: ", mergedPayload);
        document.body.classList.add("loading");

        console.log("mergedPayload: ", mergedPayload);

        // if(this.props.insurantPortalData && this.props.insurantPortalUrl) {
        // mergedPayload = {
        //     ...mergedPayload,
        //     data: {
        //         ...mergedPayload.data,
        //         ...simulateData
        //     }
        // }
        // delete mergedPayload["data"]["RetirementSteps"];
        // delete mergedPayload["data"]["insurant"];

        return (
            newData.retirementSteps &&
            this.props.simulatePost(mergedPayload).then((res) => {
                document.body.classList.remove("loading");
                // this.setState({
                //     animateCounter: this.state.animateCounter + 1
                // })
                return res;
            })
        );
        // } else {
        //     mergedPayload = {
        //         ...mergedPayload,
        //         data: {
        //             ...mergedPayload.data,
        //             ...calculateData
        //         }
        //     }
        //     return newData.retirementSteps && this.props.calculatePost(mergedPayload).then((res) => {
        //         document.body.classList.remove('loading');
        //         // this.setState({
        //         //     animateCounter: this.state.animateCounter + 1
        //         // })
        //         return res;
        //     });
        // }
    };

    closeModal = () => {
        this.setState(
            {
                modalIsOpen: false,
                modalControlsIsOpen: false,
            },
            () => {
                // this.props.history.push({
                //     search: ''
                // })
            }
        );
    };

    openModal = (content, controls?) => {
        this.setState({
            modalIsOpen: true,
            modalControlsIsOpen: controls ? true : false,
            modalContent: content,
        });
        this.props.history.push({
            search: "?" + new URLSearchParams({ content }).toString(),
        });
    };

    wrapInTooltip = (el, text, showBoolean, placement?) => {
        // console.log('wrapintooltipPension', el, text, this.state);
        if (showBoolean) {
            return (
                <Tooltip
                    mouseEnterDelay={0}
                    mouseLeaveDelay={0}
                    destroyTooltipOnHide={true}
                    placement={placement ? placement : "top"}
                    trigger={"hover"}
                    key={Math.random() + "tooltip"}
                    overlay={<div>{text}</div>}
                >
                    {el}
                </Tooltip>
            );
        } else {
            return el;
        }
    };

    renderModalContent() {
        const purchasedCapitals = Object.keys(
            this.props.userDataWI.capitalPurchases
        ).map((key) => {
            return (
                parseFloat(this.props.userDataWI.capitalPurchases[key]["amount"]) || 0
            );
        });
        const capitalWithdrawals = Object.keys(
            this.props.userDataWI.capitalWithdrawals
        ).map((key) => {
            return (
                parseFloat(this.props.userDataWI.capitalWithdrawals[key]["amount"]) || 0
            );
        });

        const totalCapitalForAnnnuity = this.props.calculationsObj
            .capitalInformation
            ? this.props.calculationsObj.capitalInformation.summary
                ?.totalCapitalForAnnnuity || 0
            : 0;
        const capitalWithdrawalsTotal = this.props.calculationsObj
            .capitalInformation
            ? this.props.calculationsObj.capitalInformation.summary
                ?.totalCapitalWithdrawal || 0
            : 0;

        // const monthlyOrYearly = (getParameterByName('period') === 'year') ? "annualIncome" : "monthlyIncome";

        const totalPossibleCapital = this.props.calculationsObj.capitalInformation
            ? this.props.calculationsObj.capitalInformation.summary
                ?.totalPossibleCapital || 0
            : 0;
        const totalCapital = this.props.calculationsObj.capitalInformation
            ? this.props.calculationsObj.capitalInformation.summary?.totalCapital || 0
            : 0;
        const totalCapitalForBridgingPension = this.props.calculationsObj
            .capitalInformation
            ? this.props.calculationsObj.capitalInformation.summary
                ?.totalCapitalForBridgingPension || 0
            : 0;
        // const baseCapitals = this.props.calculationSteps.map((data) => {
        //     return data && data.calculationBase.baseCapital
        // })
        const conversionRate = this.props.calculationsObj.capitalInformation
            ? this.props.calculationsObj.capitalInformation.summary?.conversionRate ||
            0
            : 0;
        const sparguthaben = this.props.calculationsObj.capitalInformation
            ? this.props.calculationsObj.capitalInformation.summary
                ?.totalCapitalWithoutPurchases || 0
            : 0;

        console.log("this.state.modalContent", this.state.modalContent);
        if (this.state.modalContent === "capital") {
            console.log("renderModalContent true");
            return (
                <>
                    <div
                        className="closeIcon close"
                        onClick={() => {
                            this.closeModal();
                        }}
                    >
                        <IconClose />
                    </div>
                    <FormattedMessageCustom
                        id={translatableStrings.capitalOverview.id}
                        text={this.props.intl.formatMessage(
                            translatableStrings.capitalOverview
                        )}
                    >
                        <div className="modal-header" style={{ marginTop: -32 }}></div>
                    </FormattedMessageCustom>
                    <div className="tabContainer capital-tabs">
                        <Tab
                            active={this.state.capitalOverviewListe === 0}
                            className=""
                            minWidth
                            onClick={() => {
                                this.setState({
                                    capitalOverviewListe: 0,
                                });
                            }}
                        >
                            <span className="mdc-tab__text-label d-md-none">Struktur</span>
                        </Tab>
                        <Tab
                            className="mx-4"
                            active={this.state.capitalOverviewListe === 1}
                            minWidth
                            onClick={() => {
                                this.setState({
                                    capitalOverviewListe: 1,
                                });
                            }}
                        >
                            <FormattedMessageCustom
                                id={translatableStrings.purpose.id}
                                text={this.props.intl.formatMessage(
                                    translatableStrings.purpose
                                )}
                            >
                                <span className="mdc-tab__text-label" />
                            </FormattedMessageCustom>
                        </Tab>
                        <Tab
                            active={this.state.capitalOverviewListe === 2}
                            minWidth
                            onClick={() => {
                                this.setState({
                                    capitalOverviewListe: 2,
                                });
                            }}
                        >
                            <FormattedMessageCustom
                                id={translatableStrings.list.id}
                                text={this.props.intl.formatMessage(translatableStrings.list)}
                            >
                                <span className="mdc-tab__text-label" />
                            </FormattedMessageCustom>
                        </Tab>
                    </div>

                    <div className={`modal-content capitalsOverview`}>
                        <div className="row">
                            {this.state.capitalOverviewListe === 2 && (
                                <TeilPensionOverview
                                    capitalInformation={
                                        this.props.calculationsObj.capitalInformation
                                    }
                                    retirementSteps={this.props.retirementSteps}
                                />
                            )}

                            {this.state.capitalOverviewListe === 0 && (
                                <div className="w-100 capitalContainer">
                                    <BlockCapital
                                        // capitalPurchaseAmountMax={this.props.capitalPurchaseAmountMax}
                                        // isEarlyPensionPresent={Boolean((this.props.calculationsObj["Frühpensionierung"] || {})[monthlyOrYearly])}
                                        animateCounter={0}
                                        totalCapitalForAnnnuity={totalCapitalForAnnnuity || 1}
                                        bridgingPensionAmount={totalCapitalForBridgingPension}
                                        calculateCounterTrigger={this.props.calculateCounterTrigger}
                                        capitalWithdrawals={capitalWithdrawals}
                                        capitalWithdrawalsTotal={capitalWithdrawalsTotal}
                                        conversionRate={conversionRate}
                                        intl={this.props.intl}
                                        openModal={this.openModal}
                                        overview
                                        partialPensionsNo={
                                            this.props.calculationsObj["teilPensions"] &&
                                            this.props.calculationsObj["teilPensions"].length
                                        }
                                        purchasedCapitals={purchasedCapitals}
                                        sparguthaben={sparguthaben}
                                        structure
                                        totalCapital={totalCapital || 1}
                                        totalPossibleCapital={totalPossibleCapital || 1}
                                        wrapInTooltip={this.wrapInTooltip}
                                    />
                                </div>
                            )}
                            {this.state.capitalOverviewListe === 1 && (
                                <div className="w-100 capitalContainer">
                                    <BlockCapital
                                        // capitalPurchaseAmountMax={this.props.capitalPurchaseAmountMax}
                                        // isEarlyPensionPresent={Boolean((this.props.calculationsObj["Frühpensionierung"] || {})[monthlyOrYearly])}
                                        animateCounter={0}
                                        totalCapitalForAnnnuity={totalCapitalForAnnnuity || 1}
                                        bridgingPensionAmount={totalCapitalForBridgingPension}
                                        calculateCounterTrigger={this.props.calculateCounterTrigger}
                                        capitalWithdrawals={capitalWithdrawals}
                                        capitalWithdrawalsTotal={capitalWithdrawalsTotal}
                                        conversionRate={conversionRate}
                                        intl={this.props.intl}
                                        openModal={this.openModal}
                                        overview
                                        partialPensionsNo={
                                            this.props.calculationsObj["teilPensions"] &&
                                            this.props.calculationsObj["teilPensions"].length
                                        }
                                        purchasedCapitals={purchasedCapitals}
                                        sparguthaben={sparguthaben}
                                        totalCapital={totalCapital || 1}
                                        totalPossibleCapital={totalPossibleCapital || 1}
                                        wrapInTooltip={this.wrapInTooltip}
                                    />
                                </div>
                            )}
                            {this.state.capitalOverviewListe !== 2 && (
                                <div className="modal-buttons">
                                    <Button
                                        className="button text-center mx-auto"
                                        submit={true}
                                        onClick={() => {
                                            this.closeModal();
                                        }}
                                        buttonType={EButtonType.PillSmall}
                                    >
                                        <FormattedMessageCustom
                                            id={translatableStrings.close.id}
                                            text={this.props.intl.formatMessage(
                                                translatableStrings.close
                                            )}
                                        />
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                    {this.state.capitalOverviewListe === 2 && (
                        <div className="modal-buttons">
                            <Button
                                className="button text-center mx-auto w-auto"
                                submit={true}
                                onClick={() => {
                                    this.closeModal();
                                }}
                                buttonType={EButtonType.PillSmall}
                            >
                                <FormattedMessageCustom
                                    id={translatableStrings.close.id}
                                    text={this.props.intl.formatMessage(
                                        translatableStrings.close
                                    )}
                                />
                            </Button>
                        </div>
                    )}
                </>
            );
        }
    }

    renderModalWithChildren = (children, fromControls?) => {
        return (
            <Modal
                isOpen={
                    fromControls ? this.state.modalControlsIsOpen : this.state.modalIsOpen
                }
                onRequestClose={this.closeModal}
                contentLabel="Large Modal"
                className={"large-modal"}
                closeTimeoutMS={600}
                overlayClassName={"large-modal-overlay"}
            >
                {fromControls
                    ? this.state.modalControlsIsOpen && children
                    : this.state.modalIsOpen && children}
            </Modal>
        );
    };

    render() {
        const monthlyOrYearly = this.props.monthlyOrYearly;

        //
        // BlockCapital
        //
        const totalCapitalForAnnnuity = this.props.calculationsObj
            .capitalInformation
            ? this.props.calculationsObj.capitalInformation.summary
                ?.totalCapitalForAnnnuity || 0
            : 0;
        const totalPossibleCapital = this.props.calculationsObj.capitalInformation
            ? this.props.calculationsObj.capitalInformation.summary
                ?.totalPossibleCapital || 0
            : 0;
        const totalCapital = this.props.calculationsObj.capitalInformation
            ? this.props.calculationsObj.capitalInformation.summary?.totalCapital || 0
            : 0;
        const capitalWithdrawalsTotal = this.props.calculationsObj
            .capitalInformation
            ? this.props.calculationsObj.capitalInformation.summary
                ?.totalCapitalWithdrawal || 0
            : 0;
        const conversionRate = this.props.calculationsObj.capitalInformation
            ? this.props.calculationsObj.capitalInformation.summary?.conversionRate ||
            0
            : 0;
        const sparguthaben = this.props.calculationsObj.capitalInformation
            ? this.props.calculationsObj.capitalInformation.summary
                ?.totalCapitalWithoutPurchases || 0
            : 0;

        const purchasedCapitals = Object.keys(
            this.props.userDataWI.capitalPurchases
        ).map((key) => {
            return (
                parseFloat(this.props.userDataWI.capitalPurchases[key]["amount"]) || 0
            );
        });

        const capitalWithdrawals = Object.keys(
            this.props.userDataWI.capitalWithdrawals
        ).map((key) => {
            return (
                parseFloat(this.props.userDataWI.capitalWithdrawals[key]["amount"]) || 0
            );
        });

        const totalCapitalForBridgingPension = this.props.calculationsObj
            .capitalInformation
            ? this.props.calculationsObj.capitalInformation.summary
                ?.totalCapitalForBridgingPension
            : 0;

        return (
            <div>
                <div className="chartContainer">
                    {this.renderModalWithChildren(
                        <div
                            className={classNames("modal", {
                                graph: this.state.capitalOverviewListe < 2,
                            })}
                        >
                            {/*{this.props.userDataWI.tenantName?.toUpperCase() === "PKAR" ?*/}
                            {/*    <img alt="logo" src={PKlogo} className='modalLogo' /> :*/}
                            {/*    <img alt="logo" src={logo} className='modalLogo' />}*/}
                            {this.renderModalContent()}
                        </div>
                    )}

                    {this.state.mobileDragging && (
                        <div className="d-sm-none d-block mobileMonthYear">
                            <FormattedMessageCustom
                                id={translatableStrings.lastMonth.id}
                                text={this.props.intl.formatMessage(
                                    translatableStrings.lastMonth
                                )}
                            >
                                <span style={{ fontWeight: 300 }} />
                            </FormattedMessageCustom>
                            <span>
                                {months(this.props.lang)[
                                    this.state.currentMonths[this.state.currentIndex]
                                ] +
                                    " " +
                                    this.state.currentYears[this.state.currentIndex]}
                            </span>
                        </div>
                    )}

                    <Chart
                        ages={this.state.ages}
                        ahvPensionierungObj={
                            this.props.calculationsObj["AHV-Pensionierung"] || {}
                        }
                        animateCounter={this.state.animateCounter}
                        arbeitPensums={this.props.arbeitPensums}
                        totalCapitalForAnnnuity={totalCapitalForAnnnuity}
                        blockWidths={this.state.blockDataObjs}
                        calculateCounterTrigger={this.props.calculateCounterTrigger}
                        calculationSteps={this.props.calculationSteps}
                        capitalPurchaseAmountMax={totalPossibleCapital}
                        capitalWithdrawals={capitalWithdrawals}
                        capitalWithdrawalsTotal={capitalWithdrawalsTotal}
                        chartRightRef={this.chartRightRef}
                        containerWidthPx={this.state.containerWidthPx}
                        conversionRate={conversionRate}
                        currentAge={this.state.currentAges}
                        currentMonths={this.state.currentMonths}
                        currentYears={this.state.currentYears}
                        descendingStepsToEnteringIndex={
                            this.props.userDataWI.descendingStepsToEnteringIndex
                        }
                        defaultRetirmentAgeFemale={this.props.defaultRetirmentAgeFemale}
                        defaultRetirmentAgeMale={this.props.defaultRetirmentAgeMale}
                        earlyPensionObj={
                            this.props.calculationsObj["Frühpensionierung"] || {}
                        }
                        erwerbstatigkeitObj={
                            this.props.calculationsObj["Erwerbstätigkeit"] || {}
                        }
                        firstAltersrente={this.props.userDataWI.firstAltersrente}
                        gender={this.props.userDataWI.Geschlecht}
                        goToRenten={this.goToRenten}
                        handleDrag={this.handleDrag}
                        handleWrapperWidth={this.state.handleWrapperWidth}
                        handlesLimits={this.state.handlesLimits}
                        handlesXPos={this.state.handlesXPosReal}
                        intl={this.props.intl}
                        lang={this.props.lang}
                        monthlyOrYearly={monthlyOrYearly}
                        months={this.state.months}
                        nominalRetirementDate={
                            this.props.userDataWI.Geschlecht === "weiblich"
                                ? parseDate(
                                    this.props.userDataWI.retirementDateAt64,
                                    "dateObject"
                                )
                                : parseDate(
                                    this.props.userDataWI.retirementDateAt65,
                                    "dateObject"
                                )
                        }
                        openModal={this.openModal}
                        partialPensions={this.props.calculationsObj["teilPensions"] || {}}
                        pensionierungObj={
                            this.props.calculationsObj["Pensionierung"] ||
                            this.props.calculationsObj["AHV-Pensionierung"] ||
                            {}
                        }
                        purchasedCapitals={purchasedCapitals}
                        removeTeilPension={this.removeTeilPension}
                        rerender={this.rerender}
                        retirementPercentages={this.props.retirementPercentages}
                        retirementSteps={this.props.retirementSteps}
                        retirementStepsInitial={
                            this.props.userDataWI.RetirementStepsInitial
                        }
                        sparguthaben={sparguthaben}
                        startDragging={this.startDragging}
                        totalPossibleCapital={totalPossibleCapital}
                        totalCapital={totalCapital}
                        totalCapitalForBridgingPension={totalCapitalForBridgingPension}
                        updateOnResize={this.updateOnResize}
                        userDataWI={this.props.userDataWI}
                        yearMax={
                            this.props.calculationsObj.limits
                                ? this.props.calculationsObj.limits.maximumLimit
                                    .retirementAge || 70
                                : 70
                        }
                        yearMin={
                            this.props.calculationsObj.limits
                                ? this.props.calculationsObj.limits.minimumLimit
                                    .retirementAge || 58
                                : 58
                        }
                        yearRatio={this.state.yearRatio}
                        yearWidthPx={this.state.yearWidthPx}
                        yearWidthPxWhenNotDragging={this.state.yearWidthPxReal}
                        years={this.state.years}
                    />
                </div>
                {console.log(
                    "this.props.calculations[this.retirementStepIndex]",
                    this.props.calculations[this.retirementStepIndex]
                )}
                <ChartControls
                    ref={this.props.chartControlsRef}
                    // bridgingPensionAmountMax={
                    //     this.props.calculations[this.retirementStepIndex]
                    //         ? monthlyOrYearly === "annualIncome"
                    //             ? this.props.calculations[this.retirementStepIndex].limitSet
                    //                 .maximumLimit.bridgingPensionAmount
                    //             : this.props.calculations[this.retirementStepIndex].limitSet
                    //                 .maximumLimit.bridgingPensionAmount / 12
                    //         : null
                    // }
                    // bridgingPensionAmountMin={
                    //     this.props.calculations[this.retirementStepIndex]
                    //         ? this.props.calculations[this.retirementStepIndex].limitSet
                    //             .minimumLimit.bridgingPensionAmount
                    //         : null
                    // }
                    calculateCounter={this.props.calculateCounter}
                    calculateCounterTrigger={this.props.calculateCounterTrigger}
                    calculateDebounced={this.calculateDebounced}
                    calculateMaxPurchase={this.props.calculateMaxPurchase}
                    calculationSteps={this.props.calculationSteps}
                    calculations={this.props.calculations}
                    isBridingPossible={this.props.isBridingPossible}
                    hasPayrollClasses={this.props.hasPayrollClasses}
                    capitalWidthdrawalAmountMax={
                        this.props.calculationSteps[0]
                            ? this.props.calculationSteps[0].limitSet.maximumLimit
                                .capitalWithdrawalAmount
                            : null
                    }
                    capitalWidthdrawalAmountMin={
                        this.props.calculationSteps[0]
                            ? this.props.calculationSteps[0].limitSet.minimumLimit
                                .capitalWithdrawalAmount
                            : null
                    }
                    capitalWithdrawalPercentageMax={
                        this.props.calculationSteps[0]
                            ? this.props.calculationSteps[0].limitSet.maximumLimit
                                .capitalWithdrawalPercentage
                            : null
                    }
                    clearControls={this.props.clearControls}
                    closeModal={this.closeModal}
                    goToRenten={this.goToRenten}
                    lastCalculatePayload={this.props.lastCalculatePayload}
                    intl={this.props.intl}
                    isEarlyPensionPresent={Boolean(
                        this.props.calculationsObj["Frühpensionierung"]
                    )}
                    mobileModal={this.props.mobileModal}
                    modalContent={this.state.modalContent}
                    modalIsOpen={this.state.modalControlsIsOpen}
                    monthlyOrYearly={monthlyOrYearly}
                    openModal={this.openModal}
                    removeCapitalPurchase={this.props.removeCapitalPurchase}
                    renderModalWithChildren={this.renderModalWithChildren}
                    removeTeilpension={this.removeTeilPension}
                    retirementSteps={this.props.retirementSteps}
                    setBridgingPension={this.props.setBridgingPension}
                    setCapitalPurchase={this.props.setCapitalPurchase}
                    setCapitalWithdrawal={this.props.setCapitalWithdrawal}
                    setDivorcePostings={this.props.setDivorcePostings}
                    setUserDataLohnklasse={this.props.setUserDataLohnklasse}
                    currentSituation={this.props.currentSituation}
                    userDataWI={this.props.userDataWI}
                    wrapInTooltip={this.wrapInTooltip}
                    setSincronizeMaxPurchases={this.props.setSincronizeMaxPurchases}
                />
            </div>
        );
    }
}
