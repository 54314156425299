import React from 'react';
import classNames from 'classnames';
import Slider from '../../../../components/Slider/index'
import { parseDate, parseToApiDate, addToLSObject, formatNumber, isDate, dateFormat, inputFormatDate } from '../../../../utils/react';
import { defined, isNumericOrDot } from '../../../../utils/variableEvaluation';
// import { postExample } from "../../../../constants/payloads";
import { Button, EButtonType } from "../../../../components/Button";
import { ReactComponent as HelpIcon } from "../../../../assets/icons/Help Icon.svg";
import Tooltip from 'rc-tooltip';
import PKlogo from '../../../../assets/imgs/pkar_logo.png';
import logo from '../../../../assets/icons/logo.png';
import { ReactComponent as IconTrash } from '../../../../assets/icons/trash.svg';
import { ReactComponent as IconPlus } from '../../../../assets/icons/plus.svg';
import './ChartControls.scss';
import { removeInArrayByIdx, checkIfArrayIsUnique } from '../../../../utils/array';
import { defineMessages } from 'react-intl';
import FormattedMessageCustom from '../../../../components/FormattedMessageCustom';
import Card from "../../../../components/Card";
import { Dashboard } from "../../../../components/Card/Content/Dashboard";
import { ReactComponent as HouseIcon } from "../../../../assets/icons/house-icon.svg";
import { ReactComponent as ChartIcon } from "../../../../assets/icons/chart-icon.svg";
import { ReactComponent as CaseIcon } from "../../../../assets/icons/urlaub-icon.svg";
import { ReactComponent as ScheidungIcon } from "../../../../assets/icons/scheidung.svg";
import { ButtonColors, ButtonTypes, Input } from "icr-shared-components";
import { ReactComponent as Arrow } from "../../../../assets/icons/arrow-right-action.svg";
import { Button as SharedButton } from "icr-shared-components";


import Scheidung from "./Scheidung"

const translatableStrings = defineMessages({
    capitalWithdrawal: {
        id: 'pageChart.capitalWithdrawal',
        defaultMessage: 'Kapitalbezug'
    },
    capitalWithdrawalTooltip: {
        id: 'pageChart.capitalWithdrawalTooltip',
        defaultMessage: 'Anstelle einer Rente können Sie das angesparte Kapital beziehen. In diesem Fall erhalten Sie bei der Pensionierung eine einmalige Auszahlung des Kapitals. Sie haben die Möglichkeit einen Teil der Altersleistungen als Rente und einen Teil als Kapital zu beziehen. Beachten Sie, dass durch den Bezug von Kapital die Rente vermindert wird oder wegfällt. Allfällige Beschränkungen sind im Simulator berücksichtigt. '
    },
    allBuyInsRemoved: {
        id: 'pageChart.allBuyInsRemoved',
        defaultMessage: 'Sie haben alle Kapitaleinkäufe gelöscht'
    },
    partialPensionRetirement: {
        id: 'pageChart.partialPensionRetirement',
        defaultMessage: 'Teilpensionierung'
    },
    fullPensionRetirement: {
        id: 'pageChart.fullPensionRetirement',
        defaultMessage: 'Vollpensionierung'
    },
    forPartialPension: {
        id: 'pageChart.forPartialPension',
        defaultMessage: 'Per {partialPensionDate} Teilpensionierung {partialPensionNo}%'
    },
    forPension: {
        id: 'pageChart.forPension',
        defaultMessage: 'Per {partialPensionDate} Pensionierung {partialPensionNo}%'
    },
    howMuchWithdraw: {
        id: 'pageChart.howMuchWithdraw',
        defaultMessage: 'Wie viel Kapital wollen Sie beziehen?',
    },
    capitalBuyInInvalidExplanation: {
        id: 'pageChart.capitalBuyInInvalidExplanation',
        defaultMessage: 'Spätestes Kapitaleinkaufsdatum ist der Zeitpunkt der vollen Pensionierung. Dieser Kapitaleinkauf wird daher für die Berechnung nicht berücksichtigt. Ändern Sie das Datum es Einkaufs oder den Zeitpunkt der vollen Pensionierung.'
    },
    ready: {
        id: 'button.ready',
        defaultMessage: 'FERTIG',
    },
    back: {
        id: 'button.back',
        defaultMessage: 'Zurück',
    },
    cancel: {
        id: 'button.cancel',
        defaultMessage: 'ABBRECHEN',
    },
    change: {
        id: 'button.change',
        defaultMessage: 'BEARBEITEN',
    },
    goBack: {
        id: 'button.goBack',
        defaultMessage: 'Zurücksetzen'
    },
    partialPension: {
        id: 'pageChart.partialPension',
        defaultMessage: 'Teilpension'
    },
    bridgingPension: {
        id: 'pageChart.bridgingPension',
        defaultMessage: 'Überbrückungsrente'
    },
    bridgingPensionTooltip: {
        id: 'pageChart.bridgingPensionTooltip',
        defaultMessage: 'Im Falle einer Frühpensionierung können Sie mit einer Überbrückungsrente die bis zur ordentlichen Pensionierung noch nicht verfügbare AHV-Rente kompensieren. Beachten Sie, dass der Bezug einer Überbrückungsrente die Höhe der Rente nach der ordentlichen Pensionierung beeinflusst.'
    },
    hide: {
        id: 'pageChart.hide',
        defaultMessage: 'HIDE'
    },
    showAll: {
        id: 'pageChart.showAll',
        defaultMessage: 'ALLE ANZEIGEN'
    },
    addCapitalWithdrawal: {
        id: 'pageChart.addCapitalWithdrawal',
        defaultMessage: 'Kapitalbezug hinzufügen'
    },
    addCapitalBuyIn: {
        id: 'pageChart.addCapitalBuyIn',
        defaultMessage: 'Kapitaleinkauf hinzufügen'
    },
    moreWithdrawals: {
        id: 'pageChart.moreWithdrawals',
        defaultMessage: 'weiterer Kapitalbezug'
    },
    moreBuyIns: {
        id: 'pageChart.moreBuyIns',
        defaultMessage: 'weiterer Kapitaleinkauf'
    },
    capitalBuyIn: {
        id: 'pageChart.capitalBuyIn',
        defaultMessage: 'Kapitaleinkauf'
    },
    capitalBuyInTooltip: {
        id: 'pageChart.capitalBuyInTooltip',
        defaultMessage: 'Der Kapitaleinkauf ist eine freiwillige Einzahlung in die Pensionskasse. Dadurch lassen sich einerseits die Altersleistungen erhöhen und andrerseits Steuern sparen, da der Einzahlungsbetrag von den Steuern abziehbar ist. Ein Kapitaleinkauf untersteht gewissen Beschränkungen, welche hier im Simulator automatisch berücksichtigt werden.'
    },
    whenDeposit: {
        id: 'pageChart.whenDeposit',
        defaultMessage: 'Wann möchten Sie einzahlen und wie hoch ist der Einkaufsbetrag?'
    },
    capitalBuyInNotPossible: {
        id: 'pageChart.capitalBuyInNotPossible',
        defaultMessage: 'An diesem Datum ist kein Einkauf möglich.'
    },
    wrongDateForCapitalBuyIn: {
        id: 'pageChart.wrongDateForCapitalBuyIn',
        defaultMessage: 'Ungültiges Datum für den Kapitaleinkauf'
    },
    wrongPayrollClassValue: {
        id: 'pageChart.wrongPayrollClassValue',
        defaultMessage: 'Die Lohnklasse muss zwischen 1 und 30 liegen.'
    },
    date: {
        id: 'general.date',
        defaultMessage: 'TT.MM.JJJJ'
    },
    variables: {
        id: 'pageChart.variables',
        defaultMessage: 'Variable Werte'
    },
    capitalEditOrRemove: {
        id: 'pageChart.capitalEditOrRemove',
        defaultMessage: 'Kapitaleinkäufe bearbeiten oder löschen.'
    },
    capitalOverview: {
        id: 'pageChart.capitalOverview',
        defaultMessage: 'Übersicht Kapitaleinkäufe'
    },
    capitalWithdrawalOverview: {
        id: 'pageChart.capitalWithdrawalOverview',
        defaultMessage: 'Capital Withdrawals overview'
    },
    teilpensionNotPossible: {
        id: 'pageChart.teilpensionNotPossible',
        defaultMessage: 'Es ist maximal ein Teilpensionierungsschritt möglich.'
    },
    teilpensionNotPossiblePKAR: {
        id: 'pageChart.teilpensionNotPossiblePKAR',
        defaultMessage: 'Es kann aus technischen Gründen nur ein Teilpensionierungsschritt erfasst werden.'
    },
    backToInsurantPortalButton: {
        id: 'pageChart.backToInsurantPortalButton',
        defaultMessage: 'ZURÜCK ZUM VERSICHERTEN-PORTAL'
    },
    totalRetirement: {
        id: 'pageChart.totalRetirement',
        defaultMessage: 'Altersrente'
    },
    scheidung: {
        id: 'pageChart.scheidung',
        defaultMessage: 'Betrag'
    },
    scheidungTooltip: {
        id: 'pageChart.scheidungTooltip',
        defaultMessage: 'Bei einer Trennung vom Ehepartner kommt es zur güterrechtlichen Auseinandersetzung. Die Ehegatten müssen bei der Scheidung das Vermögen teilen, und zwar in Übereinstimmung mit der Errungenschaftsbeteiligung oder einer anderen vorab in einem Ehevertrag vereinbarten Güterregelung. Je nach Fall kommt es zu einer Abnahme oder Zunahme des Kapitals.'
    },
    enterPayrollClass: {
        id: 'pageLohnklasse.enterPayrollClass',
        defaultMessage: 'Lohnklasse eingeben (1 bis 30)'
    }
});

interface BridgingPensionValues {
    bridgingPensionAmount: number,
    linkedRetirementStepDate: string;
    maximumPensionAmount: number;
    minimumPensionAmount: number;
    id: string;
};

interface State {
    capitalPurchaseDateInvalid: Array<boolean>;
    capitalPurchaseDateInvalidFromDrag: boolean;
    capitalPurchaseDates: Array<string>;
    capitalPurchaseDatesCandidate: Array<string>;
    capitalPurchaseRemoveCandidate: Array<number>;
    resetCounter: number;
    showAll: boolean;
    showAllWithdrawals: boolean;
    sliderValueBridgingPension: Array<BridgingPensionValues>;
    sliderValuesPurchase: Array<any>;
    sliderValuesPurchaseCandidate: Array<any>;
    sliderValuesWithdrawal: Array<any>;
    sliderValuesWithdrawalCandidate: Array<any>;
    incomePeriodSet: Array<any>;
    scheidungTransfer: number;
    scheidungDate: any;
    payrollClass: any;
}

interface Props {
    // activeSegment: number;
    // getSegmentIdx: any;
    // sliderValues: any;
    // sliderValuesPurchase: any;
    // bridgingPensionAmountMax: number;
    // bridgingPensionAmountMin: number
    calculateCounter: number;
    calculateCounterTrigger: number;
    calculateDebounced: any;
    calculateMaxPurchase: any;
    calculationSteps: any;
    calculations: any;
    capitalWidthdrawalAmountMax: number;
    capitalWidthdrawalAmountMin: number;
    capitalWithdrawalPercentageMax: number;
    clearControls: any;
    closeModal: any;
    goToRenten: any;
    lastCalculatePayload: any;
    intl: any;
    isEarlyPensionPresent: boolean;
    isBridingPossible: boolean;
    mobileModal: boolean;
    modalContent: string;
    modalIsOpen: boolean;
    monthlyOrYearly: string;
    openModal: any;
    removeCapitalPurchase: any;
    renderModalWithChildren: any;
    removeTeilpension: any;
    retirementSteps: any;
    setBridgingPension: any;
    setCapitalPurchase: any;
    setCapitalWithdrawal: any;
    userDataWI: any;
    wrapInTooltip: any;
    setDivorcePostings: any;
    currentSituation: any;
    setSincronizeMaxPurchases:any;
    hasPayrollClasses: boolean;
    setUserDataLohnklasse: any;
}



// const initialSliderValuesPurchase = [];
// const initialSliderValuesWithdrawal = [];

// Modal.setAppElement('#root');

export default class ChartControls extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);

        let capitalPurchaseDates = [];
        let sliderValuesWithdrawal = [];
        let sliderValuesPurchase = [];
        Object.keys(this.props.userDataWI.maxPurchases).forEach((key) => {
            const date = parseDate(key, "secsToDDMMYYYY");
            capitalPurchaseDates.push(date);
        });

        Object.keys(this.props.userDataWI.capitalWithdrawals).filter(key => key !== 'number').forEach((key) => {
            const amount = this.props.userDataWI.capitalWithdrawals[key]['amount'];
            console.log('amount', amount)
            sliderValuesWithdrawal.push(amount);
        });

        Object.keys(this.props.userDataWI.capitalPurchases).forEach((key) => {
            const amount = this.props.userDataWI.capitalPurchases[key]['amount'];
            sliderValuesPurchase.push(amount);
        });


        this.state = {
            showAll: false,
            showAllWithdrawals: false,
            capitalPurchaseDateInvalid: [false],
            capitalPurchaseDateInvalidFromDrag: false,
            capitalPurchaseDates: capitalPurchaseDates,
            capitalPurchaseDatesCandidate: capitalPurchaseDates,
            capitalPurchaseRemoveCandidate: [],
            sliderValueBridgingPension: this.props.userDataWI.bridgingPensionSteps || [],
            resetCounter: 0,
            sliderValuesWithdrawal: sliderValuesWithdrawal,
            sliderValuesWithdrawalCandidate: sliderValuesWithdrawal,
            sliderValuesPurchase: sliderValuesPurchase,
            sliderValuesPurchaseCandidate: sliderValuesPurchase,
            incomePeriodSet: this.props.userDataWI.lastCalculateResponse.incomePeriodSet,
            scheidungTransfer: 1,
            scheidungDate: null,
            payrollClass: this.props.userDataWI.payrollClass ? this.props.userDataWI.payrollClass : (this.props.userDataWI.settings && this.props.userDataWI.settings.suggestMaxBridgingPensionValues) ? "30" : "1"
        };
    }
    private updateTimeout: any = null;
    private recalculatedMaxPurchasesAt: number = 0;
    private capitalPurchaseDateInvalid: Array<boolean> = [false];

    componentDidMount() {
        // console.log('ChartControls app did mount', this.props);

        this.checkCapitalBuyInDates();

        const incomePeriodSet = this.props.userDataWI.lastCalculateResponse.incomePeriodSet;

        if (this.state.sliderValueBridgingPension.length === 0 && incomePeriodSet.length > 0) {
            const setSuggestedValues = this.props.userDataWI.settings && this.props.userDataWI.settings.suggestMaxBridgingPensionValues;
            console.log("entrou1", this.state.sliderValueBridgingPension.length)

            let sliderValues = [];
            incomePeriodSet.map((incomePeriod) => {
                if (incomePeriod.limitSet.isBridingPensionPossible) {
                    sliderValues.push({
                        bridgingPensionAmount: setSuggestedValues ? incomePeriod.limitSet.maximumLimit.bridgingPensionAmount : 0,
                        maximumPensionAmount: incomePeriod.limitSet.maximumLimit.bridgingPensionAmount,
                        minimumPensionAmount: incomePeriod.limitSet.minimumLimit.bridgingPensionAmount, 
                        linkedRetirementStepDate: incomePeriod.linkedRetirementStep.retirementDate,
                        id: incomePeriod.linkedRetirementStep.retirementStepId
                    });
                }
            })

            this.setState({
                sliderValueBridgingPension: sliderValues
            }
                , () => {
                    clearTimeout(this.updateTimeout);
                    this.updateTimeout = setTimeout(() => {
                        this.props.setBridgingPension({ sliderValues, reloadPage: false });
                    }, 300)
                }
            );
        }

    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const incomePeriodSet = this.props.userDataWI.lastCalculateResponse.incomePeriodSet;

        if (incomePeriodSet.length > 0) {
            if (this.state.sliderValueBridgingPension.length > incomePeriodSet.filter(obj => obj.limitSet.isBridingPensionPossible).length) {

                let sliderToRemove;
                let slidersToUpdate = [];
                this.state.sliderValueBridgingPension.forEach(sliderObj => {

                    const existents = incomePeriodSet.find((obj) => obj.limitSet.isBridingPensionPossible && obj.linkedRetirementStep?.retirementStepId === sliderObj.id)

                    // Set old sliders to update
                    if(existents && existents.limitSet?.maximumLimit?.bridgingPensionAmount !== sliderObj.maximumPensionAmount) {
                        slidersToUpdate.push(existents);
                    }

                    // Set to add remove slider
                    if (!existents) {
                        sliderToRemove = sliderObj
                    }
                })
                // Remove incomePeriod from slider
                let localSliderValueBridgingPension = this.state.sliderValueBridgingPension.filter((slider) => slider.id !== sliderToRemove.id);

                // Update if needed the older slider values
                if(slidersToUpdate && slidersToUpdate?.length > 0) {
                    slidersToUpdate.forEach(sliderToUpdate => {
                        localSliderValueBridgingPension = localSliderValueBridgingPension.filter(currentSlider => currentSlider.id !== sliderToUpdate.linkedRetirementStep?.retirementStepId);
                        const stateSlider = this.state.sliderValueBridgingPension.find(slider => slider.id === sliderToUpdate.linkedRetirementStep?.retirementStepId);
                        localSliderValueBridgingPension.push({
                            bridgingPensionAmount: stateSlider.bridgingPensionAmount > sliderToUpdate.limitSet.maximumLimit.bridgingPensionAmount ? sliderToUpdate.limitSet.maximumLimit.bridgingPensionAmount : stateSlider.bridgingPensionAmount,
                            linkedRetirementStepDate: sliderToUpdate.linkedRetirementStep.retirementDate,
                            maximumPensionAmount: sliderToUpdate.limitSet.maximumLimit.bridgingPensionAmount,
                            minimumPensionAmount: sliderToUpdate.limitSet.minimumLimit.bridgingPensionAmount,
                            id: sliderToUpdate.linkedRetirementStep.retirementStepId
                        });
                    });
                }

                this.setState({
                    sliderValueBridgingPension: localSliderValueBridgingPension
                }, () => {
                    clearTimeout(this.updateTimeout);
                    this.updateTimeout = setTimeout(() => {
                        this.props.setBridgingPension({ sliderValues: localSliderValueBridgingPension, reloadPage: false });
                    }, 300)
                });
            } else if (this.state.sliderValueBridgingPension.length < incomePeriodSet.filter(obj => obj.limitSet.isBridingPensionPossible).length) {

                let sliderToAdd;
                let slidersToUpdate = [];
                incomePeriodSet.forEach(incomePeriod => {
                    if (incomePeriod.limitSet.isBridingPensionPossible) {
                        const existents = this.state.sliderValueBridgingPension.find((obj) => obj.id === incomePeriod.linkedRetirementStep?.retirementStepId);

                        // Set old sliders to update
                        if(existents && incomePeriod.limitSet?.maximumLimit?.bridgingPensionAmount !== existents.maximumPensionAmount) {
                            slidersToUpdate.push(incomePeriod);
                        }

                        // Set to add new slider
                        if (!existents) {
                            sliderToAdd = incomePeriod;
                        }
                    }
                })

                let localSliderValueBridgingPension = this.state.sliderValueBridgingPension;
                
                // Update if needed the older slider values
                if(slidersToUpdate && slidersToUpdate?.length > 0) {
                    slidersToUpdate.forEach(sliderToUpdate => {
                        localSliderValueBridgingPension = localSliderValueBridgingPension.filter(currentSlider => currentSlider.id !== sliderToUpdate.linkedRetirementStep?.retirementStepId);
                        const stateSlider = this.state.sliderValueBridgingPension.find(slider => slider.id === sliderToUpdate.linkedRetirementStep?.retirementStepId);
                        localSliderValueBridgingPension.push({
                            bridgingPensionAmount: stateSlider.bridgingPensionAmount > sliderToUpdate.limitSet.maximumLimit.bridgingPensionAmount ? sliderToUpdate.limitSet.maximumLimit.bridgingPensionAmount : stateSlider.bridgingPensionAmount,
                            linkedRetirementStepDate: sliderToUpdate.linkedRetirementStep.retirementDate,
                            maximumPensionAmount: sliderToUpdate.limitSet.maximumLimit.bridgingPensionAmount,
                            minimumPensionAmount: sliderToUpdate.limitSet.minimumLimit.bridgingPensionAmount,
                            id: sliderToUpdate.linkedRetirementStep.retirementStepId
                        });
                    });
                }

                const setSuggestedValues = this.props.userDataWI.settings && this.props.userDataWI.settings.suggestMaxBridgingPensionValues;

                // Add new incomePeriod slider to state
                localSliderValueBridgingPension.push({
                    bridgingPensionAmount: setSuggestedValues ? sliderToAdd.limitSet.maximumLimit.bridgingPensionAmount : 0,
                    linkedRetirementStepDate: sliderToAdd.linkedRetirementStep.retirementDate,
                    maximumPensionAmount: sliderToAdd.limitSet.maximumLimit.bridgingPensionAmount,
                    minimumPensionAmount: sliderToAdd.limitSet.minimumLimit.bridgingPensionAmount,
                    id: sliderToAdd.linkedRetirementStep.retirementStepId
                });

                console.log("entrou3")

                const isTeilPensionierung = sliderToAdd.label === "Teilpensionierung";

                this.setState({
                    sliderValueBridgingPension: localSliderValueBridgingPension
                }, () => {
                    clearTimeout(this.updateTimeout);
                    this.updateTimeout = setTimeout(() => {
                        this.props.setBridgingPension({ sliderValues: localSliderValueBridgingPension, reloadPage: true });
                    }, isTeilPensionierung ? 5200 : 200) //TODO
                });

            }
        }


        // if (incomePeriod?.linkedRetirementStep && !incomePeriod?.linkedRetirementStep?.retirementStepId && incomePeriod.limitSet.isBridingPensionPossible) {
        //     const bridgingPensionObj = {
        //         bridgingPensionAmount: 0,
        //         linkedRetirementStepDate: incomePeriod.linkedRetirementStep.retirementDate,
        //         id: String(Math.floor(Math.random() * (100 - 0 + 1) + 0))
        //     };
        //     let localSliderValues = this.state.sliderValueBridgingPension;

        //     if (!localSliderValues.find(slider => slider.id === bridgingPensionObj.id)) {
        //         localSliderValues.push(bridgingPensionObj);
        //         this.setState({
        //             sliderValueBridgingPension: localSliderValues
        //         })
        //     }

        //     console.log(localSliderValues, "test")
        // }


        if (Object.keys(prevProps.userDataWI.capitalWithdrawals).length !== Object.keys(this.props.userDataWI.capitalWithdrawals).length) {
            let sliderValuesWithdrawal = [];
            Object.keys(this.props.userDataWI.capitalWithdrawals).forEach((key) => {
                const amount = this.props.userDataWI.capitalWithdrawals[key]['amount'];
                console.log('amount', amount)
                sliderValuesWithdrawal.push(amount);
            });

            this.setState({
                sliderValuesWithdrawal: sliderValuesWithdrawal,
                sliderValuesWithdrawalCandidate: sliderValuesWithdrawal,
            });
        }

        if (Object.keys(prevProps.userDataWI.capitalPurchases).length !== Object.keys(this.props.userDataWI.capitalPurchases).length) {
            let sliderValuesPurchase = [];
            Object.keys(this.props.userDataWI.capitalPurchases).forEach((key) => {
                const amount = this.props.userDataWI.capitalPurchases[key]['amount'];
                console.log('amount', amount)
                sliderValuesPurchase.push(amount);
            });

            this.setState({
                sliderValuesPurchase: sliderValuesPurchase,
                sliderValuesPurchaseCandidate: sliderValuesPurchase
            });
        }

        if (prevProps.userDataWI.RetirementSteps !== this.props.userDataWI.RetirementSteps) {
            console.log('RetirementSteps changed', prevProps.userDataWI.RetirementSteps, this.props.userDataWI.RetirementSteps);
            this.checkCapitalBuyInDates();
        }
        if (prevState.capitalPurchaseDateInvalid.includes(true) && !this.state.capitalPurchaseDateInvalid.includes(true)) {
            this.recalculatedMaxPurchasesAt = this.props.calculateCounter;
        }

        if (this.props.calculateCounter > this.recalculatedMaxPurchasesAt) {
            Object.keys(this.props.userDataWI.capitalPurchases).forEach((key, idx) => {
                this.calculateCapitalPurchase({
                    target: {
                        value: this.props.userDataWI.capitalPurchases[key]['date']
                    }
                }, idx);
            });
            this.recalculatedMaxPurchasesAt = this.props.calculateCounter;
        }
    }

    checkCapitalBuyInDates = () => {
        console.log('checkCapitalBuyInDates', this.props.userDataWI.RetirementSteps)
        // let newCapitalPurchaseDateInvalid = [...this.state.capitalPurchaseDateInvalid];
        let newCapitalPurchaseDateInvalid = [...this.capitalPurchaseDateInvalid];
        let capitalPurchaseDateInvalidFromDrag = false;
        this.props.userDataWI.RetirementSteps.forEach((stepDate, idx) => {
            Object.keys(this.props.userDataWI.capitalPurchases).forEach((key, idx2) => {
                const purchaseDateTime = new Date(this.props.userDataWI.capitalPurchases[key]['date']).getTime();
                const stepDateTime = new Date(stepDate).getTime();
                console.log('purchaseDateTime', purchaseDateTime,
                    'stepDateTime', stepDateTime,
                    'this.props.userDataWI.capitalPurchases[key][date]', this.props.userDataWI.capitalPurchases[key]['date'])

                if (stepDateTime < purchaseDateTime) {
                    console.log('invalid idx2', idx2)
                    newCapitalPurchaseDateInvalid[idx2] = true;
                    capitalPurchaseDateInvalidFromDrag = true;
                } else {
                    console.log('valid', idx2)
                    newCapitalPurchaseDateInvalid[idx2] = false;
                    capitalPurchaseDateInvalidFromDrag = false;
                }

                console.log('will set state, newCapitalPurchaseDateInvalid', newCapitalPurchaseDateInvalid);
            })
        });

        this.capitalPurchaseDateInvalid = [...newCapitalPurchaseDateInvalid];
        this.setState({
            capitalPurchaseDateInvalid: [...newCapitalPurchaseDateInvalid],
            capitalPurchaseDateInvalidFromDrag
        });
    }

    // if reset came from visualization its gonna be null (!modalContent)
    resetControls = (modalContent) => {
        if(!modalContent || modalContent === 'startCapitalBuyIn'){
            this.capitalPurchaseDateInvalid = [false];
        }

        this.setState({
            // capitalwithdrawal
            sliderValuesWithdrawal: !modalContent || modalContent === 'capitalwithdrawal' ? [0] : this.state.sliderValuesWithdrawal,
            sliderValuesWithdrawalCandidate: !modalContent || modalContent === 'capitalwithdrawal' ? [0] : this.state.sliderValuesWithdrawalCandidate,
            
            // überbrückungsrente
            sliderValueBridgingPension: !modalContent || modalContent === 'überbrückungsrente' ? [] : this.state.sliderValueBridgingPension,
            payrollClass: !modalContent || modalContent === 'überbrückungsrente' ? (this.props.userDataWI.settings && this.props.userDataWI.settings.suggestMaxBridgingPensionValues) ? "30" : "1" : this.state.payrollClass,
            
            // startCapitalBuyIn
            sliderValuesPurchase: !modalContent || modalContent === 'startCapitalBuyIn' ? [0] : this.state.sliderValuesPurchase,
            sliderValuesPurchaseCandidate: !modalContent || modalContent === 'startCapitalBuyIn' ? [0] : this.state.sliderValuesPurchaseCandidate,
            capitalPurchaseDateInvalidFromDrag: !modalContent || modalContent === 'startCapitalBuyIn' ? false : this.state.capitalPurchaseDateInvalidFromDrag,
            capitalPurchaseDates: !modalContent || modalContent === 'startCapitalBuyIn' ? [""] : this.state.capitalPurchaseDates,
            capitalPurchaseDatesCandidate: !modalContent || modalContent === 'startCapitalBuyIn' ? [""] : this.state.capitalPurchaseDatesCandidate,
            capitalPurchaseDateInvalid: !modalContent || modalContent === 'startCapitalBuyIn' ? [false] : this.state.capitalPurchaseDateInvalid,

            resetCounter: this.state.resetCounter + 1
        }, () => {
            // general reset
            if(!modalContent){
                this.props.userDataWI.RetirementSteps.forEach((date, idx) => {
                    idx !== 0 && this.props.removeTeilpension(date, idx);
                });
            }
            if(!modalContent || modalContent === 'überbrückungsrente') {
                this.props.setUserDataLohnklasse({value: this.state.payrollClass, onlyPayrollClass: true});
            }
            this.props.clearControls(modalContent);
            // this.props.calculateDebounced();
        })
    }

    renderModal = (modalContent) => {
        console.log('rendermodal', modalContent);
        return (
            <div className={`modal`}>
                {/*{this.props.userDataWI.tenantName?.toUpperCase() === "PKAR" ?*/}
                {/*    <img alt="logo" src={PKlogo} className='modalLogo' /> :*/}
                {/*    <img alt="logo" src={logo} className='modalLogo' />}*/}
                {true &&
                    <div className="headerBackButton modalBackButtons">
                        <p className="chartTitleBack">
                            <SharedButton
                                className="mt-2"
                                type={ButtonTypes.Text}
                                color={ButtonColors.Action}
                                onClick={(e) => this.props.closeModal()}>
                                <Arrow className="rotate-180" /> <FormattedMessageCustom id={translatableStrings.back.id} text={this.props.intl.formatMessage(translatableStrings.back)} />
                            </SharedButton>
                        </p>
                        <p className="chartTitleBack">
                            <SharedButton
                                className="mt-2"
                                type={ButtonTypes.Text}
                                color={ButtonColors.Action}
                                onClick={(e) => this.resetControls(modalContent)}>
                                <FormattedMessageCustom id={translatableStrings.goBack.id} text={this.props.intl.formatMessage(translatableStrings.goBack)} />
                            </SharedButton>
                        </p>
                    </div>}
                {this.renderModalContent(modalContent)}
            </div>
        )
    }

    altersrente = (value, message?) => {
        return <div className="flex-column">
            <div className="flex-row-center">
                <FormattedMessageCustom id={translatableStrings.totalRetirement.id} text={this.props.intl.formatMessage(translatableStrings.totalRetirement)}>
                    <p className="totalRetirement">
                    </p>
                </FormattedMessageCustom>
                <Tooltip
                    mouseEnterDelay={0}
                    mouseLeaveDelay={0}
                    destroyTooltipOnHide={true}
                    placement={'bottom'}
                    trigger={'click'}
                    overlay={<div>{message || ''}</div>}>
                    <HelpIcon style={{ zIndex: 9999, width: '18px', marginLeft: '8px' }} />
                </Tooltip>
            </div>
            <div className="title">{`CHF ${formatNumber(value)}`}</div>
        </div>
    }

    divorcePurchase = (data: { date: string, value: string }) => {
        this.props.setDivorcePostings([data])
    }

    validatePayrollClass = () => {
        return this.props.hasPayrollClasses && (+this.state.payrollClass < 1 || +this.state.payrollClass > 30);
    }

    handlePayrollChange = (name, value) => {
        isNumericOrDot(value) && this.setState({
            payrollClass: value
        });
    }

    renderModalContent(modalContent) {
        if (modalContent === "capitalwithdrawal") {
            return (
                <>
                    <FormattedMessageCustom id={translatableStrings.howMuchWithdraw.id} text={this.props.intl.formatMessage(translatableStrings.howMuchWithdraw)}>
                        <div className="modal-header withdrawal add">
                        </div>
                    </FormattedMessageCustom>

                    <div className={`modal-content withdrawal`}>
                        <div className="row">
                            {this.props.calculations.length && [...this.props.calculations.filter((stepObj) => Boolean(stepObj.linkedRetirementStep))].map((stepObj, idx) => {
                                console.log(`percentage`, stepObj.linkedRetirementStep.retirementPercentage)
                                console.log(`values`, translatableStrings.forPension)
                                return (
                                    <div className="col-12 mb-4">
                                        <FormattedMessageCustom id={stepObj.linkedRetirementStep.retirementPercentage === 100 ? translatableStrings.forPension.id : translatableStrings.forPartialPension.id} text={this.props.intl.formatMessage(stepObj.linkedRetirementStep.retirementPercentage === 100 ? translatableStrings.forPension : translatableStrings.forPartialPension, {
                                            partialPensionDate: parseDate(stepObj.linkedRetirementStep.retirementDate, "secsToDDMMYYYY"),
                                            partialPensionNo: stepObj.linkedRetirementStep.retirementPercentage
                                        })}>
                                            <div className="sliderLikeLabel">
                                            </div>
                                        </FormattedMessageCustom>
                                        <Slider
                                            controlled
                                            intl={this.props.intl}
                                            // tooltip
                                            // tooltipText={this.props.intl.formatMessage(translatableStrings.capitalWithdrawalTooltip)}
                                            translateId={translatableStrings.capitalWithdrawal.id}
                                            label={this.props.intl.formatMessage(translatableStrings.capitalWithdrawal)}
                                            showPercInput
                                            maxVal={stepObj.limitSet.maximumLimit.capitalWithdrawalAmount}
                                            maxPercentage={stepObj.limitSet.maximumLimit.capitalWithdrawalPercentage}
                                            // activeSegment={0}
                                            minVal={stepObj.limitSet.minimumLimit.capitalWithdrawalAmount}
                                            initialValue={this.state.sliderValuesWithdrawalCandidate[idx]}
                                            forcedValue={this.state.sliderValuesWithdrawalCandidate[idx]}
                                            className={classNames("middle capitalWithdrawalSlider", {
                                                "inactive": this.props.capitalWidthdrawalAmountMax === 0
                                            })}
                                            domain={[stepObj.limitSet.minimumLimit.capitalWithdrawalAmount, stepObj.limitSet.maximumLimit.capitalWithdrawalAmount]}
                                            sliderValueChanged={(val, initial) => {
                                                const newSliderValues = [...this.state.sliderValuesWithdrawalCandidate];
                                                newSliderValues[idx] = val;
                                                console.log('newSliderValues1', newSliderValues)
                                                this.setState({
                                                    sliderValuesWithdrawalCandidate: newSliderValues,
                                                })
                                            }}
                                            resetCounter={this.state.resetCounter}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                        <div className="modal-buttons">
                            <Button
                                className="button reflect-button text-center mx-auto"
                                submit={true}
                                onClick={() => {
                                    let sliderValuesWithdrawalCandidate = [...this.state.sliderValuesWithdrawalCandidate];
                                    // const cleanValues = sliderValuesWithdrawalCandidate.filter((el) => {
                                    //     return defined(el)
                                    // });
                                    [...this.props.calculationSteps].reverse().forEach((stepObj, idx) => {
                                        console.log('idx', idx, 'calculationSteps', stepObj);
                                        // if (stepObj.limitSet.maximumLimit.capitalWithdrawalAmount > 0) {
                                        console.log('limitPositive', idx, sliderValuesWithdrawalCandidate);
                                        // defined(sliderValuesWithdrawalCandidate[idx]) && 
                                        this.props.setCapitalWithdrawal({
                                            "amount": defined(sliderValuesWithdrawalCandidate[idx]) ? sliderValuesWithdrawalCandidate[idx] : 0,
                                            "index": idx,
                                            "percentage": defined(sliderValuesWithdrawalCandidate[idx]) ? (sliderValuesWithdrawalCandidate[idx] / stepObj.limitSet.maximumLimit.capitalWithdrawalAmount * 100).toFixed(2) : 0
                                        });
                                        // }
                                    });
                                    this.setState({
                                        sliderValuesWithdrawal: sliderValuesWithdrawalCandidate,
                                        // sliderValuesWithdrawalCandidate: cleanValues,
                                    })
                                    this.props.closeModal();
                                }}
                                buttonType={EButtonType.PillSmall}
                            >
                                <FormattedMessageCustom id={translatableStrings.ready.id} text={this.props.intl.formatMessage(translatableStrings.ready)} />
                            </Button>
                        </div>
                    </div>
                </>
            )
        } else if (modalContent === "capitalbuyin") {
            const nextIdx = Object.keys(this.props.userDataWI.capitalPurchases).length;
            console.log('nextIdx2', this.getCapitalMaxPurchase(this.state.capitalPurchaseDatesCandidate[nextIdx]));
            return (
                <>
                    <div className="modal-header buyin modal-header-content">
                        <FormattedMessageCustom id={translatableStrings.whenDeposit.id} text={this.props.intl.formatMessage(translatableStrings.whenDeposit)} />
                        {/* {this.altersrente(this.props.userDataWI.firstAltersrente[this.props.monthlyOrYearly])} */}
                    </div>
                    <div className={`modal-content withdrawal buyin`}>
                        {/* <div className="row"> */}
                        <div className="col-12">
                            <div className="sliderLikeLabel d-flex flex-row mb-4">
                                <div className="d-flex flex-row w-100 flex-wrap mb-0">
                                    {Object.keys(this.props.userDataWI.capitalPurchases).map((key, idx) => {
                                        return (
                                            [<div className={classNames("d-flex w-100 flex-wrap justify-content-between mb-4", {
                                                // "mt-md-5": idx > 0,
                                                // "invalid": this.state.capitalPurchaseDateInvalid[idx]
                                                "invalid": this.capitalPurchaseDateInvalid[idx]
                                            })}>
                                                <div className="mr-1" style={{}}>{idx + 1}. <FormattedMessageCustom id={translatableStrings.capitalBuyIn.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyIn)} /></div>
                                                <div className="capitalBuyInText" style={{ minWidth: 100 }}>({dateFormat(this.props.userDataWI.capitalPurchases[key]['date'], "yyyymmdd")})</div>
                                                <div className="" style={{ minWidth: 82 }}>
                                                    CHF {formatNumber(this.props.userDataWI.capitalPurchases[key]['amount'], 0)}
                                                </div>
                                            </div>,
                                            // this.state.capitalPurchaseDateInvalid[idx] ? <p className="invalidPurchaseDate">
                                            this.capitalPurchaseDateInvalid[idx] ? <p className="invalidPurchaseDate">
                                                {this.state.capitalPurchaseDateInvalidFromDrag ?
                                                    <FormattedMessageCustom id={translatableStrings.wrongDateForCapitalBuyIn.id} text={this.props.intl.formatMessage(translatableStrings.wrongDateForCapitalBuyIn)}>
                                                    </FormattedMessageCustom>
                                                    : <FormattedMessageCustom id={translatableStrings.capitalBuyInNotPossible.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInNotPossible)}>
                                                    </FormattedMessageCustom>
                                                }
                                                <Tooltip
                                                    mouseEnterDelay={0}
                                                    mouseLeaveDelay={0}
                                                    destroyTooltipOnHide={true}
                                                    placement={'top'}
                                                    trigger={'click'}
                                                    overlay={
                                                        <FormattedMessageCustom id={translatableStrings.capitalBuyInInvalidExplanation.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInInvalidExplanation)}>
                                                        </FormattedMessageCustom>
                                                    }
                                                >
                                                    <HelpIcon style={{ zIndex: 9999, width: '18px', marginLeft: '8px' }} className="pointer" />
                                                </Tooltip>
                                            </p> : null
                                            ]

                                        )
                                    })}
                                </div>
                            </div>
                            <Slider
                                intl={this.props.intl}
                                controlled
                                // tooltip
                                // tooltipText={this.props.intl.formatMessage(translatableStrings.capitalBuyInTooltip)}
                                translateId={translatableStrings.capitalBuyIn.id}
                                label={this.props.intl.formatMessage(translatableStrings.capitalBuyIn)}
                                // activeSegment={nextIdx}
                                maxVal={this.getCapitalMaxPurchase(this.state.capitalPurchaseDatesCandidate[nextIdx])}
                                minVal={0}
                                className={classNames("capitalBuyInSlider", {
                                    // "inactive": this.state.capitalPurchaseDates.indexOf(this.stacapitalBuyInSliderte.capitalPurchaseDatesCandidate[nextIdx]) >= 0 || Object.keys(this.props.userDataWI.maxPurchases).length === nextIdx
                                    "inactive": !checkIfArrayIsUnique(this.state.capitalPurchaseDatesCandidate)
                                })}
                                domain={[0, this.getCapitalMaxPurchase(this.state.capitalPurchaseDatesCandidate[nextIdx])]}
                                initialValue={this.state.sliderValuesPurchaseCandidate[nextIdx]}
                                forcedValue={this.state.sliderValuesPurchaseCandidate[nextIdx]}
                                sliderValueChanged={(val, initial) => {
                                    const newSliderValues = [...this.state.sliderValuesPurchaseCandidate];
                                    newSliderValues[nextIdx] = val;
                                    this.recalculatedMaxPurchasesAt = this.props.calculateCounter + 1;

                                    this.setState({
                                        sliderValuesPurchaseCandidate: newSliderValues,
                                    }, () => {
                                    })
                                }}
                                resetCounter={this.state.resetCounter}
                            >

                                <div className="sliderDateInput">
                                    {/* <IconCalendar style={{
                                            position: 'absolute',
                                            right: '0px',
                                            bottom: '7px'
                                        }} /> */}
                                    <input
                                        data-translate={translatableStrings.date.id}
                                        placeholder={this.props.intl.formatMessage(translatableStrings.date)}
                                        type="text"
                                        pattern="(?:(?:0[1-9]|1[0-9]|2[0-9])\.(?:0[1-9]|1[0-2])|(?:30)\.(?:(?!02)(?:0[1-9]|1[0-2]))|(?:31)\.(?:0[13578]|1[02]))\.(?:19|20)[0-9]{2}"
                                        onBlur={(e) => this.calculateCapitalPurchase(e, nextIdx)}
                                        onChange={(e) => {
                                            console.log('inputChange capitalPurchaseDateInvalid', this.capitalPurchaseDateInvalid)
                                            const newDate = e.target.value;
                                            let newCapitalPurchaseDates = [...this.state.capitalPurchaseDatesCandidate];
                                            newCapitalPurchaseDates[nextIdx] = newDate;
                                            let newCapitalPurchaseDateInvalid = [...this.state.capitalPurchaseDateInvalid];
                                            newCapitalPurchaseDateInvalid[nextIdx] = false;
                                            this.capitalPurchaseDateInvalid = [...newCapitalPurchaseDateInvalid];
                                            this.setState({
                                                capitalPurchaseDatesCandidate: [...newCapitalPurchaseDates],
                                                capitalPurchaseDateInvalid: [...newCapitalPurchaseDateInvalid]

                                            });
                                        }}
                                        maxLength={10}
                                        value={this.state.capitalPurchaseDatesCandidate[nextIdx] ? inputFormatDate(this.state.capitalPurchaseDatesCandidate[nextIdx]) : ""}
                                        className={classNames("slider_val text-left", {
                                            // "invalid": this.state.capitalPurchaseDateInvalid[nextIdx]
                                            "invalid": this.capitalPurchaseDateInvalid[nextIdx]
                                        })}
                                    />
                                </div>

                            </Slider>
                            {/* {this.state.capitalPurchaseDateInvalid[nextIdx] && */}
                            {this.capitalPurchaseDateInvalid[nextIdx] &&
                                <p className="invalidPurchaseDate">
                                    {this.state.capitalPurchaseDateInvalidFromDrag ?
                                        <FormattedMessageCustom id={translatableStrings.wrongDateForCapitalBuyIn.id} text={this.props.intl.formatMessage(translatableStrings.wrongDateForCapitalBuyIn)}>
                                        </FormattedMessageCustom>
                                        : <FormattedMessageCustom id={translatableStrings.capitalBuyInNotPossible.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInNotPossible)}>
                                        </FormattedMessageCustom>
                                    }
                                    <Tooltip
                                        mouseEnterDelay={0}
                                        mouseLeaveDelay={0}
                                        destroyTooltipOnHide={true}
                                        placement={'top'}
                                        trigger={'click'}
                                        overlay={
                                            <FormattedMessageCustom id={translatableStrings.capitalBuyInInvalidExplanation.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInInvalidExplanation)}>
                                            </FormattedMessageCustom>
                                        }
                                    >
                                        <HelpIcon style={{ zIndex: 9999, width: '18px', marginLeft: '8px' }} className="pointer" />
                                    </Tooltip>
                                </p>
                            }

                        </div>
                        <div className="modal-buttons">
                            <Button
                                className="button reflect-button text-center mx-auto"
                                submit={true}
                                onClick={() => {
                                    const idx = this.state.sliderValuesPurchaseCandidate.length - 1;
                                    console.log('idx', idx, this.state.sliderValuesPurchaseCandidate);
                                    defined(this.state.sliderValuesPurchaseCandidate[idx]) && this.props.setCapitalPurchase({
                                        "amount": this.state.sliderValuesPurchaseCandidate[idx] || 0,
                                        "index": idx,
                                        "date": this.state.capitalPurchaseDatesCandidate[idx]
                                    });
                                    addToLSObject("userDataWI", "sliderValuesPurchase", this.state.sliderValuesPurchaseCandidate);

                                    this.setState({
                                        capitalPurchaseDates: [...this.state.capitalPurchaseDatesCandidate],
                                        sliderValuesPurchase: [...this.state.sliderValuesPurchaseCandidate],
                                    })
                                    this.props.setSincronizeMaxPurchases();
                                    this.props.closeModal();
                                }}
                                buttonType={EButtonType.PillSmall}
                            >
                                <FormattedMessageCustom id={translatableStrings.ready.id} text={this.props.intl.formatMessage(translatableStrings.ready)} />
                            </Button>

                            <Button
                                className="button aux-button"
                                onClick={() => {
                                    this.setState({
                                        capitalPurchaseDatesCandidate: [...this.state.capitalPurchaseDates],
                                        sliderValuesPurchaseCandidate: [...this.state.sliderValuesPurchase],
                                    });
                                    this.props.setSincronizeMaxPurchases();
                                    this.props.closeModal();
                                }}
                                buttonType={EButtonType.BasicLink}
                            >
                                <FormattedMessageCustom id={translatableStrings.cancel.id} text={this.props.intl.formatMessage(translatableStrings.cancel)} />
                            </Button>
                        </div>
                    </div>
                    {/* </div> */}
                </>
            )
        } else if (modalContent === "capitalbuyinedit") {
            console.log('modalContent222', modalContent, this.props.userDataWI);
            return (
                <>
                    <div className="modal-header buyin modal-header-content">
                        <FormattedMessageCustom id={translatableStrings.capitalEditOrRemove.id} text={this.props.intl.formatMessage(translatableStrings.capitalEditOrRemove)} />
                        {/* {this.altersrente(this.props.userDataWI.firstAltersrente[this.props.monthlyOrYearly])} */}
                    </div>
                    <div className={`modal-content withdrawal buyinedit`}>
                        {/* <div className="row"> */}
                        {Object.keys(this.state.sliderValuesPurchaseCandidate).length ? Object.keys(this.state.sliderValuesPurchaseCandidate).map((key, idx) => {
                            console.log('this.state.capitalPurchaseDates.indexOf(this.state.capitalPurchaseDatesCandidate[idx])', this.state.capitalPurchaseDates.indexOf(this.state.capitalPurchaseDatesCandidate[idx]));
                            return <>
                                <div className="col-11-desktop p-relative">
                                    <Slider
                                        intl={this.props.intl}
                                        controlled
                                        // tooltip
                                        // tooltipText={this.props.intl.formatMessage(translatableStrings.capitalBuyInTooltip)}
                                        className={classNames("capitalBuyInSlider edit-slider", {
                                            "inactive": Object.keys(this.props.userDataWI.maxPurchases).length === 0 || !checkIfArrayIsUnique(this.state.capitalPurchaseDatesCandidate),
                                            // "notForThisDate": this.state.capitalPurchaseDateInvalid[idx]
                                            "notForThisDate": this.capitalPurchaseDateInvalid[idx] || this.getCapitalMaxPurchase(this.state.capitalPurchaseDatesCandidate[idx]) === 0
                                        })}
                                        label={this.props.intl.formatMessage(translatableStrings.capitalBuyIn)}
                                        translateId={translatableStrings.capitalBuyIn.id}
                                        maxVal={this.getCapitalMaxPurchase(this.state.capitalPurchaseDatesCandidate[idx])}
                                        minVal={0}
                                        domain={[0, this.getCapitalMaxPurchase(this.state.capitalPurchaseDatesCandidate[idx])]}
                                        initialValue={defined(this.state.sliderValuesPurchaseCandidate[idx]) ? this.state.sliderValuesPurchaseCandidate[idx] : 0}
                                        forcedValue={defined(this.state.sliderValuesPurchaseCandidate[idx]) ? this.state.sliderValuesPurchaseCandidate[idx] : 0}
                                        sliderValueChanged={(val, initial) => {
                                            const newSliderValuesPurchase = [...this.state.sliderValuesPurchaseCandidate];
                                            newSliderValuesPurchase[idx] = val;
                                            this.recalculatedMaxPurchasesAt = this.props.calculateCounter + 1;
                                            this.setState({
                                                sliderValuesPurchaseCandidate: newSliderValuesPurchase,
                                            }, () => {

                                            })
                                        }}
                                        resetCounter={this.state.resetCounter}
                                    >
                                        <div className="sliderDateInput">
                                            {/* <IconCalendar style={{
                                                    position: 'absolute',
                                                    right: '0px',
                                                    bottom: '7px'
                                                }} /> */}
                                            <input
                                                data-translate={translatableStrings.date.id}
                                                placeholder={this.props.intl.formatMessage(translatableStrings.date)}
                                                type="text"
                                                onBlur={(e) => this.calculateCapitalPurchase(e, idx)}
                                                pattern="(?:(?:0[1-9]|1[0-9]|2[0-9])\.(?:0[1-9]|1[0-2])|(?:30)\.(?:(?!02)(?:0[1-9]|1[0-2]))|(?:31)\.(?:0[13578]|1[02]))\.(?:19|20)[0-9]{2}"
                                                onChange={(e) => {
                                                    console.log('inputChange capitalPurchaseDateInvalid', this.state.capitalPurchaseDateInvalid)
                                                    const newDate = e.target.value;
                                                    let newCapitalPurchaseDates = [...this.state.capitalPurchaseDatesCandidate];
                                                    newCapitalPurchaseDates[idx] = newDate;
                                                    let newCapitalPurchaseDateInvalid = [...this.capitalPurchaseDateInvalid];
                                                    // let newCapitalPurchaseDateInvalid = [...this.state.capitalPurchaseDateInvalid];
                                                    newCapitalPurchaseDateInvalid[idx] = false;
                                                    this.capitalPurchaseDateInvalid = [...newCapitalPurchaseDateInvalid];
                                                    this.setState({
                                                        capitalPurchaseDatesCandidate: [...newCapitalPurchaseDates],
                                                        capitalPurchaseDateInvalid: [...newCapitalPurchaseDateInvalid]
                                                    });
                                                }}
                                                value={inputFormatDate(this.state.capitalPurchaseDatesCandidate[idx]) || ""}
                                                className={classNames("slider_val text-left", {
                                                    // "invalid": this.state.capitalPurchaseDateInvalid[idx]
                                                    "invalid": this.capitalPurchaseDateInvalid[idx]
                                                })}
                                            />
                                        </div>
                                    </Slider>
                                    {/* {this.state.capitalPurchaseDateInvalid[idx] && <p className="invalidPurchaseDate"> */}
                                    {this.capitalPurchaseDateInvalid[idx] && <p className="invalidPurchaseDate">
                                        {this.state.capitalPurchaseDateInvalidFromDrag ?
                                            <FormattedMessageCustom id={translatableStrings.wrongDateForCapitalBuyIn.id} text={this.props.intl.formatMessage(translatableStrings.wrongDateForCapitalBuyIn)}>
                                            </FormattedMessageCustom>
                                            : <FormattedMessageCustom id={translatableStrings.capitalBuyInNotPossible.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInNotPossible)}>
                                            </FormattedMessageCustom>
                                        }
                                        <Tooltip
                                            mouseEnterDelay={0}
                                            mouseLeaveDelay={0}
                                            destroyTooltipOnHide={true}
                                            placement={'top'}
                                            trigger={'click'}
                                            overlay={
                                                <FormattedMessageCustom id={translatableStrings.capitalBuyInInvalidExplanation.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInInvalidExplanation)}>
                                                </FormattedMessageCustom>
                                            }
                                        >
                                            <HelpIcon style={{ zIndex: 9999, width: '18px', marginLeft: '8px' }} className="pointer" />
                                        </Tooltip>
                                    </p>}
                                    <button
                                        onClick={() => {
                                            console.log('click capitalPurchaseDateInvalid', this.state.capitalPurchaseDateInvalid);
                                            this.capitalPurchaseDateInvalid = [...removeInArrayByIdx([...this.state.capitalPurchaseDateInvalid], idx)];
                                            this.setState({
                                                sliderValuesPurchaseCandidate: [...removeInArrayByIdx([...this.state.sliderValuesPurchaseCandidate], idx)],
                                                capitalPurchaseDatesCandidate: [...removeInArrayByIdx([...this.state.capitalPurchaseDatesCandidate], idx)],
                                                capitalPurchaseRemoveCandidate: [...this.state.capitalPurchaseRemoveCandidate, idx],
                                                capitalPurchaseDateInvalid: [...removeInArrayByIdx([...this.state.capitalPurchaseDateInvalid], idx)],
                                                // resetCounter: this.state.resetCounter + 1
                                            });
                                        }}
                                        className="removeCapitalIcon nonButton"
                                    >
                                        <IconTrash />
                                    </button>
                                </div>
                            </>
                        })
                            : <p className="allRemoved">
                                <FormattedMessageCustom id={translatableStrings.allBuyInsRemoved.id} text={this.props.intl.formatMessage(translatableStrings.allBuyInsRemoved)} />
                            </p>
                        }
                        <div className="modal-buttons">
                            <Button
                                className="button reflect-button text-center mx-auto"
                                submit={true}
                                onClick={() => {
                                    // const apiDate = parseToApiDate(purchaseDate, "ddmmyyyy");
                                    // const key = apiDate;
                                    console.log('maxPurchases delete', this.props.userDataWI.maxPurchases);
                                    this.state.capitalPurchaseRemoveCandidate.forEach((purchaseIdx) => {
                                        this.props.removeCapitalPurchase(purchaseIdx);
                                    });

                                    this.state.capitalPurchaseDatesCandidate.forEach((purchaseDate, idx) => {
                                        defined(this.state.sliderValuesPurchaseCandidate[idx]) && this.props.setCapitalPurchase({
                                            "amount": this.state.sliderValuesPurchaseCandidate[idx] || 0,
                                            "index": idx,
                                            "date": this.state.capitalPurchaseDatesCandidate[idx]
                                        });
                                    });

                                    addToLSObject("userDataWI", "sliderValuesPurchase", this.state.sliderValuesPurchaseCandidate);

                                    this.setState({
                                        capitalPurchaseDates: [...this.state.capitalPurchaseDatesCandidate],
                                        sliderValuesPurchase: [...this.state.sliderValuesPurchaseCandidate],
                                        capitalPurchaseRemoveCandidate: []
                                    }, () => {
                                        this.props.setSincronizeMaxPurchases()
                                        this.props.closeModal();
                                    })
                                }}
                                buttonType={EButtonType.PillSmall}
                            >
                                <FormattedMessageCustom id={translatableStrings.ready.id} text={this.props.intl.formatMessage(translatableStrings.ready)} />
                            </Button>

                            <Button
                                className="button"
                                onClick={() => {
                                    this.setState({
                                        capitalPurchaseDatesCandidate: [...this.state.capitalPurchaseDates],
                                        sliderValuesPurchaseCandidate: [...this.state.sliderValuesPurchase],
                                        capitalPurchaseRemoveCandidate: []
                                    });
                                    this.props.setSincronizeMaxPurchases();
                                    this.props.closeModal();
                                }
                                }
                                buttonType={EButtonType.BasicLink}
                            >
                                <FormattedMessageCustom id={translatableStrings.cancel.id} text={this.props.intl.formatMessage(translatableStrings.cancel)} />
                            </Button>
                        </div>
                        {/* </div> */}
                    </div>
                </>
            )
        } else if (modalContent === "showAllBuyIns") {
            const nextIdx = Object.keys(this.props.userDataWI.capitalPurchases).length;
            console.log('nextIdx', nextIdx);
            return (
                <>
                    <FormattedMessageCustom id={translatableStrings.capitalOverview.id} text={this.props.intl.formatMessage(translatableStrings.capitalOverview)}>
                        <div className="modal-header  buyin"></div>
                    </FormattedMessageCustom>

                    <div className={`modal-content withdrawal buyin`}>
                        <div className="row">
                            <div className="col-12">
                                <div className="sliderLikeLabel d-flex flex-row mb-4">
                                    <div className="d-flex flex-row w-100 flex-wrap mb-0">
                                        {Object.keys(this.props.userDataWI.capitalPurchases).map((key, idx) => {
                                            return (
                                                [<div className={classNames("d-flex w-100 flex-wrap justify-content-between mb-1", {
                                                    "mt-1": idx > 0,
                                                    // "invalid": this.state.capitalPurchaseDateInvalid[idx]
                                                    "invalid": this.capitalPurchaseDateInvalid[idx]
                                                })}>
                                                    <div className="mr-1" style={{}}>{idx + 1}. <FormattedMessageCustom id={translatableStrings.capitalBuyIn.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyIn)} /></div>
                                                    <div className="capitalBuyInText" style={{ minWidth: 100 }}>({parseDate(this.props.userDataWI.capitalPurchases[key]['date'], "secsToDDMMYYYY")})
                                                        {/* {this.state.capitalPurchaseDateInvalid[idx] ? <p className="invalidPurchaseDate"> */}
                                                        {this.capitalPurchaseDateInvalid[idx] ? <p className="invalidPurchaseDate">
                                                            {/* {this.state.capitalPurchaseDateInvalidFromDrag ?
                                                        <FormattedMessageCustom id={translatableStrings.wrongDateForCapitalBuyIn.id} text={this.props.intl.formatMessage(translatableStrings.wrongDateForCapitalBuyIn)}>
                                                        </FormattedMessageCustom>
                                                        : <FormattedMessageCustom id={translatableStrings.capitalBuyInNotPossible.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInNotPossible)}>
                                                        </FormattedMessageCustom>
                                                    } */}
                                                            <Tooltip
                                                                mouseEnterDelay={0}
                                                                mouseLeaveDelay={0}
                                                                destroyTooltipOnHide={true}
                                                                placement={'top'}
                                                                trigger={'click'}
                                                                overlay={
                                                                    <FormattedMessageCustom id={translatableStrings.capitalBuyInInvalidExplanation.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInInvalidExplanation)}>
                                                                    </FormattedMessageCustom>
                                                                }
                                                            >
                                                                <HelpIcon style={{ zIndex: 9999, width: '18px', marginLeft: '8px' }} className="pointer" />
                                                            </Tooltip>
                                                        </p> : null}
                                                    </div>
                                                    <div className="" style={{ minWidth: 82 }}>
                                                        CHF {formatNumber(this.props.userDataWI.capitalPurchases[key]['amount'], 0)}
                                                    </div>
                                                </div>
                                                ]
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-buttons">
                                <Button
                                    className="button reflect-button"
                                    onClick={() => {
                                        this.props.closeModal();
                                    }}
                                    buttonType={EButtonType.PillSmall}
                                >
                                    <FormattedMessageCustom id={translatableStrings.back.id} text={this.props.intl.formatMessage(translatableStrings.back)} />
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            )
        } else if (modalContent === "showallwithdrawals") {
            return (
                <>
                    <FormattedMessageCustom id={translatableStrings.capitalWithdrawalOverview.id} text={this.props.intl.formatMessage(translatableStrings.capitalWithdrawalOverview)}>
                        <div className="modal-header  buyin"></div>
                    </FormattedMessageCustom>

                    <div className={`modal-content withdrawal buyin`}>
                        <div className="row">
                            <div className="col-12">
                                <div className="sliderLikeLabel d-flex flex-row mb-4">
                                    <div className="d-flex flex-row w-100 flex-wrap mb-0">
                                        {
                                            this.props.calculations.filter((stepObj) => Boolean(stepObj.linkedRetirementStep)).map((stepObj, idx) => {
                                                return (

                                                    <div className={classNames("d-flex w-100 flex-wrap justify-content-between mb-1 capitalPurchaseRowInModal", {
                                                        // "invalid": this.state.capitalPurchaseDateInvalid[idx]
                                                    })}>
                                                        <div>
                                                            {parseDate(stepObj.linkedRetirementStep.retirementDate, "secsToDDMMYYYY") + ' '}
                                                            {
                                                                stepObj.linkedRetirementStep.retirementPercentage === 100 ? (
                                                                    <FormattedMessageCustom key={translatableStrings.fullPensionRetirement.id} id={translatableStrings.fullPensionRetirement.id} text={this.props.intl.formatMessage(translatableStrings.fullPensionRetirement)} />
                                                                ) :
                                                                    <FormattedMessageCustom id={translatableStrings.partialPensionRetirement.id} key={translatableStrings.partialPensionRetirement.id} text={this.props.intl.formatMessage(translatableStrings.partialPensionRetirement)} />
                                                            } {" " + stepObj.linkedRetirementStep.retirementPercentage}%
                                                        </div>
                                                        {/* <div>{defined(stepObj.linkedRetirementStep.capitalWidthdrawalAmount) ? "CHF " + formatNumber(stepObj.linkedRetirementStep.capitalWidthdrawalAmount) : null}</div> */}


                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="modal-buttons">
                                <Button
                                    className="button reflect-button"
                                    onClick={() => {
                                        this.props.closeModal();
                                    }}
                                    buttonType={EButtonType.PillSmall}
                                >
                                    <FormattedMessageCustom id={translatableStrings.back.id} text={this.props.intl.formatMessage(translatableStrings.back)} />
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            )
        } else if (modalContent === "überbrückungsrente") {

            const incomePeriodSet = this.props.userDataWI.lastCalculateResponse.incomePeriodSet;
            return (
                <>
                    <FormattedMessageCustom id={translatableStrings.bridgingPension.id} text={this.props.intl.formatMessage(translatableStrings.bridgingPension)}>
                        <div className="modal-header  buyin"></div>
                    </FormattedMessageCustom>
                    {/* to do an array map and to render conditionaly if maximum limit exists */}
                    <div className={`modal-content withdrawal buyin`}>
                        <div className="row">
                            <div className="col-12">
                                
                            {this.props.hasPayrollClasses && <div className="d-flex flex-column w-100 flex-wrap mb-0 sliderLikeLabel">
                                <span>Lohnklasse</span>

                                <Input
                                    value={this.state.payrollClass}
                                    placeholder={this.props.intl.formatMessage(translatableStrings.enterPayrollClass)}
                                    inputWrapperClass="w-100"
                                    onChange={(name, val) => this.handlePayrollChange(name, val)}
                                    type='number'
                                    max={30}
                                    min={1}
                                    // validationMessageConfig={{}}
                                    inputWrapperStyle={{}}>
                                </Input>
                                {this.validatePayrollClass() && <p className="invalidPayrollClass">
                                    <FormattedMessageCustom id={translatableStrings.wrongPayrollClassValue.id} text={this.props.intl.formatMessage(translatableStrings.wrongPayrollClassValue)}>
                                    </FormattedMessageCustom>
                                </p>}
                            </div>}


                                <div className="sliderLikeLabel d-flex flex-column mb-4">
                                    {incomePeriodSet.map((incomePeriod, index) => {
                                        const endDate = parseDate(incomePeriod.endDate, "secsToDDMMYYYY");
                                        // this.props.monthlyOrYearly === "annualIncome"
                                        const currentSlider = this.state.sliderValueBridgingPension?.find((obj) => obj?.id === incomePeriod?.linkedRetirementStep?.retirementStepId);
                                        const annualIncome = this.props.monthlyOrYearly === "annualIncome";
                                        return incomePeriod.limitSet.isBridingPensionPossible ? (
                                            <div className="d-flex flex-column w-100 flex-wrap mb-0">
                                                <span>Von {parseDate(incomePeriod.startDate, "secsToDDMMYYYY")} {endDate === "31.12.9999" ? "" : `bis ${endDate}`}</span>
                                                <Slider
                                                    controlled
                                                    tooltip
                                                    intl={this.props.intl}
                                                    tooltipText={this.props.intl.formatMessage(translatableStrings.bridgingPensionTooltip)}
                                                    label={this.props.intl.formatMessage(translatableStrings.bridgingPension)}
                                                    className={classNames({
                                                        "inactive": this.validatePayrollClass()
                                                    })}
                                                    translateId={translatableStrings.bridgingPension.id}
                                                    maxVal={this.validatePayrollClass() ? 0 : annualIncome ? currentSlider?.maximumPensionAmount : (currentSlider?.maximumPensionAmount / 12)}
                                                    minVal={0}
                                                    initialValue={this.validatePayrollClass() ? 0 : annualIncome ? currentSlider?.bridgingPensionAmount : (currentSlider?.bridgingPensionAmount / 12)}
                                                    forcedValue={this.validatePayrollClass() ? 0 : annualIncome ? currentSlider?.bridgingPensionAmount : (currentSlider?.bridgingPensionAmount / 12)}
                                                    domain={[annualIncome ? currentSlider?.minimumPensionAmount : (currentSlider?.minimumPensionAmount / 12), (this.validatePayrollClass() ? 0 : annualIncome ? currentSlider?.maximumPensionAmount : (currentSlider?.maximumPensionAmount / 12))]}
                                                    sliderValueChanged={(val, initial) => {
                                                        const bridgingPensionObj = {
                                                            bridgingPensionAmount: annualIncome ? val : (val * 12),
                                                            linkedRetirementStepDate: incomePeriod.linkedRetirementStep.retirementDate,
                                                            maximumPensionAmount: incomePeriod.limitSet.maximumLimit.bridgingPensionAmount,
                                                            minimumPensionAmount: incomePeriod.limitSet.minimumLimit.bridgingPensionAmount,
                                                            id: incomePeriod.linkedRetirementStep.retirementStepId
                                                        };

                                                        let localSliderValues = this.state.sliderValueBridgingPension;

                                                        // localSliderValues[index] = bridgingPensionObj;

                                                        let sliderValue = localSliderValues.findIndex(obj => obj?.id === incomePeriod.linkedRetirementStep.retirementStepId);
                                                        console.log("bridginpensionobj", bridgingPensionObj);
                                                        console.log(sliderValue, "locals")
                                                        localSliderValues[sliderValue] = bridgingPensionObj;

                                                        console.log(localSliderValues, "localslidervalues")
                                                        console.log(index, this.state.sliderValueBridgingPension, "localslidervalues")

                                                        this.setState({
                                                            sliderValueBridgingPension: localSliderValues,
                                                        }, () => {
                                                            clearTimeout(this.updateTimeout);
                                                            this.updateTimeout = setTimeout(() => {
                                                                this.props.setBridgingPension({ sliderValues: localSliderValues, reloadPage: true });
                                                            }, 300);



                                                            // if (this.props.userDataWI.bridgingPension !== this.state.sliderValueBridgingPension) {
                                                            //     clearTimeout(this.updateTimeout);
                                                            //     if (this.props.userDataWI.bridgingPension !== this.state.sliderValueBridgingPension) {
                                                            //         clearTimeout(this.updateTimeout);
                                                            //         // if (this.props.calculateCounterTrigger > 0) {
                                                            //         // }
                                                            //         this.updateTimeout = setTimeout(() => {
                                                            //             defined(this.state.sliderValueBridgingPension) && this.props.setBridgingPension(this.props.monthlyOrYearly === "annualIncome" ? this.state.sliderValueBridgingPension * 12 : this.state.sliderValueBridgingPension);
                                                            //         }, 300);
                                                            //     }
                                                            // }
                                                        })
                                                    }}
                                                    resetCounter={this.state.resetCounter}
                                                />
                                            </div>) : null
                                    })}


                                </div>
                            </div>
                            <Button
                                className={classNames("button reflect-button")}
                                isDisabled={this.validatePayrollClass()}
                                onClick={() => {
                                    this.updateTimeout = setTimeout(() => {
                                        this.props.setUserDataLohnklasse({value: this.state.payrollClass, onlyPayrollClass: true});
                                    }, 300);
                                    
                                    this.props.closeModal();
                                }}
                                buttonType={EButtonType.PillMedium}
                            >
                                <FormattedMessageCustom id={translatableStrings.ready.id} text={this.props.intl.formatMessage(translatableStrings.ready)} />
                            </Button>

                        </div>
                    </div>
                </>)
        } else if (modalContent === "startCapitalBuyIn") {
            return (
                <>
                    <div className="modal-header buyin modal-header-content">
                        <FormattedMessageCustom id={translatableStrings.addCapitalBuyIn.id} text={this.props.intl.formatMessage(translatableStrings.addCapitalBuyIn)} />
                        {/* {this.altersrente(this.props.userDataWI.firstAltersrente[this.props.monthlyOrYearly])} */}
                    </div>
                    <div className={`modal-content withdrawal buyin`}>
                        <div className="row">
                            {/* d-flex */}
                            <div className="sliderWrapperParent flex-wrap p-md-24"> 
                                {Object.keys(this.props.userDataWI.capitalPurchases).length === 1 ? <Slider
                                    // tooltip
                                    controlled
                                    intl={this.props.intl}
                                    // tooltipText={this.props.intl.formatMessage(translatableStrings.capitalBuyInTooltip)}
                                    label={this.props.intl.formatMessage(translatableStrings.capitalBuyIn)}
                                    translateId={translatableStrings.capitalBuyIn.id}
                                    className={classNames("capitalBuyInSlider", {
                                        "inactive": Object.keys(this.props.userDataWI.maxPurchases).length === 0 || this.getCapitalMaxPurchase(this.state.capitalPurchaseDates[0]) === 0,
                                        // "notForThisDate": this.capitalPurchaseDateInvalid[1],
                                        "notForThisDate": this.state.capitalPurchaseDateInvalid[0],
                                        "one": Object.keys(this.props.userDataWI.capitalPurchases).length === 1
                                    })}
                                    activeSegment={0}
                                    maxVal={this.state.capitalPurchaseDates[0] ? this.getCapitalMaxPurchase(this.state.capitalPurchaseDates[0]) : 0}
                                    minVal={0}
                                    domain={[0, this.getCapitalMaxPurchase(this.state.capitalPurchaseDates[0])]}
                                    initialValue={defined(this.state.sliderValuesPurchase[0]) ? this.state.sliderValuesPurchase[0] : 0}
                                    forcedValue={defined(this.state.sliderValuesPurchase[0]) ? this.state.sliderValuesPurchase[0] : 0}
                                    sliderValueChanged={(val, initial) => {
                                        let newSliderValuesPurchase = [...this.state.sliderValuesPurchase];
                                        newSliderValuesPurchase[0] = val;
                                        this.recalculatedMaxPurchasesAt = this.props.calculateCounter + 1;
                                        this.setState({
                                            sliderValuesPurchase: newSliderValuesPurchase,
                                        }, () => {
                                            const idx = this.state.sliderValuesPurchase.length - 1;
                                            console.log('idx', idx, this.state.sliderValuesPurchase,
                                                'val', val,
                                                "date", this.state.capitalPurchaseDates[idx],
                                                this.state.capitalPurchaseDates
                                            );
                                            clearTimeout(this.updateTimeout);
                                            this.updateTimeout = setTimeout(() => {
                                                defined(this.state.sliderValuesPurchase[idx]) && this.props.setCapitalPurchase({
                                                    "amount": this.state.sliderValuesPurchase[idx] || 0,
                                                    "index": idx,
                                                    "date": this.state.capitalPurchaseDates[idx]
                                                });
                                                addToLSObject("userDataWI", "sliderValuesPurchase", this.state.sliderValuesPurchase);
                                            }, 300);
                                        })
                                    }}
                                    resetCounter={this.state.resetCounter}
                                >
                                    <div className="sliderDateInput">
                                        <input
                                            data-translate={translatableStrings.date.id}
                                            placeholder={this.props.intl.formatMessage(translatableStrings.date)}
                                            type="text"
                                            pattern="(?:(?:0[1-9]|1[0-9]|2[0-9])\.(?:0[1-9]|1[0-2])|(?:30)\.(?:(?!02)(?:0[1-9]|1[0-2]))|(?:31)\.(?:0[13578]|1[02]))\.(?:19|20)[0-9]{2}"
                                            onBlur={(e) => {
                                                console.log("teste44", this.state.sliderValuesPurchase, this.state.sliderValuesPurchase.length - 1)
                                                this.calculateCapitalPurchase(e, this.state.sliderValuesPurchase.length - 1)
                                            }
                                            }
                                            onChange={(e) => {
                                                const newDate = e.target.value;
                                                let newCapitalPurchaseDates = [...this.state.capitalPurchaseDates];
                                                newCapitalPurchaseDates[0] = newDate;
                                                let newCapitalPurchaseDateInvalid = [...this.state.capitalPurchaseDateInvalid];
                                                const idx = this.state.sliderValuesPurchase.length - 1;
                                                newCapitalPurchaseDateInvalid[idx] = false;
                                                this.capitalPurchaseDateInvalid = [...newCapitalPurchaseDateInvalid];
                                                this.setState({
                                                    capitalPurchaseDates: [...newCapitalPurchaseDates],
                                                    capitalPurchaseDateInvalid: [...newCapitalPurchaseDateInvalid]
                                                });
                                            }}
                                            maxLength={10}
                                            value={this.state.capitalPurchaseDates[0] ? inputFormatDate(this.state.capitalPurchaseDates[0]) : ""}
                                            className={classNames("slider_val text-left", {
                                                // "invalid": this.capitalPurchaseDateInvalid[this.state.sliderValuesPurchase.length - 1]
                                                "invalid": this.state.capitalPurchaseDateInvalid[this.state.sliderValuesPurchase.length - 1]
                                            })}
                                        />
                                    </div>
                                </Slider> :
                                    <div className="d-flex flex-wrap w-100">
                                        <div className="slider_header">
                                            <FormattedMessageCustom id={translatableStrings.capitalBuyIn.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyIn)}>
                                                <p className="sliderLikeLabel">
                                                </p>
                                            </FormattedMessageCustom>
                                        </div>
                                        {this.props.userDataWI.capitalPurchases[Object.keys(this.props.userDataWI.capitalPurchases)[0]].date && <Button
                                            className={classNames("button openCapitalWithdrawalButtonChange", {
                                                "invalidPresent": this.state.capitalPurchaseDateInvalid.includes(true)
                                            }
                                            )}
                                            onClick={() => this.props.openModal("capitalbuyinedit", true)}
                                            buttonType={EButtonType.BasicLink}
                                        >
                                            <FormattedMessageCustom id={translatableStrings.change.id} text={this.props.intl.formatMessage(translatableStrings.change)} />
                                        </Button>}
                                    </div>
                                }
                                {/* List of kapitaleinkauf  */}
                                <div className={classNames("scrollable", {
                                    "rightPadding": this.state.showAll
                                })}>
                                    {Object.keys(this.props.userDataWI.capitalPurchases).length > 1 && Object.keys(this.props.userDataWI.capitalPurchases).map((key, idx) => {
                                        // console.log('forEach', key, this.props.userDataWI.capitalPurchases[key]);
                                        if (idx < 3 || this.state.showAll) {
                                            return (idx < 2 || this.state.showAll) ? (
                                                <div className={classNames("d-flex capitalPurchaseRow", {
                                                    "invalid": this.capitalPurchaseDateInvalid[idx]
                                                    // "invalid": this.state.capitalPurchaseDateInvalid[idx]
                                                })}>
                                                    <div>{dateFormat(this.props.userDataWI.capitalPurchases[key]["date"], "yyyymmdd")}
                                                        {this.capitalPurchaseDateInvalid[idx] ? <Tooltip
                                                            mouseEnterDelay={0}
                                                            mouseLeaveDelay={0}
                                                            destroyTooltipOnHide={true}
                                                            placement={'top'}
                                                            trigger={'click'}
                                                            overlay={
                                                                <FormattedMessageCustom id={translatableStrings.capitalBuyInInvalidExplanation.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInInvalidExplanation)}>
                                                                    <p className="invalidPurchaseDate">
                                                                    </p>
                                                                </FormattedMessageCustom>
                                                            }
                                                        >
                                                            <HelpIcon style={{ zIndex: 9999, width: '18px', marginLeft: '8px' }} className="pointer" />
                                                        </Tooltip> : null}
                                                    </div>
                                                    <div>{this.props.userDataWI.capitalPurchases[key]["amount"] ? "CHF " + formatNumber(this.props.userDataWI.capitalPurchases[key]["amount"]) : null}</div>
                                                </div>
                                            ) : (
                                                <div className={classNames("capitalPurchaseRow", {
                                                    "invalid": this.capitalPurchaseDateInvalid[idx]
                                                    // "invalid": this.state.capitalPurchaseDateInvalid[idx]
                                                })}>
                                                    <div>{Object.keys(this.props.userDataWI.capitalPurchases).length - 2} <FormattedMessageCustom id={translatableStrings.moreBuyIns.id} text={this.props.intl.formatMessage(translatableStrings.moreBuyIns)} />
                                                        {this.state.capitalPurchaseDateInvalid.slice(2).includes(true) ? <Tooltip
                                                            mouseEnterDelay={0}
                                                            mouseLeaveDelay={0}
                                                            destroyTooltipOnHide={true}
                                                            placement={'top'}
                                                            trigger={'click'}
                                                            overlay={
                                                                <FormattedMessageCustom id={translatableStrings.capitalBuyInInvalidExplanation.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInInvalidExplanation)}>
                                                                </FormattedMessageCustom>
                                                            }
                                                        >
                                                            <HelpIcon style={{ zIndex: 9999, width: '18px', marginLeft: '8px' }} className="pointer" />
                                                        </Tooltip> : null}
                                                    </div>
                                                    <Button
                                                        className="button openCapitalWithdrawalButtonChange"
                                                        onClick={() => {
                                                            this.props.openModal("showAllBuyIns", true);
                                                            // this.setState({
                                                            //     showAll: !this.state.showAll
                                                            // })
                                                        }}
                                                        buttonType={EButtonType.BasicLink}
                                                    >
                                                       <FormattedMessageCustom id={translatableStrings.showAll.id} text={this.props.intl.formatMessage(translatableStrings.showAll)} />
                                                    </Button>
                                                </div>
                                            )
                                        } else {
                                            return null
                                        }
                                    })}
                                </div>

                                {/* Button create new kapitaleinkauf*/}
                                <div className={Object.keys(this.props.userDataWI.capitalPurchases).length < 2 && "p-md-24"}>
                                    {this.state.capitalPurchaseDateInvalid[this.state.sliderValuesPurchase.length - 1] && !this.props.userDataWI.capitalPurchases[1] &&
                                        <p className="invalidPurchaseDate">
                                            {this.state.capitalPurchaseDateInvalidFromDrag ?
                                                <FormattedMessageCustom id={translatableStrings.wrongDateForCapitalBuyIn.id} text={this.props.intl.formatMessage(translatableStrings.wrongDateForCapitalBuyIn)}>
                                                </FormattedMessageCustom>
                                                : <FormattedMessageCustom id={translatableStrings.capitalBuyInNotPossible.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInNotPossible)}>
                                                </FormattedMessageCustom>
                                            }
                                            {<Tooltip
                                                mouseEnterDelay={0}
                                                mouseLeaveDelay={0}
                                                destroyTooltipOnHide={true}
                                                placement={'top'}
                                                trigger={'click'}
                                                overlay={
                                                    <FormattedMessageCustom id={translatableStrings.capitalBuyInInvalidExplanation.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInInvalidExplanation)}>
                                                        <p className="invalidPurchaseDate">
                                                        </p>
                                                    </FormattedMessageCustom>
                                                }
                                            >
                                                <HelpIcon style={{ zIndex: 9999, width: '18px', marginLeft: '8px' }} className="pointer" />
                                            </Tooltip>}
                                        </p>
                                    }
                                    {this.state.sliderValuesPurchase.length > 0 && this.state.sliderValuesPurchase[0] && this.state.sliderValuesPurchase.length < 6 ? <Button
                                        className="button openCapitalWithdrawalButton"
                                        onClick={() => {
                                            this.setState({
                                                capitalPurchaseDatesCandidate: [...this.state.capitalPurchaseDates],
                                                sliderValuesPurchaseCandidate: [...this.state.sliderValuesPurchase],
                                            }, () => {
                                            })
                                            this.props.openModal("capitalbuyin", true);
                                        }}
                                        buttonType={EButtonType.BasicLink}
                                    >
                                        <FormattedMessageCustom id={translatableStrings.addCapitalBuyIn.id} text={this.props.intl.formatMessage(translatableStrings.addCapitalBuyIn)}>
                                        </FormattedMessageCustom>
                                    </Button> : null}
                                </div>
                            </div>
                            <div className="modal-buttons">
                                <Button
                                    isDisabled={Object.keys(this.props.userDataWI.maxPurchases).length === 0 || this.getCapitalMaxPurchase(this.state.capitalPurchaseDates[0]) === 0}
                                    className="button reflect-button"
                                    onClick={() => {
                                        this.props.setSincronizeMaxPurchases();
                                        this.props.closeModal();
                                    }}
                                    buttonType={EButtonType.PillSmall}
                                >
                                    <FormattedMessageCustom id={translatableStrings.ready.id} text={this.props.intl.formatMessage(translatableStrings.ready)} />
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            )
        } else if (modalContent === "scheidung") {
            return (
                <Scheidung
                    closeModal={this.props.closeModal}
                    intl={this.props.intl}
                    getCapitalMaxPurchase={this.getCapitalMaxPurchase}
                    userDataWI={this.props.userDataWI}
                    capitalPurchaseDates={this.state.capitalPurchaseDates}
                    divorcePurchase={this.divorcePurchase}
                    currentSituation={this.props.currentSituation}
                    setDivorcePostings={this.props.setDivorcePostings}
                />
            )
        }
    }

    getCapitalMaxPurchase = (date) => {
        const apiDate = parseToApiDate(date && date.length < 10 ? dateFormat(date) : date, "ddmmyyyy");
        const key = apiDate;
        console.log('key', key,
            'getCapitalMax: ', this.props.userDataWI.maxPurchases[key]);
        return this.props.userDataWI.maxPurchases[key] ? this.props.userDataWI.maxPurchases[key]['max'] : 0;
    }

    calculateCapitalPurchase = (e, idx?) => {
        const val = e.target.value;

        if (isDate(val)) {

            const payroll = {};
            this.props.userDataWI.Lohnsumme &&
                (payroll["insuredSalary"] = parseFloat(this.props.userDataWI.Lohnsumme));
            this.props.userDataWI.Lohnsumme &&
                (payroll["grossSalary"] = parseFloat(this.props.userDataWI.Lohnsumme));
            this.props.userDataWI.Beschaftigungsgrad &&
                (payroll["activityRate"] = parseFloat(
                    this.props.userDataWI.Beschaftigungsgrad
                ));
            this.props.userDataWI.payrollClass &&
                (payroll["payrollClass"] = parseInt(this.props.userDataWI.payrollClass));

            // build payrolls on payroll object
            const payrolls = [
                {
                    activityRate: (payroll as any)?.activityRate,
                    grossSalary: (payroll as any)?.grossSalary,
                    validFrom: "2020-01-01T12:00:00.000Z", // First day of the year always
                },
            ];

            let purchases = [];

            if (!!this.props.userDataWI.maxPurchases) {
                Object.keys(this.props.userDataWI.maxPurchases).forEach((item, index) => {
                    this.props.userDataWI.capitalPurchases[index] && purchases.push({
                        date: item,
                        value: this.props.userDataWI.capitalPurchases[index].amount
                    })
                })
            }

            const acturialValuesSet = {
                totals: [{}],
                details: [],
            };
            this.props.userDataWI["AusgestelltAm"] &&
                (acturialValuesSet["totals"][0]["referenceDate"] =
                    this.props.userDataWI["AusgestelltAm"]);
            this.props.userDataWI["Sparguthaben"] &&
                (acturialValuesSet["totals"][0]["basicCapital"] = parseFloat(
                    this.props.userDataWI["Sparguthaben"]
                ));
            this.props.userDataWI["VPKontoAmount"] &&
                (acturialValuesSet["totals"][0]["earlyRetirementCapital"] = parseFloat(
                    this.props.userDataWI["VPKontoAmount"]
                ));

            Object.keys(this.props.userDataWI["capitalPurchases"]).length &&
                Object.keys(this.props.userDataWI["capitalPurchases"]).forEach((key) => {
                    this.props.userDataWI["capitalPurchases"][key].date &&
                        acturialValuesSet["details"].push({
                            referenceDate: this.props.userDataWI["capitalPurchases"][key].date,
                            totalCapital: this.props.userDataWI["capitalPurchases"][key].amount,
                        });
                });
            // this.props.userDataWI["ahvPensionAmount"] && (acturialValuesSet["earlyRetirementCapital"] = this.props.userDataWI["ahvPensionAmount"]);

            // build transactions on acturialValues
            const transactions = [];
            acturialValuesSet?.totals?.map((total: any) => {
                transactions.push({
                    creationDate: total.referenceDate,
                    transactionDate: total.referenceDate,
                    interestDate: total.referenceDate,
                    amount: total.basicCapital,
                    transactionType: 16,
                });
            });

            const apiDate = parseToApiDate(val, "ddmmyyyy");
            const mergedPayload = {
                "data": {
                    "tenantName": this.props.userDataWI.tenantName || "BVG",
                    "purchaseDate": apiDate,
                    "person": {
                        birthDate: this.props.userDataWI.Geburtsdatum,
                        sex: this.props.userDataWI.Geschlecht === "weiblich" ? 2 : 1,
                        insuranceRelationships: [
                            {
                                transactions: transactions,
                                employment: [
                                    {
                                        insurancePlans: [
                                            {
                                                planCode: "BVG",
                                            },
                                        ],
                                        payrolls: payrolls,
                                    },
                                ],
                            },
                        ],
                    },
                    "RetirementSteps": (window as any).retirementSteps,
                    "simulationData": {
                        purchases: purchases,
                    },
                }
            };
            console.log('apiDate', apiDate, 'payload: ', mergedPayload);
            let newCapitalPurchaseDateInvalid = [...this.capitalPurchaseDateInvalid];
            // let newCapitalPurchaseDateInvalid = [...this.state.capitalPurchaseDateInvalid];

            this.props.calculateMaxPurchase(mergedPayload).then((res) => {
                console.log('calculated max purchase res', res, this.props.userDataWI.maxPurchases[apiDate]);
                if (res.data.value === 0) {
                    newCapitalPurchaseDateInvalid[idx] = true;
                    this.setState({
                        capitalPurchaseDateInvalid: [...newCapitalPurchaseDateInvalid]
                    })
                    this.capitalPurchaseDateInvalid = [...newCapitalPurchaseDateInvalid];
                } else {
                    newCapitalPurchaseDateInvalid[idx] = false;
                    this.setState({
                        capitalPurchaseDateInvalid: [...newCapitalPurchaseDateInvalid]
                    })
                    this.capitalPurchaseDateInvalid = [...newCapitalPurchaseDateInvalid];
                }
            }).catch(() => {
                newCapitalPurchaseDateInvalid[idx] = true;
                this.capitalPurchaseDateInvalid = [...newCapitalPurchaseDateInvalid];
                this.setState({
                    capitalPurchaseDateInvalid: [...newCapitalPurchaseDateInvalid]
                })
            });
        }
    }

    calculateCardValueLabel = (stateCardValue: string) => {
        return this.state[stateCardValue].length > 1 ?
            formatNumber(this.state[stateCardValue].reduce(function (prev, cur) {
                return prev + cur;
            })) : this.state[stateCardValue][0] ? formatNumber(this.state[stateCardValue][0]) : 0
    }

    render() {
        const incomePeriodSet = this.props.userDataWI.lastCalculateResponse.incomePeriodSet;

        return (
            <div className={classNames("controls crossFadeOut", {
                "mobileModal": this.props.mobileModal,
                "bridgingImpossible": !(this.props.userDataWI.settings && this.props.userDataWI.settings.isBridingPossible)
            })}>
                {this.props.modalIsOpen === true ? this.props.renderModalWithChildren(this.renderModal(this.props.modalContent), true) : null}

                {this.props.wrapInTooltip(
                    <div key="add2" className="w-100 addTeilContainer">
                        <Button
                            className="button text-center strokeGrey font-12 text-uppercase font-600 addTeilButton"
                            onClick={() => this.props.goToRenten()}
                            isDisabled={!this.props.userDataWI.partialPensionLimits.isAdditionalStepPossible}
                            buttonType={EButtonType.InvertedPill}
                        >
                            <span style={{
                                marginRight: 12
                            }}>
                                <IconPlus />
                            </span>
                            <FormattedMessageCustom id={translatableStrings.partialPension.id} text={this.props.intl.formatMessage(translatableStrings.partialPension)} />
                        </Button>
                    </div>,
                    this.props.intl.formatMessage(translatableStrings[this.props.userDataWI.tenantName.toUpperCase() == 'PKAR' ? 'teilpensionNotPossiblePKAR' : 'teilpensionNotPossible']),
                    !this.props.userDataWI.partialPensionLimits.isAdditionalStepPossible, "bottom"
                )}

                <div className="topControls">
                    <FormattedMessageCustom id={translatableStrings.variables.id} text={this.props.intl.formatMessage(translatableStrings.variables)}>
                        <p className="text-left font-16" />
                    </FormattedMessageCustom>
                </div>

                <div className="sliders">

                    {/* <Card>
                        <Dashboard
                            text={`CHF ${formatNumber(this.props.userDataWI.divorcePostings ? this.props.userDataWI.divorcePostings[0].value : 0)}`}
                            title={"Scheidung"}
                            tooltip
                            tooltipText={this.props.intl.formatMessage(translatableStrings.scheidungTooltip)}
                            icon={<ScheidungIcon/>}
                            onClick={() => this.props.openModal('scheidung', true)}>
                        </Dashboard>
                    </Card> */}

                    <Card>
                        <Dashboard
                            text={`CHF ${this.calculateCardValueLabel("sliderValuesPurchase")}`}
                            title={"Kapitaleinkauf"}
                            tooltip
                            tooltipText={this.props.intl.formatMessage(translatableStrings.capitalBuyInTooltip)}
                            translateId={translatableStrings.capitalBuyInTooltip.id}
                            icon={<ChartIcon />}
                            onClick={() => this.props.openModal('startCapitalBuyIn', true)}>
                        </Dashboard>
                    </Card>


                    {this.props.isBridingPossible && (incomePeriodSet.length > 0 && !!incomePeriodSet.find((obj) => obj.limitSet.isBridingPensionPossible)) ? <Card >
                        <Dashboard
                            text={`CHF ${this.props.monthlyOrYearly === "monthlyIncome" ? formatNumber(this.props.userDataWI.lastCalculateResponse.totalBridingPension / 12) : formatNumber(this.props.userDataWI.lastCalculateResponse.totalBridingPension)}`}
                            title={"Überbrückungsrente"}
                            icon={<CaseIcon />}
                            tooltip
                            tooltipText={this.props.intl.formatMessage(translatableStrings.bridgingPensionTooltip)}
                            translateId={translatableStrings.bridgingPensionTooltip.id}
                            onClick={() => this.props.openModal('überbrückungsrente', true)}

                        >
                        </Dashboard>
                    </Card> : null}

                    <Card>
                        <Dashboard
                            text={`CHF ${this.calculateCardValueLabel("sliderValuesWithdrawalCandidate")}`}
                            title={"Kapitalbezug"}
                            tooltip
                            tooltipText={this.props.intl.formatMessage(translatableStrings.capitalWithdrawalTooltip)}
                            translateId={translatableStrings.capitalWithdrawal.id}
                            icon={<ChartIcon />}
                            onClick={() => this.props.openModal('capitalwithdrawal', true)}
                        >


                        </Dashboard>
                    </Card>

                    {/*    <Slider*/}
                    {/*        controlled*/}
                    {/*        tooltip*/}
                    {/*        intl={this.props.intl}*/}
                    {/*        tooltipText={this.props.intl.formatMessage(translatableStrings.bridgingPensionTooltip)}*/}
                    {/*        label={this.props.intl.formatMessage(translatableStrings.bridgingPension)}*/}
                    {/*        translateId={translatableStrings.bridgingPension.id}*/}
                    {/*        maxVal={[this.props.bridgingPensionAmountMax || 0]}*/}
                    {/*        minVal={this.props.bridgingPensionAmountMin || 0}*/}
                    {/*        maxPercentage={this.props.capitalWithdrawalPercentageMax}*/}
                    {/*        initialValue={this.props.monthlyOrYearly === "annualIncome" ? this.props.userDataWI.bridgingPension : this.props.userDataWI.bridgingPension / 12}*/}
                    {/*        className={classNames('bridgingPensionSlider', {*/}
                    {/*            "inactiveBridging": this.props.isEarlyPensionPresent === false*/}
                    {/*        })}*/}
                    {/*        forcedValue={this.state.sliderValueBridgingPension}*/}
                    {/*        domain={[this.props.bridgingPensionAmountMin, this.props.bridgingPensionAmountMax]}*/}
                    {/*        sliderValueChanged={(val, initial) => {*/}
                    {/*            this.setState({*/}
                    {/*                sliderValueBridgingPension: val,*/}
                    {/*            }, () => {*/}
                    {/*                if (this.props.userDataWI.bridgingPension !== this.state.sliderValueBridgingPension) {*/}
                    {/*                    clearTimeout(this.updateTimeout);*/}
                    {/*                    document.body.classList.add('dragStartedFake');*/}
                    {/*                    this.updateTimeout = setTimeout(() => {*/}
                    {/*                        defined(this.state.sliderValueBridgingPension) && this.props.setBridgingPension(this.props.monthlyOrYearly === "annualIncome" ? this.state.sliderValueBridgingPension : this.state.sliderValueBridgingPension * 12);*/}
                    {/*                    }, 300);*/}
                    {/*                }*/}
                    {/*            })*/}
                    {/*        }}*/}
                    {/*        resetCounter={this.state.resetCounter}*/}
                    {/*    />*/}

                    {/*{this.props.retirementSteps.length > 1 ?*/}
                    {/*    <div className="sliderWrapper d-flex flex-row flex-wrap">*/}
                    {/*        <div className="d-flex flex-wrap slider_header mb-0 p-0">*/}
                    {/*            <FormattedMessageCustom id={translatableStrings.capitalWithdrawal.id} text={this.props.intl.formatMessage(translatableStrings.capitalWithdrawal)} >*/}
                    {/*                <p className="sliderLikeLabel">*/}
                    {/*                </p>*/}
                    {/*            </FormattedMessageCustom>*/}
                    {/*        </div>*/}

                    {/*        {this.props.userDataWI.RetirementSteps.length > 1 ?*/}
                    {/*            <Button*/}
                    {/*                className="button openCapitalWithdrawalButtonChange"*/}
                    {/*                onClick={() => this.props.openModal("capitalwithdrawal", true)}*/}
                    {/*                buttonType={EButtonType.BasicLink}>*/}
                    {/*                <FormattedMessageCustom id={translatableStrings.change.id} text={this.props.intl.formatMessage(translatableStrings.change)} />*/}
                    {/*            </Button>*/}
                    {/*        : null}*/}

                    {/*        <div className={classNames("scrollable", { "d-none": Object.keys(this.props.userDataWI.capitalWithdrawals).length === 0 })}>*/}

                    {/*            {Object.keys(this.props.userDataWI.capitalWithdrawals).length > 0 && this.props.calculations.length > 0 ?*/}
                    {/*                this.props.calculations.filter((stepObj) => Boolean(stepObj.linkedRetirementStep)).map((stepObj, idx) => {*/}
                    {/*                    if (stepObj.linkedRetirementStep && stepObj.linkedRetirementStep.retirementDate) {*/}
                    {/*                        if (idx < 3) {*/}
                    {/*                            return (idx < 2) ? (*/}
                    {/*                               <div key={`purchaseRow${idx}`} className="d-flex capitalPurchaseRow">*/}
                    {/*                                    <div>*/}
                    {/*                                        {parseDate(stepObj.linkedRetirementStep.retirementDate, "secsToDDMMYYYY") + ' '}*/}
                    {/*                                        ({*/}
                    {/*                                            stepObj.linkedRetirementStep.retirementPercentage === 100 ? <FormattedMessageCustom key={translatableStrings.fullPensionRetirement.id} id={translatableStrings.fullPensionRetirement.id} text={this.props.intl.formatMessage(translatableStrings.fullPensionRetirement)} /> :*/}
                    {/*                                                [<FormattedMessageCustom id={translatableStrings.partialPensionRetirement.id} key={translatableStrings.partialPensionRetirement.id} text={this.props.intl.formatMessage(translatableStrings.partialPensionRetirement)} />,*/}
                    {/*                                                ` ${stepObj.linkedRetirementStep.retirementPercentage}%`]*/}
                    {/*                                        })*/}
                    {/*                                    </div>*/}
                    {/*                                    <div>{defined(stepObj.linkedRetirementStep.capitalWidthdrawalAmount) ? "CHF " + formatNumber(stepObj.linkedRetirementStep.capitalWidthdrawalAmount) : null}</div>*/}
                    {/*                                </div>*/}
                    {/*                            ) : (*/}
                    {/*                                    <div key={`purchaseRow2${idx}`} className={classNames("d-flex capitalPurchaseRow", {*/}
                    {/*                                        // "invalid": this.state.capitalPurchaseDateInvalid[idx]*/}
                    {/*                                    })}>*/}
                    {/*                                        <div>{this.props.userDataWI.RetirementSteps.length - 2} <FormattedMessageCustom id={translatableStrings.moreWithdrawals.id} text={this.props.intl.formatMessage(translatableStrings.moreWithdrawals)} /></div>*/}
                    {/*                                        <Button*/}
                    {/*                                            className="button openCapitalWithdrawalButtonChange"*/}
                    {/*                                            onClick={() => this.props.openModal('showallwithdrawals', true)}*/}
                    {/*                                            buttonType={EButtonType.BasicLink}>*/}
                    {/*                                            <FormattedMessageCustom id={translatableStrings.showAll.id} text={this.props.intl.formatMessage(translatableStrings.showAll)} />*/}
                    {/*                                            /!* {this.state.showAllWithdrawals ? <FormattedMessageCustom id={translatableStrings.hide.id} text={this.props.intl.formatMessage(translatableStrings.hide)} /> : <FormattedMessageCustom id={translatableStrings.showAll.id} text={this.props.intl.formatMessage(translatableStrings.showAll)} />} *!/*/}
                    {/*                                        </Button>*/}
                    {/*                                    </div>*/}
                    {/*                                )*/}
                    {/*                        }*/}
                    {/*                    } else {*/}
                    {/*                        console.log('will return null 1');*/}
                    {/*                        return null*/}
                    {/*                    }*/}
                    {/*                }) : null}*/}
                    {/*        </div>*/}

                    {/*        /!* {this.props.userDataWI.RetirementSteps.length > 1 && this.state.sliderValuesWithdrawal.filter((el) => el > 0).length === 0 &&*/}
                    {/*            // this.props.userDataWI.capitalWithdrawals[Object.keys(this.props.userDataWI.capitalWithdrawals)[0]]['amount'] && */}
                    {/*            <div className="mt-0">*/}
                    {/*                <Button*/}
                    {/*                    className="button openCapitalWithdrawalButton"*/}
                    {/*                    onClick={() => this.props.openModal("capitalwithdrawal", true)}*/}
                    {/*                    buttonType={EButtonType.BasicLink}*/}
                    {/*                >*/}
                    {/*                    <FormattedMessageCustom id={translatableStrings.addCapitalWithdrawal.id} text={this.props.intl.formatMessage(translatableStrings.addCapitalWithdrawal)} />*/}
                    {/*                </Button>*/}
                    {/*            </div>} *!/*/}
                    {/*    </div>*/}
                    {/*    :*/}
                    {/*    defined(this.props.capitalWidthdrawalAmountMax) && <Slider*/}
                    {/*        controlled*/}
                    {/*        tooltip*/}
                    {/*        intl={this.props.intl}*/}
                    {/*        tooltipText={this.props.intl.formatMessage(translatableStrings.capitalWithdrawalTooltip)}*/}
                    {/*        translateId={translatableStrings.capitalWithdrawal.id}*/}
                    {/*        label={this.props.intl.formatMessage(translatableStrings.capitalWithdrawal)}*/}
                    {/*        showPercInput*/}
                    {/*        maxVal={this.props.capitalWidthdrawalAmountMax}*/}
                    {/*        maxPercentage={this.props.capitalWithdrawalPercentageMax}*/}
                    {/*        // activeSegment={0}*/}
                    {/*        minVal={this.props.capitalWidthdrawalAmountMin}*/}
                    {/*        initialValue={this.state.sliderValuesWithdrawal[0]}*/}
                    {/*        forcedValue={this.state.sliderValuesWithdrawal[0]}*/}
                    {/*        className={classNames("middle capitalWithdrawalSlider", {*/}
                    {/*            "inactive": this.props.capitalWidthdrawalAmountMax === 0 || !defined(this.props.capitalWithdrawalPercentageMax)*/}
                    {/*        })}*/}
                    {/*        domain={[this.props.capitalWidthdrawalAmountMin, this.props.capitalWidthdrawalAmountMax]}*/}
                    {/*        sliderValueChanged={(val, initial) => {*/}
                    {/*            const newSliderValues = [...this.state.sliderValuesWithdrawal];*/}
                    {/*            newSliderValues[0] = val;*/}
                    {/*            console.log('newSliderValues2', newSliderValues)*/}
                    {/*            this.setState({*/}
                    {/*                sliderValuesWithdrawal: newSliderValues,*/}
                    {/*            }, () => {*/}
                    {/*                const idx = this.state.sliderValuesWithdrawal.length - 1;*/}
                    {/*                console.log('idx', idx, this.state.sliderValuesWithdrawal,*/}
                    {/*                    'val', val);*/}
                    {/*                if (this.props.userDataWI.capitalWithdrawals[idx]['amount'] !== this.state.sliderValuesWithdrawal[idx]) {*/}
                    {/*                    clearTimeout(this.updateTimeout);*/}
                    {/*                    // if (this.props.calculateCounterTrigger > 0) {*/}
                    {/*                    document.body.classList.add('dragStartedFake');*/}
                    {/*                    // }*/}
                    {/*                    this.updateTimeout = setTimeout(() => {*/}
                    {/*                        defined(this.state.sliderValuesWithdrawal[idx]) && this.props.setCapitalWithdrawal({*/}
                    {/*                            "amount": this.state.sliderValuesWithdrawal[idx],*/}
                    {/*                            "index": idx,*/}
                    {/*                            "percentage": (this.state.sliderValuesWithdrawal[idx] / this.props.capitalWidthdrawalAmountMax * 100).toFixed(2)*/}
                    {/*                        });*/}
                    {/*                    }, 300);*/}
                    {/*                    // this.props.calculateDebounced();*/}

                    {/*                }*/}
                    {/*                // addToLSObject("userDataWI", "sliderValuesWithdrawal", this.state.sliderValuesWithdrawal);*/}
                    {/*            })*/}
                    {/*        }}*/}
                    {/*        resetCounter={this.state.resetCounter}*/}
                    {/*    />*/}
                    {/*}*/}

                    {/*<div className="sliderWrapperParent d-flex flex-wrap p-md-24">*/}
                    {/*    {Object.keys(this.props.userDataWI.capitalPurchases).length === 1 ? <Slider*/}
                    {/*        tooltip*/}
                    {/*        controlled*/}
                    {/*        intl={this.props.intl}*/}
                    {/*        tooltipText={this.props.intl.formatMessage(translatableStrings.capitalBuyInTooltip)}*/}
                    {/*        label={this.props.intl.formatMessage(translatableStrings.capitalBuyIn)}*/}
                    {/*        translateId={translatableStrings.capitalBuyIn.id}*/}
                    {/*        className={classNames("capitalBuyInSlider", {*/}
                    {/*            "inactive": Object.keys(this.props.userDataWI.maxPurchases).length === 0 || this.getCapitalMaxPurchase(this.state.capitalPurchaseDates[0]) === 0,*/}
                    {/*            // "notForThisDate": this.capitalPurchaseDateInvalid[1],*/}
                    {/*            "notForThisDate": this.state.capitalPurchaseDateInvalid[0],*/}
                    {/*            "one": Object.keys(this.props.userDataWI.capitalPurchases).length === 1*/}
                    {/*        })}*/}
                    {/*        activeSegment={0}*/}
                    {/*        maxVal={this.state.capitalPurchaseDates[0] ? this.getCapitalMaxPurchase(this.state.capitalPurchaseDates[0]) : 0}*/}
                    {/*        minVal={0}*/}
                    {/*        domain={[0, this.getCapitalMaxPurchase(this.state.capitalPurchaseDates[0])]}*/}
                    {/*        initialValue={defined(this.state.sliderValuesPurchase[0]) ? this.state.sliderValuesPurchase[0] : 0}*/}
                    {/*        forcedValue={defined(this.state.sliderValuesPurchase[0]) ? this.state.sliderValuesPurchase[0] : 0}*/}
                    {/*        sliderValueChanged={(val, initial) => {*/}
                    {/*            let newSliderValuesPurchase = [...this.state.sliderValuesPurchase];*/}
                    {/*            newSliderValuesPurchase[0] = val;*/}
                    {/*            this.recalculatedMaxPurchasesAt = this.props.calculateCounter + 1;*/}
                    {/*            this.setState({*/}
                    {/*                sliderValuesPurchase: newSliderValuesPurchase,*/}
                    {/*            }, () => {*/}
                    {/*                const idx = this.state.sliderValuesPurchase.length - 1;*/}
                    {/*                clearTimeout(this.updateTimeout);*/}
                    {/*                this.updateTimeout = setTimeout(() => {*/}
                    {/*                    defined(this.state.sliderValuesPurchase[idx]) && this.props.setCapitalPurchase({*/}
                    {/*                        "amount": this.state.sliderValuesPurchase[idx] || 0,*/}
                    {/*                        "index": idx,*/}
                    {/*                        "date": this.state.capitalPurchaseDates[idx]*/}
                    {/*                    });*/}
                    {/*                    addToLSObject("userDataWI", "sliderValuesPurchase", this.state.sliderValuesPurchase);*/}
                    {/*                }, 300);*/}
                    {/*            })*/}
                    {/*        }}*/}
                    {/*        resetCounter={this.state.resetCounter}>*/}
                    {/*        <div className="sliderDateInput">*/}
                    {/*            <input*/}
                    {/*                data-translate={translatableStrings.date.id}*/}
                    {/*                placeholder={this.props.intl.formatMessage(translatableStrings.date)}*/}
                    {/*                type="text"*/}
                    {/*                pattern="(?:(?:0[1-9]|1[0-9]|2[0-9])\.(?:0[1-9]|1[0-2])|(?:30)\.(?:(?!02)(?:0[1-9]|1[0-2]))|(?:31)\.(?:0[13578]|1[02]))\.(?:19|20)[0-9]{2}"*/}
                    {/*                onBlur={(e) => this.calculateCapitalPurchase(e, this.state.sliderValuesPurchase.length - 1)}*/}
                    {/*                onChange={(e) => {*/}
                    {/*                    const newDate = e.target.value;*/}
                    {/*                    let newCapitalPurchaseDates = [...this.state.capitalPurchaseDates];*/}
                    {/*                    newCapitalPurchaseDates[0] = newDate;*/}
                    {/*                    let newCapitalPurchaseDateInvalid = [...this.state.capitalPurchaseDateInvalid];*/}
                    {/*                    const idx = this.state.sliderValuesPurchase.length - 1;*/}
                    {/*                    newCapitalPurchaseDateInvalid[idx] = false;*/}
                    {/*                    this.capitalPurchaseDateInvalid = [...newCapitalPurchaseDateInvalid];*/}
                    {/*                    this.setState({*/}
                    {/*                        capitalPurchaseDates: [...newCapitalPurchaseDates],*/}
                    {/*                        capitalPurchaseDateInvalid: [...newCapitalPurchaseDateInvalid]*/}
                    {/*                    });*/}
                    {/*                }}*/}
                    {/*                value={this.state.capitalPurchaseDates[0] || ""}*/}
                    {/*                className={classNames("slider_val text-left", {"invalid": this.state.capitalPurchaseDateInvalid[this.state.sliderValuesPurchase.length - 1]})}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </Slider> :*/}
                    {/*        <div className="d-flex flex-wrap w-100">*/}
                    {/*            <div className="slider_header">*/}
                    {/*                <FormattedMessageCustom id={translatableStrings.capitalBuyIn.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyIn)}>*/}
                    {/*                    <p className="sliderLikeLabel">*/}
                    {/*                    </p>*/}
                    {/*                </FormattedMessageCustom>*/}
                    {/*            </div>*/}
                    {/*            {this.props.userDataWI.capitalPurchases[Object.keys(this.props.userDataWI.capitalPurchases)[0]].date && */}
                    {/*            <Button*/}
                    {/*                className={classNames("button openCapitalWithdrawalButtonChange", { "invalidPresent": this.state.capitalPurchaseDateInvalid.includes(true)})}*/}
                    {/*                onClick={() => this.props.openModal("capitalbuyinedit", true)}*/}
                    {/*                buttonType={EButtonType.BasicLink}>*/}
                    {/*                <FormattedMessageCustom id={translatableStrings.change.id} text={this.props.intl.formatMessage(translatableStrings.change)} />*/}
                    {/*            </Button>}*/}
                    {/*        </div>*/}
                    {/*    }*/}

                    {/*    <div className={classNames("scrollable", {"rightPadding": this.state.showAll})}>*/}
                    {/*        {Object.keys(this.props.userDataWI.capitalPurchases).length > 1 && Object.keys(this.props.userDataWI.capitalPurchases).map((key, idx) => {*/}
                    {/*            // console.log('forEach', key, this.props.userDataWI.capitalPurchases[key]);*/}
                    {/*            if (idx < 3 || this.state.showAll) {*/}
                    {/*                return (idx < 2 || this.state.showAll) ? (*/}
                    {/*                    <div className={classNames("d-flex capitalPurchaseRow", {*/}
                    {/*                        "invalid": this.capitalPurchaseDateInvalid[idx]*/}
                    {/*                        // "invalid": this.state.capitalPurchaseDateInvalid[idx]*/}
                    {/*                    })}>*/}
                    {/*                        <div>{parseDate(this.props.userDataWI.capitalPurchases[key]["date"], "secsToDDMMYYYY")}*/}
                    {/*                            /!* {this.state.capitalPurchaseDateInvalid[idx] ? <Tooltip *!/*/}
                    {/*                            {this.capitalPurchaseDateInvalid[idx] ? <Tooltip*/}
                    {/*                                mouseEnterDelay={0}*/}
                    {/*                                mouseLeaveDelay={0}*/}
                    {/*                                destroyTooltipOnHide={true}*/}
                    {/*                                placement={'top'}*/}
                    {/*                                trigger={'click'}*/}
                    {/*                                overlay={*/}
                    {/*                                    <FormattedMessageCustom id={translatableStrings.capitalBuyInInvalidExplanation.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInInvalidExplanation)}>*/}
                    {/*                                        <p className="invalidPurchaseDate" />*/}
                    {/*                                    </FormattedMessageCustom>*/}
                    {/*                                }>*/}
                    {/*                                <HelpIcon style={{ zIndex: 9999, width: '18px', marginLeft: '8px' }} className="pointer" />*/}
                    {/*                            </Tooltip> : null}*/}
                    {/*                        </div>*/}
                    {/*                        <div>{this.props.userDataWI.capitalPurchases[key]["amount"] ? "CHF " + formatNumber(this.props.userDataWI.capitalPurchases[key]["amount"]) : null}</div>*/}
                    {/*                    </div>*/}
                    {/*                ) : (*/}
                    {/*                        <div className={classNames("d-flex capitalPurchaseRow", {*/}
                    {/*                            "invalid": this.capitalPurchaseDateInvalid[idx]*/}
                    {/*                            // "invalid": this.state.capitalPurchaseDateInvalid[idx]*/}
                    {/*                        })}>*/}
                    {/*                            <div>{Object.keys(this.props.userDataWI.capitalPurchases).length - 2} <FormattedMessageCustom id={translatableStrings.moreBuyIns.id} text={this.props.intl.formatMessage(translatableStrings.moreBuyIns)} />*/}
                    {/*                                /!* {this.state.capitalPurchaseDateInvalid.slice(2).includes(true) ? <Tooltip *!/*/}
                    {/*                                {this.state.capitalPurchaseDateInvalid.slice(2).includes(true) ? <Tooltip*/}
                    {/*                                    mouseEnterDelay={0}*/}
                    {/*                                    mouseLeaveDelay={0}*/}
                    {/*                                    destroyTooltipOnHide={true}*/}
                    {/*                                    placement={'top'}*/}
                    {/*                                    trigger={'click'}*/}
                    {/*                                    overlay={*/}
                    {/*                                        <FormattedMessageCustom id={translatableStrings.capitalBuyInInvalidExplanation.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInInvalidExplanation)}>*/}
                    {/*                                        </FormattedMessageCustom>*/}
                    {/*                                    }*/}
                    {/*                                >*/}
                    {/*                                    <HelpIcon style={{ zIndex: 9999, width: '18px', marginLeft: '8px' }} className="pointer" />*/}
                    {/*                                </Tooltip> : null}*/}
                    {/*                            </div>*/}
                    {/*                            <Button*/}
                    {/*                                className="button openCapitalWithdrawalButtonChange"*/}
                    {/*                                onClick={() => this.props.openModal("showAllBuyIns", true)}*/}
                    {/*                                buttonType={EButtonType.BasicLink}>*/}
                    {/*                                <FormattedMessageCustom id={translatableStrings.showAll.id} text={this.props.intl.formatMessage(translatableStrings.showAll)} />*/}
                    {/*                            </Button>*/}
                    {/*                        </div>*/}
                    {/*                    )*/}
                    {/*            } else {*/}
                    {/*                return null*/}
                    {/*            }*/}
                    {/*        })}*/}
                    {/*    </div>*/}

                    {/*    <div className={Object.keys(this.props.userDataWI.capitalPurchases).length < 2 && "p-md-24"}>*/}
                    {/*        {this.state.capitalPurchaseDateInvalid[this.state.sliderValuesPurchase.length - 1] && !this.props.userDataWI.capitalPurchases[1] &&*/}
                    {/*            <p className="invalidPurchaseDate">*/}
                    {/*                {this.state.capitalPurchaseDateInvalidFromDrag ?*/}
                    {/*                    <FormattedMessageCustom id={translatableStrings.wrongDateForCapitalBuyIn.id} text={this.props.intl.formatMessage(translatableStrings.wrongDateForCapitalBuyIn)}>*/}
                    {/*                    </FormattedMessageCustom>*/}
                    {/*                    : <FormattedMessageCustom id={translatableStrings.capitalBuyInNotPossible.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInNotPossible)}>*/}
                    {/*                    </FormattedMessageCustom>*/}
                    {/*                }*/}
                    {/*                {<Tooltip*/}
                    {/*                    mouseEnterDelay={0}*/}
                    {/*                    mouseLeaveDelay={0}*/}
                    {/*                    destroyTooltipOnHide={true}*/}
                    {/*                    placement={'top'}*/}
                    {/*                    trigger={'click'}*/}
                    {/*                    overlay={*/}
                    {/*                        <FormattedMessageCustom id={translatableStrings.capitalBuyInInvalidExplanation.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInInvalidExplanation)}>*/}
                    {/*                            <p className="invalidPurchaseDate">*/}
                    {/*                            </p>*/}
                    {/*                        </FormattedMessageCustom>*/}
                    {/*                    }>*/}
                    {/*                    <HelpIcon style={{ zIndex: 9999, width: '18px', marginLeft: '8px' }} className="pointer" />*/}
                    {/*                </Tooltip>}*/}
                    {/*            </p>*/}
                    {/*        }*/}

                    {/*        {this.state.sliderValuesPurchase.length > 0 && this.state.sliderValuesPurchase[0] && this.state.sliderValuesPurchase.length < 6 ? <Button*/}
                    {/*            className="button openCapitalWithdrawalButton"*/}
                    {/*            buttonType={EButtonType.BasicLink}*/}
                    {/*            onClick={() => {*/}
                    {/*                this.setState({*/}
                    {/*                    capitalPurchaseDatesCandidate: [...this.state.capitalPurchaseDates],*/}
                    {/*                    sliderValuesPurchaseCandidate: [...this.state.sliderValuesPurchase],*/}
                    {/*                });*/}
                    {/*                this.props.openModal("capitalbuyin", true);*/}
                    {/*            }}>*/}
                    {/*            <FormattedMessageCustom id={translatableStrings.addCapitalBuyIn.id} text={this.props.intl.formatMessage(translatableStrings.addCapitalBuyIn)}>*/}
                    {/*            </FormattedMessageCustom>*/}
                    {/*        </Button> : null}*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        );
    }
}