import * as constants from '../constants/index';
import { Cookies } from 'react-cookie';
import { removeInArrayByIdx } from '../utils/array';
import { addToLSObject, dateFormat, daysInMonth, parseToApiDate } from '../utils/react';
import { routes } from '../Routes';
import sorting from "../utils/sorting";
import { getParameterByName } from '../utils/toSearchParams';
const cookies = new Cookies();

export interface ExamplePictures {
    activityRatePictureUrl: string | null;
    dateOfIssuePictureUrl: string | null;
    insurancePlanPictureUrl: string | null;
    payrollClassPictureUrl: string | null;
    payrollPictureUrl: string | null;
    savingsBalancePictureUrl: string | null;
}

export interface AppState {
    authentication: any;
    avatarSrc: string;
    calculateCounter: any;
    calculateCounterTrigger: any;
    calculationSteps: any;
    calculations: any;
    currentStep: number;
    errors: any;
    loading: boolean;
    recalculationPending: boolean;
    sidebarOpened: boolean;
    stepsOpened: any;
    examplePicturesSet: ExamplePictures;
    userDataWI: any;
    insurantPortalUrl: any;
    defaultRetirmentAgeFemale: number;
    defaultRetirmentAgeMale: number;
    insurantPortalData: any;
    monthlyOrYearly: string;
    globalSettings: any;
}

const currentPage = window.location.hash ? window.location.hash.split('/')[1].split('?')[0] : "";
let currentStep = 4;
routes.forEach((route) => {
    if (route.key === currentPage) {
        currentStep = route.step;
    }
})
console.log('currentStep', currentStep);

(window as any).preloadData = () => {
    const data = { "tenantName": "PKAR", "Geburtsdatum": "1964-05-11T12:00:00.000Z", "descendingStepsToEnteringIndex": { "0": 0 }, "retirementDateAt65": "2029-05-31T12:00:00.000Z", "retirementDateAt64": "2028-05-31T12:00:00.000Z", "earliestRetirement": "2022-05-31T12:00:00.000Z", "retirementPercentage": [100], "arbeitPensums": [0], "RetirementSteps": ["2029-05-31T12:00:00.000Z"], "RetirementStepsInitial": ["2029-05-31T12:00:00.000Z"], "RetirementStepsUnsorted": ["2029-05-31T12:00:00.000Z"], "Geschlecht": "männlich", "AusgestelltAm": "2020-01-19T12:00:00.000Z", "Lohnsumme": "95000", "bruttolohns": [0], "Beschaftigungsgrad": "90", "Sparguthaben": "451000", "ahvPensionAmount": "2390" };

    localStorage.setItem('userDataWI', JSON.stringify(data));
}

const getInitialState = () => {
    console.log('getInitialState');
    let lsUserData;
    let tenantName;
    let insurantPortalData;
    let insurantPortalUrl;

    try {
        lsUserData = JSON.parse((window as any).localStorage.getItem("userDataWI"));
        insurantPortalData = JSON.parse((window as any).localStorage.getItem("insurantPortalDataWI"));
        insurantPortalUrl = JSON.parse((window as any).localStorage.getItem("insurantPortalUrlWI"));
    } catch (error) {
        lsUserData = null;
        insurantPortalData = null;
        insurantPortalUrl = null;
        console.warn('There was a problem with LS', error)
    }

    if (lsUserData && lsUserData.RetirementStepsInitial) {
        lsUserData.RetirementSteps = [...lsUserData.RetirementSteps];
        lsUserData.RetirementStepsUnsorted = [...lsUserData.RetirementStepsUnsorted];
    }

    if (lsUserData && lsUserData.tenantName) {
        tenantName = lsUserData.tenantName
    } else {
        console.log('getParam', getParameterByName('tenantName'));
        tenantName = getParameterByName('tenantName') || getParameterByName('tenantname') || "BVG";
        addToLSObject("userDataWI", "tenantName", tenantName);
    }

    return {
        authentication: {
            login: null,
            token: cookies.get('bearerToken'),
            expirationDate: null
        },
        monthlyOrYearly: "monthlyIncome",
        sidebarOpened: false,
        avatarSrc: "https://cdn1.iconfinder.com/data/icons/user-pictures/100/unknown-512.png",
        loading: false,
        errors: [],
        calculations: null,
        calculationsObj: null,
        calculationSteps: null,
        calculateCounterTrigger: 0,
        calculateCounter: 0,
        currentStep: currentStep,
        lastCalculatePayload: null,
        recalculationPending: false,
        globalSettings: null,
        hasMultiplePlans: false,
        hasPayrollClasses: false,
        isBridingPossible: false,
        suggestMaxBridgingPensionValues: false,
        defaultRetirmentAgeFemale: 64,
        defaultRetirmentAgeMale: 65,
        stepsOpened: {
            1: false,
            2: false,
            3: false,
            4: false,
        },
        insurancePlans: [],
        globalSettingsFetched: false,
        examplePicturesSet: {
            activityRatePictureUrl: null,
            dateOfIssuePictureUrl: null,
            insurancePlanPictureUrl: null,
            payrollClassPictureUrl: null,
            payrollPictureUrl: null,
            savingsBalancePictureUrl: null
        },
        insurantPortalData: insurantPortalData,
        insurantPortalUrl: insurantPortalUrl,
        userDataWI: {
            // Wohnort: null,
            AusgestelltAm: null,
            Beschaftigungsgrad: null,
            Datenquelle: null,
            Geburtsdatum: null,
            Geschlecht: null,
            bruttolohns: [null],
            firstAltersrente: null,
            lastPartialPensionLimits: null,
            lastCalculateResponse: {
                incomePeriodSet: {},
                capitalInformation: {}
            },
            Lohnsumme: null,
            RetirementSteps: [],
            RetirementStepsInitial: [],
            RetirementStepsUnsorted: [],
            Sparguthaben: null,
            Wohnort: "1200 Genève",
            ahvPensionAmount: null,
            arbeitPensums: [],
            bridgingPension: 0,
            calculationType: "Retirement",
            capitalPurchases: { 0: {} },
            capitalWithdrawals: { 0: {} },
            earliestRetirement: null,
            maxPurchases: {},
            partialPensionLimits: {
                isAdditionalStepPossible: true
            },
            pensionType: "Common",
            retirementDateAt65: null,
            retirementPercentage: [],
            settings: null,
            ...lsUserData,
            tenantName: tenantName,
        }
    }
};

// console.log("app.ts", getInitialState());

const app = (state: AppState = getInitialState(), action: any) => {
    switch (action.type) {
        case constants.TENANT_SET: {
            console.log("reducer tenantName", action);
            addToLSObject("userDataWI", "tenantName", action.payload);
            return {
                ...state, userDataWi: {
                    tenantName: action.payload
                }
            }
        }
        case constants.SET_MONTHLY_OR_YEARLY: {
            return {
                ...state,
                monthlyOrYearly: action.payload
            }
        }
        // case constants.LANG_SET: {
        //     console.log("reducer LANG_SET", action);
        //     let langCode = action.payload;
        //     addToLSObject("userDataWI", "langCode", langCode);

        //     return {
        //         ...state, userDataWi: {
        //             langCode: langCode
        //         }
        //     }
        // }

        case constants.FETCHED_INSURANT_DATA: {
            const initialState = { ...getInitialState() };
            const insurantPortalData = action.payload.data;
            const insurantPortalUrl = action.payload.url;

            const initialBirthday = action.payload.data.birthdate;
            const birthdayFromData = initialBirthday.split("T")[0]
            const birthdayWithDots = birthdayFromData.split("-").reverse().join(".")

            const femaleRetirement = state.defaultRetirmentAgeFemale;
            const maleRetirement = state.defaultRetirmentAgeMale;
            console.log("DEFAULT RETIRMENT AGE FEM", state.defaultRetirmentAgeFemale);
            console.log("DEFAULT RETIRMENT AGE MAS", state.defaultRetirmentAgeMale);

            const dateSegments = birthdayWithDots.split(/[-.]/);
            let dPlus65 = parseToApiDate(`${dateSegments[1]}-${daysInMonth(dateSegments[1], parseInt(dateSegments[2]) + maleRetirement)}-${parseInt(dateSegments[2]) + maleRetirement}`, "mmddyyyy");
            let dPlus64 = parseToApiDate(`${dateSegments[1]}-${daysInMonth(dateSegments[1], parseInt(dateSegments[2]) + femaleRetirement)}-${parseInt(dateSegments[2]) + femaleRetirement}`, "mmddyyyy");
            let dPlus58 = parseToApiDate(`${dateSegments[1]}-${daysInMonth(dateSegments[1], parseInt(dateSegments[2]) + 58)}-${parseInt(dateSegments[2]) + 58}`, "mmddyyyy");

            const now = new Date();
            const dPlus65Date = new Date(dPlus65);
            const dPlus64Date = new Date(dPlus64);
            let lastDayOfMonth = parseToApiDate(`${now.getMonth() + 1}-${daysInMonth(now.getMonth() + 1, now.getFullYear())}-${now.getFullYear()}`, "mmddyyyy");
            if (dPlus65Date.getTime() < now.getTime()) {
                dPlus65 = lastDayOfMonth;
            }
            if (dPlus64Date.getTime() < now.getTime()) {
                dPlus64 = lastDayOfMonth;
            }

            const gender = action.payload.data.sex;
            let genderText;

            let retirementDate;
            if (gender === 0) {
                genderText = "männlich"
                retirementDate = dPlus65;
            } else {
                genderText = "weiblich"
                retirementDate = dPlus64;
            }

            const descendingStepsToEnteringIndex = {
                0: 0
            };

            const tenantName = action.payload.data.tenantCode;
            const lohnsumme = action.payload.data.insuranceRelationships?.[0]?.employment?.[0]?.payrolls?.[0]?.grossSalary || "0";
            const beschaftigungsgrad = action.payload.data.insuranceRelationships?.[0]?.employment?.[0]?.payrolls?.[0]?.activityRate || "0";

            const userDataWI = {
                "tenantName": "PKSO",
                "Geburtsdatum": initialBirthday,
                "retirementDateAt65": dPlus65,
                "retirementDateAt64": dPlus64,
                "earliestRetirement": dPlus58,
                "descendingStepsToEnteringIndex": descendingStepsToEnteringIndex,
                "retirementPercentage": [100],
                "arbeitPensums": [0],
                "RetirementSteps": [
                    retirementDate
                ],
                "RetirementStepsInitial": [
                    retirementDate
                ],
                "RetirementStepsUnsorted": [
                    retirementDate
                ],
                "bruttolohns": [0],
                "Geschlecht": genderText,
                // to clarify
                "AusgestelltAm": new Date().toISOString(),
                "Lohnsumme": lohnsumme,
                "Beschaftigungsgrad": beschaftigungsgrad,
                "Sparguthaben": "0", //TODO: check if this causes problem
                "ahvPensionAmount": "2390", //TODO: this is fixed for now, should be changed
            }



            localStorage.setItem('insurantPortalDataWI', JSON.stringify(insurantPortalData));
            localStorage.setItem('insurantPortalUrlWI', JSON.stringify(insurantPortalUrl));
            localStorage.setItem('userDataWI', JSON.stringify({ ...initialState.userDataWI, ...userDataWI }));
            return {
                ...initialState,
                insurantPortalData,
                insurantPortalUrl,
                userDataWI: {
                    ...initialState.userDataWI,
                    ...userDataWI,
                    settings: {
                        ...state.userDataWI.settings
                    }
                }
            }
        }


        case constants.CLEAR_DATA: {
            const initialState = { ...getInitialState() };
            return {
                ...initialState,
                userDataWI: {
                    ...initialState.userDataWI,
                    settings: state.userDataWI.settings,
                    tenantName: state.userDataWI.tenantName
                }
            }
        }
        case constants.LOADING: {
            console.log("reducer loading", action);
            return { ...state, loading: action.payload }
        }
        case constants.LOGIN: {
            console.log("reducer login start", action.payload);
            return { ...state, authentication: { login: action.payload.login } }
        }
        case constants.LOGIN_SUCCESS: {
            console.log("reducer loginsuccess", action.payload);
            return { ...state, authentication: { ...state.authentication, token: action.payload.bearerToken, expirationDate: action.payload.expirationDate } }
        }
        case constants.LOG_OUT: {
            console.log("reducer logout", action);
            return { ...state, authentication: { login: null, token: null, expirationDate: null } };
        }
        case constants.LOGIN_ERROR: {
            // @TODO better handle state change when error
            console.log('a login error occurred: ', action);
            return { ...state, authentication: getInitialState().authentication, errors: [action.error] };
        }
        case constants.SET_STEPS_OPENED: {
            console.log('setstepsopened reducer', action.payload);
            return {
                ...state,
                stepsOpened: {
                    ...state.stepsOpened,
                    ...action.payload,
                }
            }
        }
        case constants.USERDATA_ANLIEGEN: {
            addToLSObject("userDataWI", "calculationType", action.payload);
            return {
                ...state,
                currentStep: 2,
                userDataWI: {
                    ...state.userDataWI,
                    calculationType: action.payload,
                }
            }
        }
        case constants.USERDATA_DETAILLIERUNG: {
            addToLSObject("userDataWI", "pensionType", action.payload);
            return {
                ...state,
                currentStep: 3,
                userDataWI: {
                    ...state.userDataWI,
                    pensionType: action.payload,
                },
                stepsOpened: {
                    ...state.stepsOpened,
                    1: false
                }
            }
        }
        case constants.USERDATA_DATENQUELLE: {
            addToLSObject("userDataWI", "Datenquelle", action.payload);
            return {
                ...state,
                currentStep: 4,
                userDataWI: {
                    ...state.userDataWI,
                    Datenquelle: action.payload,
                }
            }
        }
        case constants.USERDATA_GEBURTSDATUM: {
            const dateSegments = action.payload.split(/[-.]/);
            const d = parseToApiDate(`${dateSegments[1]}-${dateSegments[0]}-${dateSegments[2]}`, "mmddyyyy");

            const femaleRetirement = state.defaultRetirmentAgeFemale;
            const maleRetirement = state.defaultRetirmentAgeMale;

            let dPlus65 = parseToApiDate(`${dateSegments[1]}-${daysInMonth(dateSegments[1], parseInt(dateSegments[2]) + maleRetirement)}-${parseInt(dateSegments[2]) + maleRetirement}`, "mmddyyyy");
            let dPlus64 = parseToApiDate(`${dateSegments[1]}-${daysInMonth(dateSegments[1], parseInt(dateSegments[2]) + femaleRetirement)}-${parseInt(dateSegments[2]) + femaleRetirement}`, "mmddyyyy");
            let dPlus58 = parseToApiDate(`${dateSegments[1]}-${daysInMonth(dateSegments[1], parseInt(dateSegments[2]) + 58)}-${parseInt(dateSegments[2]) + 58}`, "mmddyyyy");
            console.log('birthdate reducer: ', dateSegments);
            console.log('newDate: ', dPlus65);

            const now = new Date();
            const dPlus65Date = new Date(dPlus65);
            const dPlus64Date = new Date(dPlus64);
            let lastDayOfMonth = parseToApiDate(`${now.getMonth() + 1}-${daysInMonth(now.getMonth() + 1, now.getFullYear())}-${now.getFullYear()}`, "mmddyyyy");

            if (dPlus65Date.getTime() < now.getTime()) {
                dPlus65 = lastDayOfMonth;
                // dPlus58 = lastDayOfMonth
            }

            if (dPlus64Date.getTime() < now.getTime()) {
                dPlus64 = lastDayOfMonth;
                // dPlus58 = lastDayOfMonth
            }

            console.log("65", dPlus65);
            console.log("64", dPlus64);
            console.log("58", dPlus58);


            const descendingStepsToEnteringIndex = {
                0: 0
            };
            let retirementDate;

            addToLSObject("userDataWI", "Geburtsdatum", d);
            addToLSObject("userDataWI", "descendingStepsToEnteringIndex", descendingStepsToEnteringIndex);
            addToLSObject("userDataWI", "retirementDateAt65", dPlus65);
            addToLSObject("userDataWI", "retirementDateAt64", dPlus64);
            addToLSObject("userDataWI", "earliestRetirement", dPlus58);
            addToLSObject("userDataWI", "retirementPercentage", [100]);
            addToLSObject("userDataWI", "arbeitPensums", [0]);

            if (state.userDataWI.Geschlecht === "weiblich") {
                addToLSObject("userDataWI", "RetirementSteps", [
                    dPlus64
                ]);
                addToLSObject("userDataWI", "RetirementStepsInitial", [
                    dPlus64
                ]);
                addToLSObject("userDataWI", "RetirementStepsUnsorted", [
                    dPlus64
                ]);
                retirementDate = dPlus64;
            } else {
                addToLSObject("userDataWI", "RetirementSteps", [
                    dPlus65
                ]);
                addToLSObject("userDataWI", "RetirementStepsInitial", [
                    dPlus65
                ]);
                addToLSObject("userDataWI", "RetirementStepsUnsorted", [
                    dPlus65
                ]);
                retirementDate = dPlus65;
            }

            return {
                ...state,
                currentStep: 5,
                userDataWI: {
                    ...state.userDataWI,
                    "Geburtsdatum": d,
                    "retirementDateAt65": dPlus65,
                    "retirementDateAt64": dPlus64,
                    "earliestRetirement": dPlus58,
                    "descendingStepsToEnteringIndex": descendingStepsToEnteringIndex,
                    "retirementPercentage": [100],
                    "arbeitPensums": [0],
                    "RetirementSteps": [
                        retirementDate
                    ],
                    "RetirementStepsInitial": [
                        retirementDate
                    ],
                    "RetirementStepsUnsorted": [
                        retirementDate
                    ],
                }
            }
        }
        case constants.USERDATA_GESCHLECHT: {
            addToLSObject("userDataWI", "Geschlecht", action.payload);
            const dPlus64 = state.userDataWI.retirementDateAt64;
            const dPlus65 = state.userDataWI.retirementDateAt65;
            let retirementDate;

            if (action.payload === "weiblich") {
                addToLSObject("userDataWI", "RetirementSteps", [
                    dPlus64
                ]);
                addToLSObject("userDataWI", "RetirementStepsInitial", [
                    dPlus64
                ]);
                addToLSObject("userDataWI", "RetirementStepsUnsorted", [
                    dPlus64
                ]);
                retirementDate = dPlus64;
            } else {
                addToLSObject("userDataWI", "RetirementSteps", [
                    dPlus65
                ]);
                addToLSObject("userDataWI", "RetirementStepsInitial", [
                    dPlus65
                ]);
                addToLSObject("userDataWI", "RetirementStepsUnsorted", [
                    dPlus65
                ]);
                retirementDate = dPlus65;
            }

            return {
                ...state,
                currentStep: 6,
                userDataWI: {
                    ...state.userDataWI,
                    Geschlecht: action.payload,
                    "RetirementSteps": [
                        retirementDate
                    ],
                    "RetirementStepsInitial": [
                        retirementDate
                    ],
                    "RetirementStepsUnsorted": [
                        retirementDate
                    ],
                },
                stepsOpened: {
                    ...state.stepsOpened,
                    2: false
                }
            }
        }
        case constants.USERDATA_WOHNORT: {
            addToLSObject("userDataWI", "Wohnort", action.payload);
            return {
                ...state,
                currentStep: 7,
                userDataWI: {
                    ...state.userDataWI,
                    Wohnort: action.payload,
                },
                stepsOpened: {
                    ...state.stepsOpened,
                    2: false
                }
            }
        }
        case constants.USERDATA_AUSGESTELLTAM: {
            console.log('ausgestelld', action.payload);
            // ddmmyyyy
            const dateSegments = action.payload.split(/[-.]/);
            const d = parseToApiDate(`${dateSegments[0]}-${dateSegments[1]}-${dateSegments[2]}`, "ddmmyyyy");
            addToLSObject("userDataWI", "AusgestelltAm", d);
            return {
                ...state,
                currentStep: 8,
                userDataWI: {
                    ...state.userDataWI,
                    AusgestelltAm: d,
                }
            }
        }
        case constants.USERDATA_LOHNKLASSE: {
            addToLSObject("userDataWI", "payrollClass", action.payload.value);

            return {
                ...state,
                currentStep: action.payload.onlyPayrollClass ? state.currentStep : 9,
                calculateCounterTrigger: action.payload.onlyPayrollClass ? state.calculateCounterTrigger + 1 : state.calculateCounterTrigger,
                userDataWI: {
                    ...state.userDataWI,
                    payrollClass: action.payload.value
                }
            }
        }

        case constants.USERDATA_INSURANCE_PLAN: {
            addToLSObject("userDataWI", "insurancePlan", action.payload);

            return {
                ...state,
                currentStep: 9,
                userDataWI: {
                    ...state.userDataWI,
                    insurancePlan: action.payload
                }
            }
        }

        case constants.USERDATA_LOHNSUMME: {
            addToLSObject("userDataWI", "Lohnsumme", action.payload);
            let newBruttolohns = [...state.userDataWI.bruttolohns];
            // newBruttolohns[0] = Number(action.payload);
            newBruttolohns[0] = 0;
            addToLSObject("userDataWI", "bruttolohns", newBruttolohns);

            return {
                ...state,
                currentStep: 9,
                userDataWI: {
                    ...state.userDataWI,
                    Lohnsumme: action.payload,
                    bruttolohns: newBruttolohns
                }
            }
        }

        case constants.USERDATA_BESCHAFTIGUNGSGRAD: {
            addToLSObject("userDataWI", "Beschaftigungsgrad", action.payload);
            return {
                ...state,
                currentStep: 9,
                userDataWI: {
                    ...state.userDataWI,
                    Beschaftigungsgrad: action.payload,
                }
            }
        }
        case constants.USERDATA_SPARGUTHABEN: {
            console.log('sparguthben: ', action.payload);
            addToLSObject("userDataWI", "Sparguthaben", action.payload);
            return {
                ...state,
                currentStep: 10,
                userDataWI: {
                    ...state.userDataWI,
                    Sparguthaben: action.payload,
                }
            }
        }
        case constants.USERDATA_VPKONTO: {
            addToLSObject("userDataWI", "VPKonto", action.payload);
            addToLSObject("userDataWI", "ahvPensionAmount", 0);
            console.log('userdata vpkonto: ', action.payload, action.payload === "Ja");
            return {
                ...state,
                currentStep: 11,
                userDataWI: {
                    ...state.userDataWI,
                    VPKonto: action.payload,
                    ahvPensionAmount: 0,

                },
            }
        }
        case constants.USERDATA_VPKONTO_AMOUNT: {
            addToLSObject("userDataWI", "VPKontoAmount", action.payload);
            return {
                ...state,
                currentStep: 11,
                userDataWI: {
                    ...state.userDataWI,
                    VPKontoAmount: action.payload,

                },
            }
        }
        case constants.USERDATA_AHVRENTE: {
            addToLSObject("userDataWI", "ahvPensionAmount", action.payload);
            return {
                ...state,
                currentStep: 12,
                userDataWI: {
                    ...state.userDataWI,
                    ahvPensionAmount: action.payload,
                },
            }
        }
        case constants.USERDATA_RENTENANTEIL: {
            console.log('unserdata rentenanteil');
            const newRetirementPercentages = [...state.userDataWI.retirementPercentage, action.payload];
            addToLSObject("userDataWI", "retirementPercentage", newRetirementPercentages);
            return {
                ...state,
                currentStep: 13,
                userDataWI: {
                    ...state.userDataWI,
                    retirementPercentage: newRetirementPercentages,
                }
            }
        }
        case constants.USERDATA_BRUTTOLOHN: {
            console.log('unserdata rentenanteil');
            let newBruttolohns = [...state.userDataWI.bruttolohns];
            newBruttolohns[action.payload.idx] = action.payload.data;
            addToLSObject("userDataWI", "bruttolohns", newBruttolohns);
            return {
                ...state,
                currentStep: 13,
                userDataWI: {
                    ...state.userDataWI,
                    bruttolohns: newBruttolohns,
                }
            }
        }
        case constants.USERDATA_ARBEITSPENSUM: {
            const newArbeitPensums = [...state.userDataWI.arbeitPensums, action.payload];
            addToLSObject("userDataWI", "arbeitPensums", newArbeitPensums);
            return {
                ...state,
                currentStep: 14,
                userDataWI: {
                    ...state.userDataWI,
                    arbeitPensums: newArbeitPensums,
                },
                calculateCounterTrigger: 0,
                // stepsOpened: {
                //     ...state.stepsOpened,
                //     4: false
                // }
            }
        }
        case constants.USERDATA_BRIDGINGPENSION: {
            const {sliderValues, reloadPage} = action.payload;

            addToLSObject("userDataWI", "bridgingPensionSteps", sliderValues);

            console.log('action.payload', action.payload, "bridgingPension", sliderValues)

            const bridgingPensionSteps = {
                ...state,
                userDataWI: {
                    ...state.userDataWI,
                    // bridgingPension: 0,
                    bridgingPensionSteps: sliderValues
                }
            }

            if(!reloadPage) {
                return bridgingPensionSteps
            } else {
                return {
                    ...bridgingPensionSteps,
                    calculateCounterTrigger: (window.innerWidth > 896 ? (state.calculateCounterTrigger + 1) : state.calculateCounterTrigger),
                    recalculationPending: !(window.innerWidth > 896)
                }
            }
        }

        case constants.USERDATA_DIVORCEPOSTINGS: {
            const divorcePostings = [
                {
                    date: action.payload.length > 0 ? action.payload[0].date.includes("T") ? action.payload[0].date : parseToApiDate(action.payload[0].date, "yyyyddmm") : "",
                    value: action.payload[0].value
                }
            ];
            addToLSObject("userDataWI", "divorcePostings", divorcePostings);
            return {
                ...state,
                userDataWI: {
                    ...state.userDataWI,
                    divorcePostings,
                },
                calculateCounterTrigger: window.innerWidth > 896 ? (state.calculateCounterTrigger + 1) : state.calculateCounterTrigger,
                recalculationPending: !(window.innerWidth > 896),
            }
        }

        case constants.USERDATA_DIVORCETOTALVALUE: {
            addToLSObject("userDataWI", "divorceTotalValue", action.payload);
            return {
                ...state,
                userDataWI: {
                    ...state.userDataWI,
                    divorceTotalValue: action.payload,
                },
            }
        }

        case constants.FETCHED_MAX_PURCHASE: {
            const newMaxPurchases = {
                ...state.userDataWI.maxPurchases,
                [action.payload.date]: { "date": action.payload.date, "max": action.payload.max }
            };
            addToLSObject("userDataWI", "maxPurchases", newMaxPurchases);
            return {
                ...state,
                userDataWI: {
                    ...state.userDataWI,
                    maxPurchases: newMaxPurchases,
                },
            }
        }
        case constants.CLEAR_CONTROLS: {
            const modalContent = action.payload;

            if(!modalContent || modalContent === 'überbrückungsrente'){
                addToLSObject("userDataWI", "bridgingPension", 0);
            }

            if(!modalContent || modalContent === 'capitalwithdrawal'){
                addToLSObject("userDataWI", "capitalWithdrawals", {
                    0: {}
                });
            }

            if(!modalContent || modalContent === 'startCapitalBuyIn') {
                addToLSObject("userDataWI", "capitalPurchases", {
                    0: {}
                });
                addToLSObject("userDataWI", "maxPurchases", {});
            }

            if(!modalContent){
                addToLSObject("userDataWI", "RetirementSteps", state.userDataWI.RetirementStepsInitial);
                addToLSObject("userDataWI", "RetirementStepsUnsorted", state.userDataWI.RetirementStepsInitial);
            }
            const originalRetirementDate = (state.userDataWI.Geschlecht === "weiblich" ? state.userDataWI.retirementDateAt64 : state.userDataWI.retirementDateAt65);
            
            return {
                ...state,
                userDataWI: {
                    ...state.userDataWI,
                    // überbrückungsrente
                    bridgingPension: !modalContent || modalContent === 'überbrückungsrente' ? 0 : state.userDataWI.bridgingPension,
                    // capitalwithdrawal
                    capitalWithdrawals: !modalContent || modalContent === 'capitalwithdrawal' ? {
                        0: {}
                    } : state.userDataWI.capitalWithdrawals,
                    // startCapitalBuyIn
                    maxPurchases: !modalContent || modalContent === 'startCapitalBuyIn' ? {} : state.userDataWI.maxPurchases,
                    capitalPurchases: !modalContent || modalContent === 'startCapitalBuyIn' ? {
                        0: {}
                    } : state.userDataWI.capitalPurchases,
                    // remove all teilpensions, bring back main handle to original retirement date
                    RetirementSteps: !modalContent ? [originalRetirementDate] : state.userDataWI.RetirementSteps,
                    RetirementStepsUnsorted: !modalContent ? [originalRetirementDate] : state.userDataWI.RetirementStepsUnsorted,
                    RetirementStepsInitial: !modalContent ? [originalRetirementDate] : state.userDataWI.RetirementStepsInitial, //to compoensate for the initial handle
                    arbeitPensums: !modalContent ? [state.userDataWI.arbeitPensums[0]] : state.userDataWI.arbeitPensums,
                    retirementPercentage: !modalContent ? [state.userDataWI.retirementPercentage[0]] : state.userDataWI.retirementPercentage,
                    descendingStepsToEnteringIndex: !modalContent ? { 0: 0 } : state.userDataWI.descendingStepsToEnteringIndex
                },
                calculateCounterTrigger: window.innerWidth > 896 ? (state.calculateCounterTrigger + 1) : state.calculateCounterTrigger,
                recalculationPending: !(window.innerWidth > 896),
            }
        }
        case constants.USERDATA_CAPITAL_WITHDRAWAL: {
            const amount = parseFloat(action.payload.amount);
            const index = action.payload.index;
            const percentage = action.payload.percentage;

            const newCapitalWithdrawal = {
                ...state.userDataWI.capitalWithdrawals, [index]: {
                    'amount': amount,
                    'index': index,
                    'percentage': percentage
                },
            };

            console.log('newCapitalWithdrawal', newCapitalWithdrawal, state.userDataWI.capitalWithdrawals);

            console.log('capitalWithdrawals: ',
                action.payload, state.userDataWI.capitalWithdrawals,
            );

            addToLSObject("userDataWI", "capitalWithdrawals", newCapitalWithdrawal);

            return {
                ...state,
                currentStep: 15,
                calculateCounterTrigger: window.innerWidth > 896 ? (state.calculateCounterTrigger + 1) : state.calculateCounterTrigger,
                recalculationPending: !(window.innerWidth > 896),
                userDataWI: {
                    ...state.userDataWI,
                    capitalWithdrawals: newCapitalWithdrawal,
                },
            }
        }
        case constants.USERDATA_CAPITAL_PURCHASE: {
            console.log('capital purchase', action.payload.date);
            const amount = parseFloat(action.payload.amount);
            const index = action.payload.index;
            const d = parseToApiDate(dateFormat(action.payload.date), "ddmmyyyy")

            const newCapitalPurchases = {
                ...state.userDataWI.capitalPurchases, [index]: {
                    'amount': amount,
                    'index': index,
                    'date': d
                }
            };

            console.log('newCapitalPurchases', newCapitalPurchases, state.userDataWI.capitalPurchases);

            console.log('setUserDataZeitpunkt: ',
                action.payload, state.userDataWI.capitalPurchases,
            );

            addToLSObject("userDataWI", "capitalPurchases", newCapitalPurchases);

            return {
                ...state,
                currentStep: 15,
                calculateCounterTrigger: window.innerWidth > 896 ? (state.calculateCounterTrigger + 1) : state.calculateCounterTrigger,
                recalculationPending: !(window.innerWidth > 896),
                userDataWI: {
                    ...state.userDataWI,
                    capitalPurchases: newCapitalPurchases,
                },

            }
        }
        case '@@router/LOCATION_CHANGE': {
            console.log('location change: ',
                action.payload
            );

            return {
                ...state,
                calculateCounterTrigger: action.payload.location.pathname !== "/chart" ? 0 : state.calculateCounterTrigger,
            }
        }
        case constants.SINCRONIZE_MAXPURCHASES: {
            let cleanedMaxPurchases = {}
            Object.keys(state.userDataWI.maxPurchases).forEach((item)=>{
                Object.keys(state.userDataWI.capitalPurchases).forEach(capital=>{
                    if(state.userDataWI.maxPurchases[item].date === state.userDataWI.capitalPurchases[capital].date){
                        cleanedMaxPurchases[state.userDataWI.maxPurchases[item].date] = state.userDataWI.maxPurchases[item]
                    }
                })
            })
            
            addToLSObject("userDataWI", "maxPurchases", cleanedMaxPurchases);
            return {
                ...state,
                userDataWI: {
                    ...state.userDataWI,
                    maxPurchases: cleanedMaxPurchases,
                },

            }
        }

        case constants.FORCE_RECALCULATE: {
            console.log('force recalcualte');
            return {
                ...state,
                calculateCounterTrigger: state.calculateCounterTrigger + 1,
            }
        }

        case constants.USERDATA_CAPITAL_PURCHASE_REMOVE: {
            const capitalPurchases = { ...state.userDataWI.capitalPurchases };
            const maxPurchases = { ...state.userDataWI.maxPurchases };
            let newCapitalPurchases = {};
            // let newMaxPurchases = {};
            console.log('remove capitalPurchases index: ', action.payload,
                'maxPurchases', maxPurchases,
                'capitalPurchases', capitalPurchases,
                // 'capitalPurchases[action.payload][date]',capitalPurchases[action.payload]['date']
            );

            // if (Object.keys(capitalPurchases).length > 1) {
            capitalPurchases[action.payload] && delete maxPurchases[capitalPurchases[action.payload]['date']];
            delete capitalPurchases[action.payload];
            // recalculate indexes
            Object.keys(capitalPurchases).forEach((oldKey, idx) => {
                newCapitalPurchases[idx] = capitalPurchases[oldKey]
            })
            // } else {
            // }
            console.log('newCapitalPurchases', newCapitalPurchases, Object.keys(newCapitalPurchases));
            if (Object.keys(newCapitalPurchases).length === 0) {
                newCapitalPurchases[0] = {};
            }
            addToLSObject("userDataWI", "capitalPurchases", newCapitalPurchases);
            addToLSObject("userDataWI", "maxPurchases", maxPurchases);
            return {
                ...state,
                currentStep: 16,
                calculateCounterTrigger: window.innerWidth > 896 ? (state.calculateCounterTrigger + 1) : state.calculateCounterTrigger,
                recalculationPending: !(window.innerWidth > 896),
                userDataWI: {
                    ...state.userDataWI,
                    capitalPurchases: newCapitalPurchases,
                    maxPurchases: maxPurchases
                }
            }
        }
        case constants.USERDATA_CAPITAL_PURCHASE_UPDATE: {
            const dateSegments = action.payload.date.split(/[-.]/);
            const index = action.payload.index;
            const d = parseToApiDate(`${dateSegments[1]}-${dateSegments[0]}-${dateSegments[2]}`, "mmddyyyy");

            let newCapitalPurchases = [...state.userDataWI.capitalPurchases];
            newCapitalPurchases[index] = d;
            // i need to know which index to update!!!
            addToLSObject("userDataWI", "capitalPurchases", newCapitalPurchases);
            console.log('zeitpunkt update: ', action.payload, newCapitalPurchases);
            return {
                ...state,
                calculateCounterTrigger: window.innerWidth > 896 ? (state.calculateCounterTrigger + 1) : state.calculateCounterTrigger,
                recalculationPending: !(window.innerWidth > 896),
                userDataWI: {
                    ...state.userDataWI,
                    capitalPurchases: newCapitalPurchases,
                }
            }
        }
        case constants.USERDATA_ZEITPUNKT: {
            // ddmmyyyy
            const dateSegments = action.payload[0].split(/[-.]/);
            // let day = dateSegments[0];
            let month = dateSegments[1];
            let year = dateSegments[2];
            // if januar
            if (month % 12 === 1) {
                month = 12;
                year -= 1;
            } else {
                month -= 1;
            }
            const d = parseToApiDate(`${daysInMonth(month, year)}-${('0' + month).slice(-2)}-${year}`, "ddmmyyyy");
            console.log('dateSegments zeitpunkt', dateSegments, d);
            const newRetirementSteps = [...state.userDataWI.RetirementSteps, d]
            const newRetirementStepsUnsorted = [...state.userDataWI.RetirementStepsUnsorted, d]
            const sortedSteps = [...newRetirementSteps].sort((a, b) => {
                return sorting(a, b, -1, true);
            })

            const descendingStepsToEnteringIndex = {};
            console.log('newRetirementSteps', newRetirementSteps, state.userDataWI.RetirementSteps);
            console.log('newRetirementStepsUnsorted', newRetirementStepsUnsorted, state.userDataWI.RetirementStepsUnsorted);
            sortedSteps.forEach((step, idx) => {
                console.log('sorted steps', idx, step, newRetirementStepsUnsorted);
                descendingStepsToEnteringIndex[idx] = newRetirementStepsUnsorted.indexOf(step);
            })

            console.log('setUserDataZeitpunkt: ',
                action.payload, state.userDataWI.RetirementSteps,
                JSON.parse(JSON.stringify(sortedSteps)),
                'descendingToEnteringIndex', descendingStepsToEnteringIndex
            );

            addToLSObject("userDataWI", "RetirementSteps", sortedSteps);
            addToLSObject("userDataWI", "RetirementStepsInitial", sortedSteps);
            addToLSObject("userDataWI", "RetirementStepsUnsorted", newRetirementStepsUnsorted);
            addToLSObject("userDataWI", "descendingStepsToEnteringIndex", descendingStepsToEnteringIndex);

            return {
                ...state,
                currentStep: 15,
                userDataWI: {
                    ...state.userDataWI,
                    RetirementSteps: sortedSteps,
                    RetirementStepsUnsorted: newRetirementStepsUnsorted,
                    RetirementStepsInitial: sortedSteps,
                    descendingStepsToEnteringIndex: descendingStepsToEnteringIndex
                },

            }
        }
        case constants.USERDATA_TEILPENSION_REMOVE: {

            const indexDescending = action.payload.idx;
            const indexWhenEntering = state.userDataWI.descendingStepsToEnteringIndex[action.payload.idx];
            // const index = state.userDataWI.RetirementSteps.indexOf(action.payload);
            const newZeitPunktsInitial = [...state.userDataWI.RetirementStepsInitial];
            // const newZeitPunktsUnsorted = [...state.userDataWI.RetirementStepsUnsorted];
            const newZeitPunkts = [...state.userDataWI.RetirementSteps];
            const newBruttolohns = [...state.userDataWI.bruttolohns];
            const newZeitPunktsUnsorted = [...state.userDataWI.RetirementStepsUnsorted];
            const newArbeitPensums = [...state.userDataWI.arbeitPensums];
            const newRetirementPercentages = [...state.userDataWI.retirementPercentage];

            // remove capitalWithdrawal also
            const capitalWithdrawals = { ...state.userDataWI.capitalWithdrawals };
            let newCapitalWithdrawals = {};
            const retirementStepToWithdrawalIndex = Object.keys(capitalWithdrawals).length - indexDescending - 1;
            capitalWithdrawals[retirementStepToWithdrawalIndex] && delete capitalWithdrawals[retirementStepToWithdrawalIndex];

            // recalculate indexes
            Object.keys(capitalWithdrawals).forEach((oldKey, idx) => {
                newCapitalWithdrawals[idx] = capitalWithdrawals[oldKey]
            });
            console.log('remove capital withdrawal also', capitalWithdrawals,
                "newCapitalWithdrawals", newCapitalWithdrawals,
                'idx', indexDescending
            );

            addToLSObject("userDataWI", "capitalWithdrawals", newCapitalWithdrawals);

            // const newdescendingStepsToEnteringIndex = state.userDataWI.descendingStepsToEnteringIndex;
            // const index = newZeitPunktsUnsorted.indexOf(newZeitPunkts[sortedIndex]);
            console.log('remove teilpension index: ', indexDescending, action.payload,
                'mapping', state.userDataWI.descendingStepsToEnteringIndex,
                'indexDescending', indexDescending,
                'indexWhenEntering', indexWhenEntering
            );

            const newZeitPunktsInitialRemoved = removeInArrayByIdx(newZeitPunktsInitial, indexDescending);
            const newZeitPunktsRemoved = removeInArrayByIdx(newZeitPunkts, indexDescending);
            const newZeitPunktsUnsortedRemoved = removeInArrayByIdx(newZeitPunktsUnsorted, indexWhenEntering || indexDescending);
            let newArbeitPensumsRemoved, newRetirementPercentagesRemoved, newBruttolohnsRemoved;

            if (newArbeitPensums.length > newZeitPunktsRemoved.length) {
                newArbeitPensumsRemoved = removeInArrayByIdx(newArbeitPensums, indexWhenEntering || indexDescending);
            } else {
                newArbeitPensumsRemoved = newArbeitPensums;
            }

            if (newBruttolohns.length > newZeitPunktsRemoved.length) {
                newBruttolohnsRemoved = removeInArrayByIdx(newBruttolohns, indexWhenEntering || indexDescending);
            } else {
                newBruttolohnsRemoved = newBruttolohns;
            }

            if (newRetirementPercentages.length > newZeitPunktsRemoved.length) {
                newRetirementPercentagesRemoved = removeInArrayByIdx(newRetirementPercentages, indexWhenEntering || indexDescending);
            } else {
                newRetirementPercentagesRemoved = newRetirementPercentages;
            }

            const sortedSteps = [...newZeitPunktsRemoved].sort((a, b) => {
                return sorting(a, b, -1, true);
            })

            const descendingStepsToEnteringIndex = {};

            sortedSteps.forEach((step, idx) => {
                descendingStepsToEnteringIndex[idx] = newZeitPunktsUnsortedRemoved.indexOf(step);
            })

            addToLSObject("userDataWI", "RetirementStepsInitial", newZeitPunktsInitialRemoved);
            addToLSObject("userDataWI", "RetirementSteps", newZeitPunktsRemoved);
            addToLSObject("userDataWI", "RetirementStepsUnsorted", newZeitPunktsUnsortedRemoved);
            addToLSObject("userDataWI", "arbeitPensums", newArbeitPensumsRemoved);
            addToLSObject("userDataWI", "bruttolohns", newBruttolohnsRemoved);
            addToLSObject("userDataWI", "retirementPercentage", newRetirementPercentagesRemoved);
            addToLSObject("userDataWI", "descendingStepsToEnteringIndex", descendingStepsToEnteringIndex);
            return {
                ...state,
                currentStep: 16,
                userDataWI: {
                    ...state.userDataWI,
                    capitalWithdrawals: newCapitalWithdrawals,
                    RetirementSteps: newZeitPunktsRemoved,
                    RetirementStepsUnsorted: newZeitPunktsUnsortedRemoved,
                    RetirementStepsInitial: newZeitPunktsInitialRemoved, //to compoensate for the initial handle
                    arbeitPensums: newArbeitPensumsRemoved,
                    bruttolohns: newBruttolohnsRemoved,
                    retirementPercentage: newRetirementPercentagesRemoved,
                    descendingStepsToEnteringIndex: descendingStepsToEnteringIndex
                }
            }
        }
        case constants.USERDATA_ZEITPUNKT_UPDATE: {
            const dateSegments = action.payload.date.split(/[-.]/);
            const d = parseToApiDate(`${dateSegments[1]}-${dateSegments[0]}-${dateSegments[2]}`, "mmddyyyy");
            let newZeitPunkts = [...state.userDataWI.RetirementSteps];
            let newZeitPunktsUnsorted = [...state.userDataWI.RetirementStepsUnsorted];
            newZeitPunkts[action.payload.index] = d;
            newZeitPunktsUnsorted[state.userDataWI.descendingStepsToEnteringIndex[action.payload.index]] = d;
            // i need to know which index to update!!!
            addToLSObject("userDataWI", "RetirementSteps", newZeitPunkts);
            addToLSObject("userDataWI", "RetirementStepsUnsorted", newZeitPunktsUnsorted);
            console.log('zeitpunkt update: ', action.payload, newZeitPunkts, newZeitPunktsUnsorted);
            return {
                ...state,
                userDataWI: {
                    ...state.userDataWI,
                    RetirementSteps: newZeitPunkts,
                    RetirementStepsUnsorted: newZeitPunktsUnsorted,
                }
            }
        }
        case constants.FETCHED_SETTINGS: {
            console.log('FETCHED SETTINGS: ', action.payload);
            const hasMultiplePlans = action.payload?.data?.hasMultiplePlans;
            const hasPayrollClasses = action.payload?.data?.hasPayrollClasses;
            const isBridingPossible = action.payload?.data?.isBridingPossible;
            const suggestMaxBridgingPensionValues = action.payload?.data?.suggestMaxBridgingPensionValues;
            const defaultRetirmentAgeFemale = action.payload?.data?.defaultRetirmentAgeFemale
            const defaultRetirmentAgeMale = action.payload?.data?.defaultRetirmentAgeMale
            const newState = {
                ...state,
                globalSettingsFetched: true,
                hasMultiplePlans: hasMultiplePlans,
                hasPayrollClasses: hasPayrollClasses,
                isBridingPossible: isBridingPossible,
                defaultRetirmentAgeFemale: defaultRetirmentAgeFemale,
                suggestMaxBridgingPensionValues: suggestMaxBridgingPensionValues,
                defaultRetirmentAgeMale: defaultRetirmentAgeMale,
                insurancePlans: action.payload?.data?.insurancePlanList || [],
                examplePicturesSet: action.payload?.data?.customDesign?.examplePicturesSet,
                userDataWI: {
                    ...state.userDataWI,
                    settings: action.payload ? action.payload.data : null
                }
            }

            if (hasPayrollClasses && !newState["userDataWI"]["payrollClass"]) {
                newState["userDataWI"]["payrollClass"] = null
            }
            if (hasMultiplePlans && !newState["userDataWI"]["insurancePlan"]) {
                newState["userDataWI"]["insurancePlan"] = null
            }
            console.log("FETCHED SETTINGS", newState);
            return { ...newState };
        }
        case constants.FETCHED_CALCULATIONS: {
            console.log('fetched calculations', action.payload);
            const calculationsObj = {
                teilPensions: [],
                capitalInformation: action.payload.data.capitalInformation
            };
            let firstAltersrente = null;
            let calculationSteps = [];
            // let teilPensions = [];
            action.payload.data.incomePeriodSet.forEach((obj, idx) => {
                if (obj['label'] === "Teilpensionierung") {
                    calculationSteps.unshift(obj);
                    calculationsObj.teilPensions.unshift(obj)
                } else {
                    calculationsObj[obj['label']] = obj;
                }
            });

            if (calculationsObj["Frühpensionierung"]) {
                calculationSteps.unshift(calculationsObj["Frühpensionierung"]);
            } else if (calculationsObj["Pensionierung"]) {
                calculationSteps.unshift(calculationsObj["Pensionierung"]);
            } else {
                calculationsObj["AHV-Pensionierung"] && calculationSteps.unshift(calculationsObj["AHV-Pensionierung"]);
            }

            addToLSObject("userDataWI", "partialPensionLimits", action.payload.data.possibleRetirementStepDefaultValuesSet);
            if (action.payload.data.possibleRetirementStepDefaultValuesSet.isAdditionalStepPossible) {
                addToLSObject("userDataWI", "lastPartialPensionLimits", action.payload.data.possibleRetirementStepDefaultValuesSet);
            }
            addToLSObject("userDataWI", "partialPensionLimits", action.payload.data.possibleRetirementStepDefaultValuesSet);

            const incomePeriodSetsWithIds = action.payload.data.incomePeriodSet.map(incomePeriod => {
                return incomePeriod.limitSet.isBridingPensionPossible ? {
                    ...incomePeriod,
                    linkedRetirementStep: {
                        ...incomePeriod.linkedRetirementStep,
                        retirementStepId: incomePeriod.linkedRetirementStep.retirementStepId ? incomePeriod.linkedRetirementStep.retirementStepId : String(Math.floor(Math.random() * (100 - 0 + 1) + 0))
                    }
                } : incomePeriod
            });
            const newPayloadWithIncomeIds = {
                ...action.payload.data,
                incomePeriodSet: incomePeriodSetsWithIds
            }
            addToLSObject("userDataWI", "lastCalculateResponse", newPayloadWithIncomeIds);
            addToLSObject("userDataWI", "lastCalculatePayload", action.payload.posted);

            if (state.userDataWI.firstAltersrente) {
                firstAltersrente = { ...state.userDataWI.firstAltersrente };
            } else {
                firstAltersrente = {
                    'monthlyIncome': action.payload.data.incomePeriodSet[action.payload.data.incomePeriodSet.length - 1]['monthlyIncome']['basicPension'],
                    'annualIncome': action.payload.data.incomePeriodSet[action.payload.data.incomePeriodSet.length - 1]['annualIncome']['basicPension'],
                }
            }

            addToLSObject("userDataWI", "firstAltersrente", firstAltersrente);

            return {
                ...state,
                calculations: action.payload.data.incomePeriodSet,
                calculationsObj,
                defaultRetirmentAgeFemale: state.userDataWI?.settings?.defaultRetirmentAgeFemale ? state.userDataWI.settings.defaultRetirmentAgeFemale: state.defaultRetirmentAgeFemale,
                defaultRetirmentAgeMale: state.userDataWI?.settings?.defaultRetirmentAgeMale ? state.userDataWI.settings.defaultRetirmentAgeMale: state.defaultRetirmentAgeMale,
                isBridingPossible: state.userDataWI?.settings?.isBridingPossible ? state.userDataWI.settings.isBridingPossible : false,
                hasPayrollClasses: state.userDataWI?.settings?.hasPayrollClasses ? state.userDataWI.settings.hasPayrollClasses : false,
                suggestMaxBridgingPensionValues: state.userDataWI?.settings?.suggestMaxBridgingPensionValues ? state.userDataWI.settings.suggestMaxBridgingPensionValues : false,
                // below causes infinite loop
                // calculateCounterTrigger: state.calculateCounterTrigger + 1,
                calculateCounter: state.calculateCounter + 1,
                lastCalculatePayload: action.payload.posted,
                calculationSteps,
                userDataWI: {
                    ...state.userDataWI,
                    firstAltersrente: firstAltersrente,
                    lastCalculateResponse: newPayloadWithIncomeIds,
                    partialPensionLimits: action.payload.data.possibleRetirementStepDefaultValuesSet,
                    lastPartialPensionLimits: action.payload.data.possibleRetirementStepDefaultValuesSet.isAdditionalStepPossible ? action.payload.data.possibleRetirementStepDefaultValuesSet : null,
                }
            }
        }
        case constants.FETCHED_CALCULATIONS_FAILED: {
            console.log('fetched failed calculations', action.payload);
            const lastCalculateResponse = JSON.parse((window as any).localStorage.getItem("userDataWI")).lastCalculateResponse;

            const calculationsObj = {
                teilPensions: [],
                capitalInformation: lastCalculateResponse ? lastCalculateResponse.capitalInformation : {}
            };
            let calculationSteps = [];
            // let teilPensions = [];
            if (lastCalculateResponse) {
                lastCalculateResponse.incomePeriodSet.forEach((obj, idx) => {
                    if (obj['label'] === "Teilpensionierung") {
                        calculationSteps.unshift(obj);
                        calculationsObj.teilPensions.unshift(obj)
                    } else {
                        calculationsObj[obj['label']] = obj;
                    }
                });
            } else {
                localStorage.clear();
            }

            calculationsObj["AHV-Pensionierung"] && calculationSteps.unshift(calculationsObj["AHV-Pensionierung"]);
            calculationsObj["Pensionierung"] && calculationSteps.unshift(calculationsObj["Pensionierung"]);
            // addToLSObject("userDataWI", "partialPensionLimits", action.payload.data.possibleRetirementStepDefaultValuesSet);
            // addToLSObject("userDataWI", "lastCalculateResponse", action.payload.data);

            return {
                ...state,
                calculations: lastCalculateResponse ? lastCalculateResponse.incomePeriodSet : {},
                calculationsObj,
                // calculateCounter: state.calculateCounter + 1,
                // lastCalculatePayload: action.payload.posted.insurant,
                calculationSteps,
                userDataWI: {
                    ...state.userDataWI,
                    partialPensionLimits: lastCalculateResponse ? lastCalculateResponse.possibleRetirementStepDefaultValuesSet : {},
                }
            }
        }

    }

    return state;
};

export default app;
