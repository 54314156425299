import React from 'react';
import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import { RootState } from '../../../../reducers/index';
import './BlockEarlyPension.scss';
import gsap from 'gsap';
import classNames from 'classnames';
// import { ChartSettings } from '../chartSettings';
import { formatNumber } from '../../../../utils/react';
import { ChartSettings } from '../chartSettings';
import { animationConfig } from '../../../../constants';
// import { start } from 'repl';
// import { Chart } from '..';
import { defineMessages } from 'react-intl';
import FormattedMessageCustom from '../../../../components/FormattedMessageCustom';

const translatableStrings = defineMessages({
    earlyPension: {
        id: 'pageChart.earlyPension',
        defaultMessage: 'Frühpensionierung'
    },
    bridgingPension: {
        id: 'pageChart.bridgingPension',
        defaultMessage: 'Überbrückungsrente'
    },
    altersRente: {
        id: 'pageChart.altersRente',
        defaultMessage: 'Altersrente'
    },
});

interface State {
    animateCounterInternal: number;
}

interface Props {
    animateCounter: number;
    bridgingPensionAmount: number;
    basicPension: number;
    calculateCounterTrigger: number;
    maxVal: number;
    partialPensionsNo: number;
    // minVal: number;
    intl: any;
    segment: number;
    totalIncome: number;
    width: number;
    wrapInTooltip: any;
}

export class BlockEarlyPension extends React.Component<Props, State> {
    private barEarlyPensionLabel: any;
    private barEarlyPensionPerc: any;
    private barBridgingPensionPerc: any;
    private barEarlyPensionLabelWrapper: any;
    private barMain: any = null;
    private timeline = null;

    constructor(props: any) {
        super(props);
        console.log("BlockEarlyPension constructor called");

        this.state = {
            animateCounterInternal: 0
        };
    }

    componentDidMount() {
        console.log('BlockEarlyPension app did mount', this.props, this.barMain);
        window.addEventListener('lastPartialPensionAnimated', this.animateIn);
        // window.addEventListener('blockCapitalAnimatedWhenNoPartialPensions', this.animateIn);
    }


    componentWillUnmount() {
        window.removeEventListener('lastPartialPensionAnimated', this.animateIn);
        // window.removeEventListener('blockCapitalAnimatedWhenNoPartialPensions', this.animateIn);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        Object.entries(this.props).forEach(([key, val]) => {
            if (prevProps[key] !== val) {
                console.log(`Prop BlockEarlyPension '${key}' changed`);
            }
        }
        );
        // if (this.state) {
        //     Object.entries(this.state).forEach(([key, val]) =>
        //         prevState[key] !== val && console.log(`State BlockEarlyPension'${key}' changed`)
        //     );
        // }
        // console.log("BlockEarlyPension componenentDidUpdate", prevProps, prevState, snapshot);
        // this.animateIn();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const widthChanged = nextProps.width !== this.props.width;
        const animateCounterIncreased = nextProps.animateCounter > this.props.animateCounter;
        const bridgingPensionAmountChanged = nextProps.bridgingPensionAmount !== this.props.bridgingPensionAmount;
        const basicPensionChanged = nextProps.basicPension !== this.props.basicPension;
        const calculateCounterTriggerIncreased = nextProps.calculateCounterTrigger > this.props.calculateCounterTrigger;
        // const animateCounterChanged = nextProps.animateCounter > this.props.animateCounter;
        // console.log('shouldComponentUpdate BlockEarlyPension widthChanged', widthChanged);
        // console.log('shouldComponentUpdate Blocksalary widthChanged', widthChanged, 'animateCounterCHanged: ', this.props.animateCounter, nextProps.animateCounter, animateCounterChanged);
        if (animateCounterIncreased || bridgingPensionAmountChanged || calculateCounterTriggerIncreased || basicPensionChanged || widthChanged || true) {
            console.log('shouldComponentUpdate TRUE earlypension', this.props.basicPension, nextProps.basicPension,
                'animateCounterIncreased', animateCounterIncreased,
                'bridgingPensionAmountChanged', bridgingPensionAmountChanged,
                'calculateCounterTriggerIncreased', calculateCounterTriggerIncreased,
                'basicPensionChanged', basicPensionChanged
            );

            // if (basicPensionChanged) {
            //     this.timeline = gsap.timeline({ paused: true });
            //     this.timeline && this.timeline.pause();
            //     let startSec = 0;
            //     const availableHeight = document.querySelector('.chartRightTop') ? document.querySelector('.chartRightTop').clientHeight - 75 : 0;
            //     const realValueToPxRatio = availableHeight / this.props.maxVal;
            //     const maxHeightOld = this.props.basicPension * realValueToPxRatio;
            //     const maxHeightNew = nextProps.basicPension * realValueToPxRatio;
            //     setTimeout(() => {
            //         console.log('will play', document.getElementById("barEarlyPensionPerc"), maxHeightOld, maxHeightNew);
            //         this.timeline
            //             .fromTo(document.getElementById("barEarlyPensionPerc"), { maxHeight: maxHeightOld, ease: animationConfig().easing }, {maxHeight: maxHeightNew}, startSec);
            //         this.timeline
            //             .timeScale(animationConfig().scale || 0.75)
            //             .play();
            //     }, 50)
            // }
            // this.props.animateCounter === 1 && this.animateIn(this.props.width, nextProps.width);
            return true;
        } else {
            // console.log('shouldComponentUpdate FALSE');
            return false
        }
    }

    // runTimeline = (oldWidth, newWidth) => {
    animateIn = (oldWidth?, newWidthPassed?) => {
        if (this.state.animateCounterInternal < 1) {
            const availableHeight = document.querySelector('.chartRightTop') ? document.querySelector('.chartRightTop').clientHeight - 75 : 0;
            const realValueToPxRatio = availableHeight / this.props.maxVal;
            const earlyPensionHeight = Math.min(this.props.basicPension * realValueToPxRatio, this.props.basicPension);

            const newWidth = newWidthPassed || this.props.width;
            console.log('runTimeline early', this.state.animateCounterInternal, newWidth);
            this.timeline = gsap.timeline({ paused: true });
            this.timeline && this.timeline.pause();
            let startSec = this.props.partialPensionsNo === 0 ? animationConfig().startSec : animationConfig().startSec + 0.4;

            this.timeline
                .fromTo(document.querySelector(".blockEarlyPension"), { opacity: 0, duration: 0 }, { opacity: 1 }, startSec)
                .fromTo(this.barMain, { maxWidth: 0, duration: 0 }, { maxWidth: newWidth }, startSec)
                .fromTo(document.getElementById('barEarlyPensionLabel'),
                    { y: -40, autoAlpha: 0, ease: animationConfig().easing, stagger: 0.2 },
                    { autoAlpha: 1, y: 0 }, startSec + 1)
                .fromTo(document.querySelectorAll(".barEarlyPensionPercs"), { maxHeight: 0, opacity: 0, ease: animationConfig().easing }, {opacity: 1, maxHeight: earlyPensionHeight}, startSec + 1);

            this.props.bridgingPensionAmount > 0 && this.timeline
                .fromTo(document.querySelector("#barBridgingPensionPerc"), { opacity: 0, duration: 0 }, { opacity: 1 }, startSec)
                .from(document.querySelectorAll(".barBridgingPensionPercs"),
                    { duration: .6, ease: animationConfig().easing }, startSec + 1.3);

            this.timeline
                .timeScale(animationConfig().scale || 0.75)
                .play();

            this.timeline.eventCallback("onStart", () => {
                console.log('oncomplete blockearlypension animateCounter', this.props.animateCounter);
                this.setState({
                    animateCounterInternal: this.state.animateCounterInternal + 1
                })
            });
        }
    }

    tooltip = (val, realValueToPxRatio) => {
        // console.log('tooltip earlypension perc: ', perc);
        if (val * realValueToPxRatio < 55 || this.props.width < ChartSettings.minBlockWidth1) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const availableHeight = document.querySelector('.chartRightTop') ? document.querySelector('.chartRightTop').clientHeight - 75 : 0;
        const realValueToPxRatio = availableHeight / this.props.maxVal;
        const enoughHeightFor1RowBridging = this.props.bridgingPensionAmount * realValueToPxRatio >= 30;
        const enoughHeightFor1RowBasic = this.props.basicPension * realValueToPxRatio >= 30;
        console.log('realValueToPxRatio early', realValueToPxRatio, this.props.basicPension * realValueToPxRatio);
        return (
            <div ref={c => this.barMain = c} className={classNames(
                "blockEarlyPension blockPadding blockFullHeight",
                {
                    "gsapInitial": this.state.animateCounterInternal < 1
                })}
                style={{
                    maxWidth: this.state.animateCounterInternal < 1 ? 0 : this.props.width,
                    overflow: 'hidden',
                    minWidth: 0,
                    opacity: 0
                    // opacity: this.state.animateCounter < 1 ? 0 : 1
                }}>
                <div id="barEarlyPensionLabelWrapper" className="blockLabelWrapper" ref={c => this.barEarlyPensionLabelWrapper = c} style={{
                    height: this.props.maxVal - this.props.bridgingPensionAmount - this.props.basicPension,
                    maxHeight: (this.props.maxVal - this.props.bridgingPensionAmount - this.props.basicPension) * realValueToPxRatio,
                    // opacity: 0
                }}>
                    <div id="barEarlyPensionLabel" className={classNames(
                        "blockLabel", {
                        // "rotated": this.props.width < ChartSettings.minBlockWidth1,
                        "hideCHF": this.props.width < 60
                    }
                    )}
                        ref={c => this.barEarlyPensionLabel = c} style={{
                            visibility: 'hidden'
                            // height: this.props.maxVal - this.props.totalIncome
                        }}>
                        <FormattedMessageCustom id={translatableStrings.earlyPension.id} text={this.props.intl.formatMessage(translatableStrings.earlyPension)}>
                            <p className="blockTitle"></p>
                        </FormattedMessageCustom>
                        <p className="blockValue">CHF {formatNumber(this.props.basicPension + this.props.bridgingPensionAmount)}</p>
                    </div>
                </div>

                {this.props.bridgingPensionAmount > 0 && <div id="barBridgingPensionPerc" className={classNames("blockStyleRounded blockElasticHeight barBridgingPensionPercs", {
                    "inTooltip": this.tooltip(this.props.bridgingPensionAmount, realValueToPxRatio),
                    "loaded": this.state.animateCounterInternal > 0,
                    "enoughHeightFor1Row": enoughHeightFor1RowBridging,
                })} ref={c => this.barBridgingPensionPerc = c} style={{
                    height: this.props.bridgingPensionAmount,
                    maxHeight: this.props.bridgingPensionAmount * realValueToPxRatio,
                    opacity: this.state.animateCounterInternal < 1 ? 0 : 1,
                }}>
                    {this.props.wrapInTooltip(
                        <div className={classNames("forTooltipOnly", {
                        })} style={{
                            height: "100%",
                            maxHeight: "100%",
                        }}>
                            <FormattedMessageCustom id={translatableStrings.bridgingPension.id} text={this.props.intl.formatMessage(translatableStrings.bridgingPension)}>
                                <p className="blockTitle bridgingPension" title="Überbrückungssrente"></p>
                            </FormattedMessageCustom>
                            <p className="blockValue">CHF {formatNumber(this.props.bridgingPensionAmount)}</p>
                        </div>,
                        [
                            <FormattedMessageCustom id={translatableStrings.bridgingPension.id} text={this.props.intl.formatMessage(translatableStrings.bridgingPension)}>
                                <p className="blockTitle"></p>
                            </FormattedMessageCustom>,
                            !enoughHeightFor1RowBridging && <p className="blockValue">CHF {formatNumber(this.props.bridgingPensionAmount)}</p>
                        ],
                        this.tooltip(this.props.bridgingPensionAmount, realValueToPxRatio)
                    )}
                </div>
                }
                <div id="barEarlyPensionPerc" className={classNames('blockElasticHeight barEarlyPensionPercs', {
                    "blockStyleRounded": this.props.bridgingPensionAmount === 0,
                    "loaded": this.state.animateCounterInternal > 0,
                    "enoughHeightFor1Row": enoughHeightFor1RowBasic,
                    "inTooltip": this.tooltip(this.props.basicPension, realValueToPxRatio)
                })} ref={c => this.barEarlyPensionPerc = c} style={{
                    opacity: this.state.animateCounterInternal < 1 ? 0 : 1,
                    height: this.props.basicPension,
                    maxHeight: Math.min(this.props.basicPension * realValueToPxRatio, this.props.basicPension),
                }}>
                    <div className={
                        classNames("bottomLabel", {
                        })
                    }>
                        <FormattedMessageCustom id={translatableStrings.altersRente.id} text={this.props.intl.formatMessage(translatableStrings.altersRente)}>
                            <p className="blockTitle"></p>
                        </FormattedMessageCustom>
                        <p className="blockValue">CHF {formatNumber(this.props.basicPension)}</p>
                    </div>

                    {this.props.wrapInTooltip(
                        <div className={classNames('forTooltipOnly', {
                        })} style={{
                            height: "100%",
                            maxHeight: "100%",
                        }}>
                        </div>,
                        [
                            <FormattedMessageCustom id={translatableStrings.altersRente.id} text={this.props.intl.formatMessage(translatableStrings.altersRente)}>
                                <p className="blockTitle"></p>
                            </FormattedMessageCustom>,
                            !enoughHeightFor1RowBasic && <p className="blockValue">CHF {formatNumber(this.props.basicPension)}</p>],
                        this.tooltip(this.props.basicPension, realValueToPxRatio)
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockEarlyPension);