import * as React from 'react';
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';
import classNames from 'classnames';
import { Handle, Track } from './components';
import { RootState } from "../../reducers";
import { Dispatch } from "redux";
import './Slider.scss'
import * as actions from "../../actions";
import { connect } from "react-redux";
import { Button, EButtonType } from "../Button";
import { formatNumber, formatSliderValue } from "../../utils/react";
import { defined } from "../../utils/variableEvaluation";
import { ReactComponent as HelpIcon } from "../../assets/icons/Help Icon.svg";
import Tooltip from 'rc-tooltip';
import { defineMessages } from 'react-intl';
import FormattedMessageCustom from '../../components/FormattedMessageCustom';

const translatableStrings = defineMessages({
    next: {
        id: 'button.next',
        defaultMessage: 'WEITER'
    },
})

interface State {
    value: any;
    newValue: any;
    percValue: any;
    // absValue: any;
    ready: boolean;
    // focusedOut: boolean;
    focusedAbsInput: boolean;
    focusedPercInput: boolean;
    isSliding: boolean;
}

interface Props {
    // activeSegment?: number;
    // currency: string;
    tooltip?: boolean;
    tooltipPlacement?: string;
    tooltipText?: string;
    tooltipTrigger?: string;
    className: string;
    controlled: boolean;
    domain?: Array<number>;
    forcedValue?: number;
    initialValue: any;
    intl: any;
    infoText?: any;
    label: any;
    maxPercentage?: number;
    maxVal: number;
    minVal: number;
    percent: boolean;
    precision?: number;
    pricision?: number;
    resetCounter?: number;
    setPercent?: any;
    showButton: boolean;
    showPercInput: boolean;
    sliderValueChanged?: any;
    stepSize?: number;
    translateId?: string;
    disableSlide?: string;
    disableOnblur?: boolean;
}

const sliderStyle: React.CSSProperties = {
    marginTop: '7%',
    position: 'relative',
    width: '100%'
};

const domain: ReadonlyArray<number> = [20, 90];

class Example extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        console.log('slider constructor initial value', this.props.label,
            this.props.initialValue,
            'forcedvalue',
            this.props.forcedValue,
            'maxval',
            this.props.maxVal,
            'maxpercentage',
            this.props.maxPercentage,
            ((this.props.maxPercentage / this.props.maxVal) * (this.props.initialValue || 0)).toFixed(1)
        );

        this.state = {
            value: this.props.initialValue || this.props.forcedValue || 0,
            newValue: this.props.initialValue || this.props.forcedValue || 0,
            // absValue: this.props.initialValue || this.props.forcedValue || 0,
            percValue: ((this.props.maxPercentage / this.props.maxVal) * (this.props.initialValue || 0)).toFixed(1),
            ready: false,
            // focusedOut: false,
            focusedAbsInput: false,
            focusedPercInput: false,
            isSliding: false
        };
    }

    private updateTimeout: any = null;

    // private percValueInput: any = null;

    componentDidMount() {
        // setTimeout(() => {
        //     if (this.percValueInput && this.props.showPercInput) {
        //         this.percValueInput.value = ((this.props.maxPercentage / this.props.maxVal) * (this.props.initialValue || 0)).toFixed(1);
        //     }
        // }, 100);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log('slider did update', this.props.label, this.props, 'prevProps', prevProps, 'value',
        // this.percValueInput && this.percValueInput.value
        // );
        // if (this.percValueInput && this.props.showPercInput) {
        //     this.percValueInput.value = ((this.props.maxPercentage / this.props.maxVal) * (this.props.forcedValue || 0)).toFixed(1);
        // }

        if (this.props.controlled === false) {
            if (prevProps.resetCounter !== this.props.resetCounter) {
                // console.log('willincreasecounter');
                this.setState({
                    value: this.props.initialValue,
                    newValue: this.props.initialValue,
                });
            }
        } else {
            if (prevProps.forcedValue !== this.props.forcedValue) {
                console.log('willincreasecounter');
                if (!this.state.focusedPercInput) {
                    this.setState({
                        percValue: (((this.props.maxPercentage / this.props.maxVal) * (this.props.forcedValue || 0))).toFixed(1)
                    });
                }
                this.setState({
                    value: this.props.forcedValue,
                    newValue: this.props.forcedValue,
                    // absValue: this.props.forcedValue,
                    // percValue: ((this.props.maxPercentage / this.props.maxVal) * (this.props.forcedValue || 0))
                });
                // if (this.percValueInput && this.props.showPercInput) {
                //     this.percValueInput.value = (this.props.forcedValue / this.props.maxVal * 100).toFixed(1);
                // }
            }
        }
    }

    onHandleChange = (values) => {
        console.log('public handleChange', values, typeof values[0], this.state.ready, Number.isNaN(values[0]), values[0]);
        if (this.props.controlled && !this.state.focusedPercInput) {
            this.setState({
                percValue: ((this.props.maxPercentage / this.props.maxVal) * (parseFloat(values[0]) || 0)).toFixed(1),
            });
            this.props.sliderValueChanged && this.props.sliderValueChanged(values[0]);
        } else {
            console.log('first if', values);
            this.setState({
                value: values[0],
                newValue: values[0],
            }, () => {
                this.state.ready && this.props.sliderValueChanged && this.props.sliderValueChanged(this.state.value);
                this.setState({ ready: true });
            });
        }
    };

    onUpdate = (values) => {
        console.log('onUpdate: ', values);
        this.onHandleChange(values);
    }

    handleAbsInputChange(e: React.ChangeEvent<HTMLInputElement>, fromBlur?) {
        let value = e.currentTarget.value;
        console.log("value22", value);
        value = formatSliderValue(value || 0, 10);

        if (fromBlur) {
            if (defined(this.props.maxVal) && parseFloat(value) > this.props.maxVal) {
                value = this.props.maxVal.toString();
            }
            if (defined(this.props.minVal) && parseFloat(value) < this.props.minVal) {
                value = this.props.minVal.toString();
            }
        }

        const previousValue = this.state.value;
        console.log('handleAbsInputChange', value, e.target.value, e.currentTarget.value, 'fromBlur', fromBlur);
        // if (fromBlur) {
        this.setState({
            value: value || (fromBlur ? null : ""),
            newValue: value || (fromBlur ? null : ""),
            focusedAbsInput: false,
            percValue: ((this.props.maxPercentage / this.props.maxVal) * (parseFloat(value) || 0)).toFixed(1),
        }, () => {
            console.log('onUpdate abs', value, 'previousValue', previousValue);
            if (previousValue !== Number(value)) {
                this.onUpdate([value])
            }
        });
        // } else {
        //     isNumericOrDot(value,1) && this.setState({
        //         // value: value,
        //         newValue: value,
        //         focusedAbsInput: true,
        //     });
        // }
    };

    handlePercInputChange(e: React.ChangeEvent<HTMLInputElement>, fromBlur?) {
        let value = e.currentTarget.value;
        value = formatSliderValue(value, 4);
        console.log('handlePercInputChange', value, parseFloat(value), fromBlur, this.props.maxPercentage);
        // if (fromBlur) {

        let val = parseFloat((this.props.maxVal / this.props.maxPercentage * parseFloat(value)).toFixed(1));

        if (fromBlur) {
            this.setState({ focusedPercInput: false });
            if (defined(this.props.maxPercentage) && parseFloat(value) > this.props.maxPercentage) {
                value = this.props.maxPercentage.toString();
            } else if (parseFloat(value) > 100) {
                value = "100";
            }
            if (!value || parseFloat(value) < 0) {
                value = "0"
            }
        }

        console.log('elseif', value, val);
        this.setState({
            percValue: !isNaN(parseFloat(value)) ? parseFloat(value) : "",
            value: val || (fromBlur ? null : ""),
            newValue: val || (fromBlur ? null : ""),
        });

        // } else {
        //     this.setState({
        //         percValue: value
        //     });
        // }
    };

    inputValue = (val, bypass?) => {
        if (this.state.focusedAbsInput) {
            return this.state.value;
        }
        return bypass ? val : formatNumber(val);
    }

    focusButton = () => {
        const el = (document.querySelector(".lohnsumme__button") as any)
        if (el && el.firstChild) {
            el.firstChild.click();
        }
    }

    render() {
        const {
            state: { value, newValue }
        } = this;
        // console.log('this.state.percValue', this.state.percValue)
        return (
            <div className={classNames("sliderWrapper", this.props.className, {
                "uncontrolled": this.props.controlled === false,
                "infoText": this.props.infoText,
                "sliding": this.state.isSliding
            })}>
                <div className={classNames('slider_header', { "with-tooltip": this.props.tooltip })}>
                    <p data-translate={this.props.translateId}>
                        {this.props.label}
                        {this.props.tooltip && <Tooltip
                            mouseEnterDelay={0}
                            mouseLeaveDelay={0}
                            destroyTooltipOnHide={true}
                            placement={this.props.tooltipPlacement || 'top'}
                            trigger={this.props.tooltipTrigger || 'click'}
                            overlay={<div>{this.props.tooltipText || ''}</div>}>
                            <HelpIcon style={{ zIndex: 9999, width: '18px', marginLeft: '8px' }} />
                        </Tooltip>
                        }
                    </p>
                    {this.props.children && <div className="slider_val_parent border-bottom-0">
                        {this.props.children}
                    </div>
                    }
                    {this.props.infoText && <p className="infoTextContent">{this.props.infoText}</p>}
                    {this.props.showPercInput && <div className="slider_proc_parent border-bottom-0">
                        <input
                            // ref={el => this.percValueInput = el}
                            // max={this.props.maxPercentage ? this.props.maxPercentage : 100}
                            type={window.innerWidth > 896 ? "text" : "number"}
                            className={
                                classNames("slider_proc", {
                                    // "hasValue": defined(this.state.percValue)
                                })
                            }
                            value={this.state.percValue}
                            onKeyPress={(e) => {
                                if (e.key == "Enter") {
                                    this.focusButton()
                                }
                            }}
                            onFocus={() => this.setState({ focusedPercInput: true })}
                            onBlur={(e) => !this.props.disableOnblur && this.handlePercInputChange(e, true)}
                            onChange={(e) => this.handlePercInputChange(e)}
                            inputMode="numeric"
                        />
                        <div className="slider_proc">%</div>
                    </div>}
                    <div className={classNames("slider_val_parent border-bottom-0", {
                        "percOnly": this.props.percent
                    })}>
                        {!this.props.percent && <label className="sliderInputLabel">
                            CHF
                        </label>}
                        <input className='slider_val'
                            value={this.state.newValue}
                            onFocus={() => {
                                this.setState({
                                    focusedAbsInput: true
                                })
                            }}
                            disabled={this.props.minVal === 0 && this.props.maxVal === 0}
                            onChange={(e) => this.handleAbsInputChange(e)}
                            onBlur={(e) => {
                                // this.setState({ focused: false });
                                !this.props.disableOnblur && this.handleAbsInputChange(e, true)
                            }}
                            onKeyPress={(e) => {
                                if (e.key == "Enter") {
                                    this.focusButton()
                                }
                            }}
                            type="number"
                            inputMode="numeric"
                        />
                        {this.props.percent ? <div className="slider_val">%</div> : null}
                    </div>
                </div>
                {this.props.disableSlide !== "2" && <><div style={{ height: 1, width: '100%' }} className="track">
                    <Slider
                        mode={1}
                        step={this.props.stepSize || 1}
                        domain={this.props.domain || domain}
                        rootStyle={sliderStyle}
                        onChange={(values) => {
                            if (defined(this.props.minVal) && parseFloat(value) < this.props.minVal) {
                                return;
                            }
                            console.log('slider onchange', values[0], 'value', value);
                            !Number.isNaN(values[0]) && (values[0] !== 0) && values[0] === value && this.onHandleChange([value])
                        }}
                        onUpdate={(values) => {
                            const emitValue = values[0].toFixed(this.props.stepSize ? this.props.stepSize * 10 : 0);
                            console.log('slider onUpdate values', values, 'state value', value,
                                // 'absValue', this.state.absValue,
                                'emitValue', emitValue);
                            !Number.isNaN(values[0]) && (values[0] !== value) && this.onUpdate([parseFloat(emitValue)])
                        }}
                        onSlideStart={() => this.setState({ isSliding: true })}
                        onSlideEnd={() => this.setState({ isSliding: false })}
                        values={[this.state.focusedAbsInput ? value : newValue]}
                    >
                        <Rail>
                            {({ getRailProps }) => (
                                <div className='slider_line_progr' {...getRailProps()} />
                            )}
                        </Rail>
                        <Handles>
                            {({ handles, getHandleProps }) => (
                                <div className="slider-handles">
                                    {handles.map(handle => (
                                        <Handle
                                            key={handle.id}
                                            handle={handle}
                                            domain={this.props.domain || domain}
                                            getHandleProps={getHandleProps}
                                        />
                                    ))}
                                </div>
                            )}
                        </Handles>
                        <Tracks right={false}>
                            {({ tracks, getTrackProps }) => (
                                <div className="slider-tracks">
                                    {tracks.map(({ id, source, target }) => (
                                        <Track
                                            key={id}
                                            source={source}
                                            target={target}
                                            getTrackProps={getTrackProps}
                                        />
                                    ))}
                                </div>
                            )}
                        </Tracks>
                    </Slider>
                </div>

                <div className='slider_footer'>
                    {/* <div>{this.props.currency ? ("CHF " + formatNumber(this.props.initialValues[0])) : ((this.props.domain && defined(this.props.domain[0])) ? this.props.domain[0] + "%" : "20%")}</div> */}
                    <div>{!defined(this.props.percent) && ("CHF " + formatNumber(this.props.minVal))}</div>
                    <div>{!defined(this.props.percent) && ("CHF " + formatNumber(this.props.maxVal))}</div>
                </div></>}
                {(this.props.percent || this.props.showPercInput) &&
                    <div className='slider_footer' style={{ marginTop: 3 }}>
                        <div>{(this.props.domain ? this.props.domain[0] + "%" : "0%")}</div>
                        {
                            this.props.percent ? (this.props.showPercInput ?
                                <div>{(this.props.domain ? this.props.domain[1] / this.props.maxVal * 100 + "%" : "90%")}</div>
                                : <div>{this.props.domain && (this.props.domain[1] + "%")}</div>
                            ) : <div>{(this.props.maxPercentage ? this.props.maxPercentage + "%" : "100%")}</div>
                        }
                    </div>}
                {this.props.showButton && <div className='lohnsumme__button'>
                    <Button
                        className="button"
                        submit={true}
                        onClick={() => this.props.setPercent(value)}
                        buttonType={EButtonType.FullWidth}
                    >
                        <FormattedMessageCustom id={translatableStrings.next.id}
                            text={this.props.intl.formatMessage(translatableStrings.next)}>
                            <div></div>
                        </FormattedMessageCustom>
                    </Button>
                </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Example);
