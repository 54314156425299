import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Tab from '@material/react-tab';
import { RootState } from '../../reducers/index';
import * as actions from '../../actions/';
import {
    calculateMaxPurchase,
    calculatePost,
    clearControls,
    clearData,
    setCapitalPurchase,
    simulatePost,
    setDivorcePostings,
    currentSituation,
    setSincronizeMaxPurchases,
    setUserDataLohnklasse
} from '../../actions/';
import { defined } from '../../utils/variableEvaluation';
import './Visualisation.scss';
import { toastr } from 'react-redux-toastr';
import { ChartContainer } from './ChartContainer';
import { getParameterByName } from '../../utils/toSearchParams';
import SelectList from "../../components/Sidebar/SelectList";
import PKlogo from '../../assets/imgs/pkar_logo.png';
import logo from '../../assets/icons/logo.png';
import { ReactComponent as IconSliders } from '../../assets/icons/3sliders.svg';
// import { ReactComponent as IconHelp } from '../../assets/icons/Help Icon.svg';
import { animationConfig } from '../../constants';
import { ReactComponent as IconClose } from "../../assets/icons/Close_Icon.svg";
// import Tooltip from 'rc-tooltip';
// import { ReactComponent as IconReplay } from '../../assets/icons/replay.svg';
import gsap from 'gsap';
import "rc-tooltip/assets/bootstrap.css";
import classNames from 'classnames';
import { defineMessages } from 'react-intl';
import FormattedMessageCustom from '../../components/FormattedMessageCustom';
import { Button, ButtonColors, ButtonTypes } from 'icr-shared-components';
import { ReactComponent as Arrow } from "../../assets/icons/arrow-right-action.svg";
import { addToLSObject } from '../../utils/react';

const translatableStrings = defineMessages({
    backToInsurantPortalButton: {
        id: 'pageChart.backToInsurantPortalButton',
        defaultMessage: 'ZURÜCK ZUM VERSICHERTEN-PORTAL'
    },
    goBack: {
        id: 'button.goBack',
        defaultMessage: 'Zurücksetzen'
    },
    expectedPensionOnRetirement: {
        id: 'pageChart.expectedPensionOnRetirement',
        defaultMessage: 'Zu erwartende Rente bei Pensionierung'
    },
    proMonat: {
        id: 'pageChart.proMonat',
        defaultMessage: 'Pro Monat'
    },
    renteProMonat: {
        id: 'pageChart.renteProMonat',
        defaultMessage: 'Rente pro Monat',
    },
    perYear: {
        id: 'pageChart.perYear',
        defaultMessage: 'Pro Jahr',
    },
    rentePerYear: {
        id: 'pageChart.rentePerYear',
        defaultMessage: 'Rente pro Jahr',
    }
})

interface State {
    mobileModal: boolean;
    readyToRender: boolean;
}

interface Props {
    actions: any;
    calculateMaxPurchase: any;
    calculatePost: any;
    calculateCounterTrigger: any;
    calculateCounter: any;
    lastCalculatePayload: any;
    calculationSteps: any;
    calculations: any;
    calculationsObj: any;
    clearControls: any;
    clearData: any;
    history: any;
    loading: boolean;
    isBridingPossible: boolean;
    hasPayrollClasses: boolean;
    defaultRetirmentAgeFemale: number;
    defaultRetirmentAgeMale: number;
    intl: any;
    location: any;
    partialPensionLimits: any;
    recalculationPending: any;
    setUserDataLohnklasse: any;
    // removeCapitalPurchase: any;
    setCapitalPurchase: any;
    userDataWI: any;
    insurantPortalData: any;
    insurantPortalUrl: any;
    simulatePost: any;
    monthlyOrYearly;
}

class Visualisation extends React.Component<Props, State> {
    chartControlsRef: React.RefObject<any>;
    constructor(props: any) {
        super(props);
        console.log("Visualisation constructor called");
        document.body.classList.remove("willkommen");
        document.body.classList.add("visualisationPage");

        this.state = {
            mobileModal: false,
            readyToRender: false,
        };
    }
    private timeline = null;
    private timelineOut = null;
    private updatesNumber = null;
    private alreadyAnimated = false;

    componentDidMount() {
        console.log('Visualisation app did mount', this.props.userDataWI);
        // console.log('param', getParameterByName('period'));
        let missingData = false;

        Object.keys(this.props.userDataWI).forEach((key) => {
            // console.log('key: ', key);
            if (!defined(this.props.userDataWI[key])) {
                // Exclude these
                if (["Datenquelle", "payrollClass", "insurancePlan", "retirementDatesInitial", 'firstAltersrente', 'VPKontoAmount', 'settings', 'ahvPensionAmount', 'resetToken', 'tenantName', 'lastPartialPensionLimits', 'bruttolohns'].includes(key)) {
                    return
                } else {
                    missingData = true;
                    toastr.error("Missing data", `User data ${key} is missing. Please go back to complete it.`, { timeOut: 1500 });
                    setTimeout(() => {
                        this.props.history.push({
                            pathname: '/geburtsdatum'
                        })
                    }, 2000)
                }
            }
        })

        const retirementStepsNo = this.props.userDataWI.RetirementSteps.length;
        const arbeitPensumsNo = this.props.userDataWI.arbeitPensums.length;
        const rentenAnteilsNo = this.props.userDataWI.retirementPercentage.length;
        const lengthsArray = [retirementStepsNo, arbeitPensumsNo, rentenAnteilsNo];
        const allInfoPresent = lengthsArray.every((val, idx, arr) => {
            return val === arr[0]
        });

        console.log(
            'missingData', missingData,
            "allInfoPresent", allInfoPresent
        );

        if (missingData) {
            this.setState({
                readyToRender: false
            })
        } else if (allInfoPresent === false) {
            const idx = Math.max(...lengthsArray) - 1;
            console.log('idx', idx);
            this.props.actions.removeTeilPension(
                { idx }
            );
        }

        this.timeline = gsap.timeline({ paused: true });
        this.timeline && this.timeline.pause();
        this.setState({
            readyToRender: !missingData ? true : false
        }, () => {
            setTimeout(() => {
                !missingData && this.initTimeline();
            }, 0)
        })
    }

    componentWillUnmount() {
        document.body.classList.remove("visualisationPage");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updatesNumber += 1;
        console.log("Visualisation componenentDidUpdate",
            "updatesNumber", this.updatesNumber,
        );

        // Object.entries(this.props).forEach(([key, val]) =>
        // prevProps[key] !== val && console.log(`Prop Visualisation'${key}' changed`)
        // );

        if (this.state) {
            // Object.entries(this.state).forEach(([key, val]) =>
            // prevState[key] !== val && console.log(`State Visualisation'${key}' changed`)
            // );
            if (this.updatesNumber >= 2 && prevProps.calculations != this.props.calculations && !this.alreadyAnimated) {
                this.animateIn();
            }
        }
    }

    animateIn() {
        console.log('visualisation animateIn');
        this.timeline
            .restart()
            .timeScale(animationConfig().scale || 0.75)
            .play();

        this.alreadyAnimated = true;
    }

    initTimeline = () => {
        console.log('visualisation runtimeline');

        const { insurantPortalUrl } = this.props;

        const elements = insurantPortalUrl ?
            [document.querySelector('.chartTitle'), document.querySelector('.chartTitleBack'), document.querySelector('.capitalMobile')]
            : [document.querySelector('.chartTitle'), document.querySelector('.capitalMobile')];


        this.timeline
            // .pause()
            .fromTo([document.querySelector('.tabs')], { autoAlpha: 0 }, {
                autoAlpha: 1
            }, animationConfig().startSec)
            .fromTo(document.querySelectorAll('.tabs .mdc-tab'), { autoAlpha: 0 }, {
                autoAlpha: 1, stagger: {
                    each: .3,
                    ease: animationConfig().easing
                }
            }, animationConfig().startSec)
            .fromTo(elements, { y: -60, autoAlpha: 0 }, { autoAlpha: 1, y: 0 }, animationConfig().startSec);
    }

    start = (e) => {
        this.props.history.push({
            pathname: e
        })
    }

    back = (e) => {
        const page = e.currentTarget.id;
        console.log('back visualistion', e.currentTarget.id, page);
        if (page) {
            this.timelineOut = gsap.timeline({ paused: true });
            this.timelineOut && this.timelineOut.pause();
            if (document.querySelectorAll('.crossFadeOut').length) {
                this.timelineOut
                    .fromTo(document.querySelectorAll('.crossFadeOut'), { autoAlpha: 1 }, {
                        delay: 0, stagger: {
                            ...animationConfig().stagger,
                        },
                        autoAlpha: 0
                    })
            }

            this.timelineOut.play();

            this.timelineOut.eventCallback("onComplete", () => {
                console.log('oncomplete');
                this.props.history.push({
                    pathname: '/' + page
                })
            });
        }
    }

    activateMonthTab = () => {
        this.props.actions.setMonthlyOrYearly("monthlyIncome");
        this.setState({
            mobileModal: false
        });
    }

    activateYearTab = () => {
        this.props.actions.setMonthlyOrYearly("annualIncome");
        this.setState({
            mobileModal: false
        });
    }

    resized = () => {
        console.log('resized visualisation');
        this.setState({
            mobileModal: false
        })
    }

    render() {
        this.chartControlsRef = React.createRef();
        const { insurantPortalUrl } = this.props;
        return (
            <div className={classNames("visualisation", { "modal": this.state.mobileModal })}>

                <div className='sidebar'>
                    <div className='flex-grow-1'>
                        {this.props.userDataWI.tenantName?.toUpperCase() === "PKAR" ?
                            <img alt="logo" src={PKlogo} className='logo d-none' /> :
                            <img alt="logo" src={logo} className='logo d-none' />}
                    </div>
                    <div className='selects'>
                        <SelectList
                            intl={this.props.intl}
                            onChart
                            // neustartHide
                            back={this.back}
                            start={this.start}
                        />
                    </div>
                </div>

                <div className="visChart">
                    <div className={classNames({"modal": this.state.mobileModal })}>
                        <div className="headerBackButton crossFadeOut">
                            <p className="chartTitleBack" style={{ opacity: 0 }}>
                                <Button
                                    className="mt-2"
                                    type={ButtonTypes.Text}
                                    color={ButtonColors.Action}
                                    onClick={(e) => window.location.href = insurantPortalUrl}>
                                    <Arrow className="rotate-180" /> <FormattedMessageCustom id={translatableStrings.backToInsurantPortalButton.id} text={this.props.intl.formatMessage(translatableStrings.backToInsurantPortalButton)} />
                                </Button>
                            </p>
                            <p className="chartTitleBack">
                                <Button
                                    className="mt-2"
                                    type={ButtonTypes.Text}
                                    color={ButtonColors.Action}
                                    onClick={(e) => this.chartControlsRef.current.resetControls(null)}>
                                    <FormattedMessageCustom id={translatableStrings.goBack.id} text={this.props.intl.formatMessage(translatableStrings.goBack)} />
                                </Button>
                            </p>
                        </div>
                    </div>

                    <div className={classNames("header crossFadeOut",{"transparent": this.state.mobileModal })}>
                        <div className='closeIcon close'
                            onClick={() => {
                                this.setState({ mobileModal: false }, () => {
                                    setTimeout(() => this.props.recalculationPending && this.props.actions.forceRecalculate(), 150)
                                })
                            }}>
                            <IconClose />
                        </div>
                        <FormattedMessageCustom id={translatableStrings.expectedPensionOnRetirement.id} text={this.props.intl.formatMessage(translatableStrings.expectedPensionOnRetirement)}>
                            <p className="chartTitle" style={{ opacity: 0 }} />
                        </FormattedMessageCustom>
                        <div className="tabs" style={{ opacity: 0 }}>
                            <Tab active={this.props.monthlyOrYearly === 'monthlyIncome'} minWidth className="mr-5" onClick={this.activateMonthTab}>
                                <FormattedMessageCustom id={translatableStrings.proMonat.id} text={this.props.intl.formatMessage(translatableStrings.proMonat)}>
                                    <span className='mdc-tab__text-label d-none d-md-block' />
                                </FormattedMessageCustom>
                                <FormattedMessageCustom id={translatableStrings.renteProMonat.id} text={this.props.intl.formatMessage(translatableStrings.renteProMonat)}>
                                    <span className='mdc-tab__text-label d-md-none' />
                                </FormattedMessageCustom>
                            </Tab>
                            <Tab active={this.props.monthlyOrYearly === 'annualIncome'} minWidth onClick={this.activateYearTab}>
                                <FormattedMessageCustom id={translatableStrings.perYear.id} text={this.props.intl.formatMessage(translatableStrings.perYear)}>
                                    <span className='mdc-tab__text-label d-none d-md-block' />
                                </FormattedMessageCustom>
                                <FormattedMessageCustom id={translatableStrings.rentePerYear.id} text={this.props.intl.formatMessage(translatableStrings.rentePerYear)}>
                                    <span className='mdc-tab__text-label d-md-none' />
                                </FormattedMessageCustom>
                            </Tab>
                        </div>
                    </div>

                    {this.state.readyToRender && <ChartContainer
                        insurantPortalData={this.props.insurantPortalData}
                        insurantPortalUrl={this.props.insurantPortalUrl}
                        loading={this.props.loading}
                        arbeitPensums={this.props.userDataWI.arbeitPensums}
                        calculateCounterTrigger={this.props.calculateCounterTrigger}
                        calculateCounter={this.props.calculateCounter}
                        calculateMaxPurchase={this.props.calculateMaxPurchase}
                        calculatePost={this.props.calculatePost}
                        simulatePost={this.props.simulatePost}
                        calculationSteps={this.props.calculationSteps || []}
                        calculations={this.props.calculations || []}
                        calculationsObj={this.props.calculationsObj || {}}
                        chartControlsRef={this.chartControlsRef}
                        clearControls={this.props.clearControls}
                        clearData={this.props.actions.clearData}
                        history={this.props.history}
                        hasPayrollClasses={this.props.hasPayrollClasses}
                        lastCalculatePayload={this.props.lastCalculatePayload}
                        intl={this.props.intl}
                        forceRecalculate={this.props.actions.forceRecalculate}
                        lang={this.props.intl.locale}
                        location={this.props.location}
                        mobileModal={this.state.mobileModal}
                        partialPensionLimits={this.props.partialPensionLimits}
                        period={getParameterByName('period')}
                        removeCapitalPurchase={this.props.actions.removeCapitalPurchase}
                        removeTeilPension={this.props.actions.removeTeilPension}
                        recalculationPending={this.props.recalculationPending}
                        resized={this.resized}
                        retirementPercentages={this.props.userDataWI.retirementPercentage}
                        retirementSteps={this.props.userDataWI.RetirementSteps}
                        setBridgingPension={this.props.actions.setBridgingPension}
                        setCapitalPurchase={this.props.setCapitalPurchase}
                        setCapitalWithdrawal={this.props.actions.setCapitalWithdrawal}
                        setUserDataLohnklasse={this.props.setUserDataLohnklasse}
                        isBridingPossible={this.props.isBridingPossible}
                        defaultRetirmentAgeMale={this.props.defaultRetirmentAgeMale}
                        defaultRetirmentAgeFemale={this.props.defaultRetirmentAgeFemale}
                        setUserDataZeitpunkt={this.props.actions.setUserDataZeitpunkt}
                        userDataWI={this.props.userDataWI}
                        userDataWIZeitpunktUpdate={this.props.actions.userDataWIZeitpunktUpdate}
                        setDivorcePostings={this.props.actions.setDivorcePostings}
                        currentSituation={this.props.actions.currentSituation}
                        monthlyOrYearly={this.props.monthlyOrYearly}
                        setSincronizeMaxPurchases={this.props.actions.setSincronizeMaxPurchases}
                    />}
                </div>

                <div className={classNames("pointer showSlidersButton d-md-none", {
                    "d-none": this.state.mobileModal
                })}
                    onClick={() => { this.setState({ mobileModal: true }) }}>
                    <div className="addButton">
                        <div className="iconWrapper" style={{ height: 50 }}><IconSliders /></div>
                    </div>
                </div>
            </div >
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions as any, dispatch),
        calculatePost: bindActionCreators(calculatePost, dispatch),
        simulatePost: bindActionCreators(simulatePost, dispatch),
        calculateMaxPurchase: bindActionCreators(calculateMaxPurchase, dispatch),
        clearData: bindActionCreators(clearData, dispatch),
        clearControls: bindActionCreators(clearControls, dispatch),
        setCapitalPurchase: bindActionCreators(setCapitalPurchase, dispatch),
        setDivorcePostings: bindActionCreators(setDivorcePostings, dispatch),
        currentSituation: bindActionCreators(currentSituation, dispatch),
        setSincronizeMaxPurchases: bindActionCreators(setSincronizeMaxPurchases, dispatch),
        setUserDataLohnklasse: bindActionCreators(setUserDataLohnklasse, dispatch)
        // removeCapitalPurchase: bindActionCreators(removeCapitalPurchase, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Visualisation);
