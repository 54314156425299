import React, { useState } from "react";
import classNames from "classnames";
import Slider from "../../../../../components/Slider/index";
import {
  addToLSObject,
  dateFormat, isDate, parseToApiDate,
} from "../../../../../utils/react";
import { Button, EButtonType } from "../../../../../components/Button";
// import { ReactComponent as HelpIcon } from "../../../../../assets/icons/Help Icon.svg";
import "../ChartControls.scss";
import { defineMessages } from "react-intl";
import FormattedMessageCustom from "../../../../../components/FormattedMessageCustom";
import { Input } from "icr-shared-components";

const translatableStrings = defineMessages({
  scheidung: {
    id: "pageChart.scheidung",
    defaultMessage: "Scheidung",
  },
  scheidungDatum: {
    id: "pageChart.scheidungDatum",
    defaultMessage: "Scheidungdatum",
  },
  scheidungTransferOptions: {
    id: "pageChart.scheidungTransferOptions",
    defaultMessage: "Transfer",
  },
  scheidungAmount: {
    id: "pageChart.scheidungAmount",
    defaultMessage: "Betrag",
  },
  button: {
    id: "pageChart.button",
    defaultMessage: "Rente berechnen",
  },
});

interface Props {
  calculations?: any;
  closeModal: any;
  intl: any;
  monthlyOrYearly?: string;
  userDataWI: any;
  getCapitalMaxPurchase: any;
  capitalPurchaseDates: any;
  divorcePurchase: any;
  currentSituation: any;
  setDivorcePostings: any;
}

const Scheidung = (props: Props) => {
  const [data, setData] = useState({
    scheidungDate: props.userDataWI.divorcePostings
      ? dateFormat(
          props.userDataWI.divorcePostings[0].date.split("T")[0],
          "yyyyddmm"
        )
      : null,
    scheidungValue: props.userDataWI.divorcePostings
      ? Math.abs(props.userDataWI.divorcePostings[0].value)
      : 0,
    scheidungTotalValue: props.userDataWI.divorceTotalValue ? props.userDataWI.divorceTotalValue : 0,
    scheidungTransfer: props.userDataWI.divorcePostings && props.userDataWI.divorcePostings[0].value > 0 ? "2" : "1",
    resetCounter: 0,
  });

  const handleTransferOptionRadioChange = (name, value) => {
    setData({ ...data, scheidungTransfer: `${value}` });
  };

  const prepareFormInputsTransferRadioOptions = (options) => {
    return options.map((option) => ({
      ...option,
      "name": "transfer",
      "type": "radio",
      "label": option.label,
      "value": option.value,
      "validationMessageConfig": null,
      "validationTests": [],
      "onChange": handleTransferOptionRadioChange,
    }));
  };

  const transferOptions = prepareFormInputsTransferRadioOptions([
    { value: "1", label: "Abgabe" },
    { value: "2", label: "Zunahme" },
  ]);


  const fetchCurrentSitutation = () => {
    const payroll = {};
        props.userDataWI.Lohnsumme &&
            (payroll["insuredSalary"] = parseFloat(props.userDataWI.Lohnsumme));
        props.userDataWI.Lohnsumme &&
            (payroll["grossSalary"] = parseFloat(props.userDataWI.Lohnsumme));
        props.userDataWI.Beschaftigungsgrad &&
            (payroll["activityRate"] = parseFloat(
                props.userDataWI.Beschaftigungsgrad
            ));
        props.userDataWI.payrollClass &&
            (payroll["payrollClass"] = parseInt(props.userDataWI.payrollClass));

        // build payrolls on payroll object
        const payrolls = [
            {
                activityRate: (payroll as any)?.activityRate,
                grossSalary: (payroll as any)?.grossSalary,
                validFrom: "2020-01-01T12:00:00.000Z", // First day of the year always
            },
        ];

        const acturialValuesSet = {
            totals: [{}],
            details: [],
        };
        props.userDataWI["AusgestelltAm"] &&
            (acturialValuesSet["totals"][0]["referenceDate"] =
                props.userDataWI["AusgestelltAm"]);
        props.userDataWI["Sparguthaben"] &&
            (acturialValuesSet["totals"][0]["basicCapital"] = parseFloat(
                props.userDataWI["Sparguthaben"]
            ));
        props.userDataWI["VPKontoAmount"] &&
            (acturialValuesSet["totals"][0]["earlyRetirementCapital"] = parseFloat(
                props.userDataWI["VPKontoAmount"]
            ));

        Object.keys(props.userDataWI["capitalPurchases"]).length &&
            Object.keys(props.userDataWI["capitalPurchases"]).forEach((key) => {
                props.userDataWI["capitalPurchases"][key].date &&
                    acturialValuesSet["details"].push({
                        referenceDate: props.userDataWI["capitalPurchases"][key].date,
                        totalCapital: props.userDataWI["capitalPurchases"][key].amount,
                    });
            });
        
        // build transactions on acturialValues
        const transactions = [];
        acturialValuesSet?.totals?.map((total: any) => {
            transactions.push({
                creationDate: total.referenceDate,
                transactionDate: total.referenceDate,
                interestDate: total.referenceDate,
                amount: total.basicCapital,
                transactionType: 16,
            });
        });

        const apiDate = parseToApiDate(data.scheidungDate, "yyyyddmm");
        const mergedPayload = {
            "data": {
                "tenantName": props.userDataWI.tenantName || "BVG",
                "keyDate": apiDate,
                "person": {
                    birthDate: props.userDataWI.Geburtsdatum,
                    sex: props.userDataWI.Geschlecht === "weiblich" ? 2 : 1,
                    insuranceRelationships: [
                        {
                            transactions: transactions,
                            employment: [
                                {
                                    insurancePlans: [
                                        {
                                            planCode: "BVG",
                                        },
                                    ],
                                    payrolls: payrolls,
                                },
                            ],
                        },
                    ],
                },
                "RetirementSteps": (window as any).retirementSteps
            }
        };
        props.currentSituation(mergedPayload).then(response=>{
          if(response){
            setData({...data, scheidungTotalValue:response, scheidungValue:0})
            addToLSObject("userDataWI", "divorceTotalValue", response);
            props.setDivorcePostings([
              {
                date: data.scheidungDate,
                value: 0,
              }
            ])
          }
        })
  }

  return (
    <>
      <div className="modal-header buyin modal-header-content">
        <FormattedMessageCustom
          id={translatableStrings.scheidung.id}
          text={props.intl.formatMessage(translatableStrings.scheidung)}
        />
        {/* {this.altersrente(this.props.userDataWI.firstAltersrente[this.props.monthlyOrYearly])} */}
      </div>
      <div className={`modal-content withdrawal buyin`}>
        <div className="row">
          <div className="sliderWrapperParent scheidungWrapper">
            <p className="title">Kapitalveränderung</p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: 50,
              }}
            >
              <p className="scheidungsdatum">
                <FormattedMessageCustom
                  id={translatableStrings.scheidungDatum.id}
                  text={props.intl.formatMessage(
                    translatableStrings.scheidungDatum
                  )}
                />
              </p>
              <Input
                type="date"
                maxLength={10}
                inputWrapperClass="text-input"
                id="birthDateInput"
                name={"date"}
                placeholder={"DD.MM.YYYY"}
                value={
                  data.scheidungDate && data.scheidungDate.length >= 10
                    ? dateFormat(data.scheidungDate)
                    : data.scheidungDate
                }
                onBlur={() =>{
                  if(isDate(data.scheidungDate)){
                    fetchCurrentSitutation()
                  }
                }}
                validationMessageConfig={{
                    visible: !!data.scheidungDate && data.scheidungDate.length > 0 && !isDate(data.scheidungDate),
                    config: {
                      message:"",
                      type: !!data.scheidungDate && data.scheidungDate.length > 0 && !isDate(data.scheidungDate) ? "error" : null
                    }
                }}
                onChange={(name, value) => {
                  setData({ ...data, scheidungDate: value });
                }}
              />
            </div>
            <div style={{ height: 78 }}>
              <Input
                id="transferRadios"
                inputWrapperClass="radio-input"
                name={"transfer"}
                radioOptions={transferOptions}
                forcedValue={data.scheidungTransfer.toString()}
              >
                <FormattedMessageCustom
                  id={translatableStrings.scheidungTransferOptions.id}
                  text={props.intl.formatMessage(
                    translatableStrings.scheidungTransferOptions
                  )}
                />
              </Input>
            </div>
            <Slider
              controlled
              intl={props.intl}
              label={props.intl.formatMessage(
                translatableStrings.scheidungAmount
              )}
              translateId={translatableStrings.scheidungAmount.id}
              className={classNames("scheidungInSlider", {
                inactive:
                  data.scheidungTotalValue === 0 &&
                  data.scheidungTransfer === "1",
              })}
              disableOnblur
              disableSlide={data.scheidungTransfer}
              activeSegment={0}
              maxVal={data.scheidungTotalValue}
              minVal={0}
              domain={[0, data.scheidungTotalValue]}
              initialValue={
                data.scheidungTotalValue === 0 && data.scheidungTransfer === "1"
                  ? 0
                  : data.scheidungValue
              }
              forcedValue={
                data.scheidungTotalValue === 0 && data.scheidungTransfer === "1"
                  ? 0
                  : data.scheidungValue
              }
              sliderValueChanged={(val, initial) => {
                if (
                  data.scheidungTotalValue !== 0 &&
                  data.scheidungTransfer === "1"
                ) {
                  setData({ ...data, scheidungValue: parseInt(val) });
                  props.divorcePurchase({
                    date: data.scheidungDate,
                    value: parseInt(val) * -1,
                  });
                } else if (data.scheidungTransfer === "2") {
                  setData({ ...data, scheidungValue: parseInt(val) });
                }
              }}
            ></Slider>
          </div>
          <div className="modal-buttons">
            <Button
              isDisabled={!data.scheidungDate && !isDate(data.scheidungDate)}
              className="button reflect-button"
              onClick={() => {
                if (data.scheidungTransfer === "2") {
                  props.divorcePurchase({
                    date: data.scheidungDate,
                    value: data.scheidungValue,
                  });
                }
                props.closeModal();
              }}
              buttonType={EButtonType.PillSmall}
            >
              <FormattedMessageCustom
                id={translatableStrings.button.id}
                text={props.intl.formatMessage(translatableStrings.button)}
              />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Scheidung;
