import React from "react";
import ReactDOM from "react-dom";
import Routes from "./Routes";
// import App from './App';
import "./styles/index.scss";
import * as serviceWorker from "./serviceWorker";
import thunk from "redux-thunk";
import logger from "redux-logger";
import prodConfig from "./config/config.prod.json";
import stageConfig from "./config/config.dev.json";
// import localConfig from './config/config.json';
import { createStore, applyMiddleware, Store } from "redux";
// import { Route } from "react-router-dom";
import { ConnectedRouter, routerMiddleware } from "connected-react-router";
import createRootReducer, { RootState } from "./reducers/index";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { IntlProvider } from "react-intl-redux";
import { sideActions } from "./middlewares";
import { createHashHistory } from "history";
import de from "./constants/locales/de.json";
import { flattenMessages } from "./utils/react";
import * as Sentry from "@sentry/react";
import "icr-shared-components/dist/index.css";
import { ThemeProvider } from "@emotion/react";
import { GlobalStyles, theme } from "icr-shared-components";

process.env.NODE_ENV !== "development" &&
  Sentry.init({
    dsn: "https://3f91313c7c934074942e82141afb9c45@o429644.ingest.sentry.io/5376647",
  });
console.log("indexm env mode: ", process.env.NODE_ENV, "Sentry", Sentry);
const historyS = createHashHistory();
const isProd = window.location.host == prodConfig.appUrl;
const isStage = window.location.host == stageConfig.appUrl;

let middleware;

if (isProd || isStage) {
  console.log("isProduction");
  middleware = applyMiddleware(
    routerMiddleware(historyS), // for dispatching history actions
    thunk,
    sideActions
  );
} else {
  console.log("isNot Production or Stage");
  middleware = applyMiddleware(
    routerMiddleware(historyS), // for dispatching history actions
    thunk,
    sideActions,
    logger
  );
}

const i18nInitialState = {
  locale: "de",
  messages: {
    ...flattenMessages(de),
  },
};

export const store = createStore(
  createRootReducer(historyS) as any,
  { intl: i18nInitialState } as any,
  middleware as any
) as Store<RootState>;

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <ConnectedRouter history={historyS}>
        <IntlProvider locale="de" defaultLocale="de">
          <GlobalStyles />
          <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="top-center"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            closeOnToastrClick
          />
          <Routes />
        </IntlProvider>
      </ConnectedRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root") as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
